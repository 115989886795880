// https://github.com/gammasoft/browser-i18n

var I18n = function(options) {
	for (var prop in options) {
		this[prop] = options[prop];
	}
	// Remove cookie
	// Cookies.remove('language');
	// var _this = this;
	this.getLocaleFileFromServer(this.locale, function(locale) {
		// Cookies.set('language', locale, { expires: 365, sameSite: 'lax' });
		return;
	});
};

I18n.localeCache = {};

I18n.prototype = {
	'defaultLocale': "en",
	// 'directory': _du + '/locales',
	// 'extension': ".min.json",

	getLocale: function(){
		return this.locale;
	},

	setLocale: async function(locale) {
		if(!locale) locale = $("html").attr("lang");
		if(!locale)	locale = this.defaultLocale;
		if (!I18n.localeCache[locale]) {
			this.getLocaleFileFromServer(locale, function(locale) {
				return;
			});
		}
		return;
	},

	getLocaleFileFromServer: async function(locale, callback) {
		var _locale;

		var _data = {
	"%s_moved_this_email_from_the_%s_to_the_%s_folder": "%s moved this conversation from the '%s' to the '%s' folder",
	"%s_moved_this_email_to_the_%s_folder": "%s moved this conversation to the '%s' folder",
	"%s_moved_this_list_item_from_the_%s_to_the_%s_folder": "%s moved this conversation from the '%s' to the '%s' folder",
	"%s_removed_this_email_from_label_folder": "%s removed this conversation from its label folder",
	"%s_removed_this_email_from_the_%s_folder": "%s removed this conversation from the '%s' folder",
	"%s_removed_this_list_item_from_label_folder": "%s removed this conversation from its label folder",
	"1_day_before_response_time": "1 day before SLA is due",
	"1_hour_before_response_time": "1 hour before SLA is due",
	"2_days_before_response_time": "2 days before SLA is due",
	"3_hours_before_response_time": "3 hours before SLA is due",
	"6_hours_before_response_time": "6 hours before SLA is due",
	"10_minutes_before_response_time": "10 minutes before SLA is due",
	"12_hours_before_response_time": "12 hours before SLA is due",
	"12hour": "12 Hour Clock (4:15 pm)",
	"24hour": "24 Hour Clock (16:15)",
	"30_minutes_before_response_time": "30 minutes before SLA is due",
	"a_label_cannot_be_a_sub_label_to_its_own_sub_label": "A label cannot be a sub-label to one of its own sub-labels",
	"a_removed_user_replied": "A removed user replied",
	"about_marketing": "About Marketing Campaigns",
	"access": "access",
	"access_control": "Access Control",
	"access_token": "Access Tokens",
	"access_token_create": "Create access token",
	"access_token_delete": "Delete access token",
	"access_token_delete_confirm": "Yes, delete token",
	"access_token_delete_desc": "Do you really want to delete this access token? Applications that use this token will not work anymore.",
	"access_token_desc": "Generate a token to grant access to both our applications and those developed by third parties.",
	"access_token_desc_2": "Do not share your Token with others and only use it with applications that you trust.",
	"access_token_name": "Name of token",
	"access_token_new": "Create a new access token",
	"account_controls": "Account controls",
	"account_controls_desc": "With account controls, you can manage user access for those who signed up with the same email domain.Use account controls to manage user access for those registered with the same email domain. You can allow them to create a personal workspace or not or redirect them to a shared workspace upon logging in.",
	"account_inactive_button_%s": "Reactivate my %s account",
	"account_inactive_desc_%s": "Your %s account has been deactivated because we no longer have an active subscription for your account, or we were unable to charge your current payment method.",
	"account_inactive_enter_billing": "Otherwise, please re-enter your billing information to reactivate your account.",
	"account_inactive_header_%s": "Your %s account is deactivated",
	"account_inactive_reach_out_%s_%s": "If you believe you've reached this page in error please contact <a href=\"mailto:%s\" __target='blank'>%s</a> to let us know.",
	"account_manager": "Account Manager",
	"account_settings": "My account settings",
	"account_social_header": "Social media accounts",
	"account_social_header_username": "Enter entire URL to account",
	"account_usage": "My account usage",
	"account_usages": "Account usages",
	"action": "Action",
	"action_occured": "the following action occurred",
	"action_permanent": "Please note, that this action is permanent and cannot be undone",
	"actions": "Actions",
	"activate": "activate",
	"activate_label_explorer": "Label-Explorer",
	"activate_upper": "Activate",
	"activated": "Activated",
	"active": "Active",
	"active_drip_campaigns": "Active email sequences",
	"active_helpmonks_users": "Active Users",
	"active_lists": "active lists",
	"activity": "Activity",
	"activity_log_panel": "Activity Log",
	"activity_over_last_7_days": "Activity over last week",
	"activity_over_time": "Activity Over Time",
	"add": "Add",
	"add_a_comment": "Add a comment",
	"add_a_group": "Add a group",
	"add_a_group_placeholder": "Enter the name for this group",
	"add_access": "Add access",
	"add_all_tags": "Add all tags",
	"add_another_note": "Add new note",
	"add_box": "Add box",
	"add_box_from_existing_contacts": "Add from existing contacts",
	"add_call_log": "Add call log",
	"add_card": "Add card",
	"add_categories": "Add categories",
	"add_category": "Add category",
	"add_cc_bcc": "Add a CC or BCC email address to all outgoing replies",
	"add_cc_bcc_short": "Add a CC or BCC recipients",
	"add_collection_button": "Add this collection",
	"add_contact_or_company": "Add a contact or a company",
	"add_contact_to": "Add contact to",
	"add_contacts": "Add contacts",
	"add_email_address": "Add email address",
	"add_email_address_desc": "Please note that you have to use a valid email address that can be resolved. You cannot use a general email address such as @gmail.com, @outlook.com, or any disposable email addresses.",
	"add_email_addresses": "Add additional email addresses",
	"add_favorite": "Add favorite",
	"add_feedback": "Add Idea or  bug",
	"add_from_contacts": "Add from contacts",
	"add_funnel": "Add funnel",
	"add_label": "Add label",
	"add_label_desc": "Click on the label(s) to add it to the conversation(s)",
	"add_list": "Add list",
	"add_mail_server": "Add a new mail-server",
	"add_mailbox_header": "Add a new mailbox",
	"add_meeting_notes": "Add meeting notes",
	"add_member_as_co_admin": "Select a member to add as Co-Admin",
	"add_message": "Add message...",
	"add_navigation_question": "What do you want to call this navigation item?",
	"add_new_comment": "Add a new comment",
	"add_new_company": "Add Company",
	"add_new_contact": "Add Contact",
	"add_new_customer": "Add Customer",
	"add_new_group": "Add a new group",
	"add_new_sla": "Add a new SLA",
	"add_new_team": "Add a new team",
	"add_new_trigger": "Add a new trigger",
	"add_note": "Add a note",
	"add_note_here": "Add your note here",
	"add_note_option": "Add a note",
	"add_note_short": "Note",
	"add_or_edit_sla": "Add/Edit Service Level Agreement",
	"add_reminder": "Add reminder",
	"add_section": "Add section",
	"add_section_question": "What do you want to call this section?",
	"add_sla": "Add Service Level Agreement",
	"add_stage": "Add stage",
	"add_tab": "Add this tab",
	"add_tag": "Add tag",
	"add_task": "Add task",
	"add_team": "Add team",
	"add_the_selected_labels": "Select the labels that you want to add to the conversation",
	"add_the_selected_labels_customers": "Select the labels that you want to add to the customers",
	"add_this_company": "Add this new company",
	"add_this_contact": "Add this new contact",
	"add_this_group": "Add this group",
	"add_this_note": "Add this note",
	"add_this_section": "Add this section",
	"add_this_sla": "Add this SLA",
	"add_this_trigger": "Add this trigger",
	"add_time_range": "add another time range",
	"add_to_box": "Add to box",
	"add_to_collection": "Add to collection",
	"add_to_followup_sequence": "Add to follow-up sequence",
	"add_to_group": "Add to group",
	"add_to_member_automatically": "Add to member automatically",
	"add_to_roadmap_list": "Add to roadmap list",
	"add_todo": "Add To-Do",
	"add_todo_short": "To-Do",
	"add_user_to_team": "Add user to team",
	"add_users": "Add users",
	"add_workspace": "Add workspace",
	"added": "Added",
	"added_on": "Added on",
	"added_to": "Added to",
	"added_with": "Added with",
	"additional_email_addresses": "Additional email addresses",
	"admin": "Admin",
	"admin_activate_plugins_desc_%s": "Activate or deactivate plugins for use in %s",
	"admin_activate_plugins_heading": "Activate Plugins for each hosts",
	"admin_activate_plugins_heading_single": "Activate Plugins",
	"admin_activity_log": "Activity Log",
	"admin_activity_log_desc": "View general system activity logs",
	"admin_adv_api_keys": "API Keys",
	"admin_adv_api_keys_existing": "Existing API Keys",
	"admin_adv_api_keys_key": "API Key (Value)",
	"admin_adv_api_keys_name": "API Keys (Name)",
	"admin_alerts_activate": "Show this alert",
	"admin_alerts_activate_checkbox": "I want to show this alert",
	"admin_alerts_activate_desc": "Show this alert to your users. Only one alert can be activated at the same time! If you select to show this alert, all other alerts will be deactivated.",
	"admin_alerts_button_new": "Create an alert",
	"admin_alerts_color": "Color of alert",
	"admin_alerts_color_desc": "Please select the background color of the alert. Upon select you will see a live preview of the color around the editor above.",
	"admin_alerts_desc": "Here you can define alerts, i.e. banners that show on top of the page in the host. You can only <strong>activate one alert at a time</strong>, but you are able to store as many predefined alerts as you like.<br><br>You can use alerts, for example, to alert your users of something exciting or to do something after an update.",
	"admin_alerts_dismiss": "Add dismiss option",
	"admin_alerts_dismiss_checkbox": "Allow user to dismiss alert",
	"admin_alerts_dismiss_desc": "With the dismiss option activated, the user can close the alert and the alert will not be shown anymore until a new alert is posted. You should probably activate this as to not to annoy your users.",
	"admin_alerts_dismiss_reset": "Show alert to user (reset dismiss)",
	"admin_alerts_dismiss_reset_checkbox": "Yes, show alert to user again",
	"admin_alerts_dismiss_reset_desc": "Check the checkbox below to reset the users preference to not show the alert! This will simply remove the stored preference for this alert for the user and he will see this alert again.<br><br>Note: This can only be done one time!",
	"admin_alerts_dismiss_text": "Dismiss text",
	"admin_alerts_edit_header": "Your alert",
	"admin_alerts_expire_on": "Expiration date",
	"admin_alerts_expire_on_desc": "Set an expiration date when this alert should automatically become deactivated, i.e. the date the alert will not be shown anymore. If you do not select a date, you will have to manually deactivate the alert.",
	"admin_alerts_message_desc": "Enter the text your users will see. Note: Your text should fit on one line only!",
	"admin_alerts_sticky_text": "Sticky button text",
	"admin_attachment_choose_storage": "Choose where to store email attachments",
	"admin_attachment_email": "Configure Email",
	"admin_attachment_email_desc": "Configure how emails are being handled in the system. Please make sure to enter these settings correct or else your server might not be able to connect to the mail server.",
	"admin_attachment_header": "Configure Attachments",
	"admin_attachment_header_desc": "Configure how attachments are being handled in the system. You can store them locally or on Amazon S3.",
	"admin_attachment_local_path": "Set attachment path",
	"admin_attachment_local_path_desc": "Independent where you store the email attachments you need to set the local path for storing the attachments. If you have chosen S3 above, then this location will be used as a temporary storage (the system will clean up automatically)",
	"admin_code_injection": "Code fragment",
	"admin_code_injection_desc_%s": "%s allows you to append your own Javascript code at the bottom of each page. This allows for inserting your own chat or statistic snippet.",
	"admin_configuration_plugins_desc": "Some plugins require further configuration. Please find these plugins below.",
	"admin_configuration_plugins_heading": "Plugin configuration",
	"admin_db_desc": "Configure your database configuration in the below panels",
	"admin_db_submit": "Test & Update Database Connection",
	"admin_db_warning": "<p>Changing the production database takes effect immediately and will cause an interruption to the operation for your system.</p><p><strong>Please ensure that you have all collections and data available in the new database or else the system will fail to start!</strong></p>",
	"admin_header_sent_messages": "Sent Messages",
	"admin_host_desc": "Define hosts on this system. A host has its own settings, users, products, categories, etc. You can edit the settings for each hosts within the Host-Administration.",
	"admin_host_details": "Host Details",
	"admin_host_edit_view": "Edit host",
	"admin_link_feedback": "Feedback",
	"admin_link_go_to_host": "Go to host",
	"admin_link_host": "Host",
	"admin_link_hosts": "Hosts",
	"admin_link_plugins": "Plugins",
	"admin_link_reports": "Report Info",
	"admin_link_settings": "Settings",
	"admin_link_signout": "Sign out",
	"admin_link_status": "Status",
	"admin_link_users": "Users",
	"admin_manage_report_data": "Manage Report Data",
	"admin_nav_alerts": "Alerts",
	"admin_nav_content": "Content Filter",
	"admin_nav_email_users": "Email your users",
	"admin_nav_email_users_desc": "Send a message by email to all users of this system.",
	"admin_nav_news": "News",
	"admin_nav_sessions": "Manage Sessions",
	"admin_news_excerpt_desc_img": "The excerpt is shown in the side-panel along with the title. Below is an example of how this might look.",
	"admin_news_full_desc_img": "The message contains the full length news entry. It is shows in a pop-up window and will show together with the title and the excerpt. Below is an example of how this might look.",
	"admin_news_notification_desc": "If checked, will show a notification for this news record. The notification will show to each user one time only.",
	"admin_news_notification_desc_img": "The notification text is shown along with the title. Below is an example of how this might look.",
	"admin_news_notification_header": "Show in a notification",
	"admin_news_notification_text_header": "Text for the notification",
	"admin_news_remove_desc": "Please confirm that you want to remove this news record",
	"admin_news_remove_header": "Remove this news record",
	"admin_news_title_desc": "The title for your news entry. The title is always being shown in all news section, i.e, for notification, in excerpt, etc.",
	"admin_plugins_sidebar_activate": "Activate",
	"admin_plugins_sidebar_configuration": "Configuration",
	"admin_plugins_sidebar_register": "Register",
	"admin_processing_info": "Status information",
	"admin_processing_info_list_desc": "Start time sorted list of all email processing info documents",
	"admin_processing_info_overview_desc": "Overview of current email processing info events",
	"admin_register_plugins_desc": "Register and deregister available plugins. Plugins must be registered before they can be activated.",
	"admin_register_plugins_heading": "Register Site Plugins",
	"admin_remove_host": "Do you really want to remove this host from the system?",
	"admin_remove_host_note": "Note: If you remove this host all of its records (users, products, etc.) will be removed permanently. This action is final and cannot be undone!",
	"admin_remove_user": "Do you really want to remove this user from the system?",
	"admin_remove_user_note": "Note: Since this is a user in the System-Administration group this user will not be able to use the system anymore. This action is final and cannot be undone!",
	"admin_sessions_desc": "Control sessions of all signed in users. Here you can also remove a session of a user. Once removed the user is immediately signed out of the system.",
	"admin_settings_appliance_warning_%s_%s": "If you are using the %s Appliance you do not need to configure this section as the %s appliance comes with an incoming mail server",
	"admin_settings_desc": "Settings Description",
	"admin_settings_domain_header": "Main domain",
	"admin_settings_domain_header_desc": "This is the domain that the system will use within emails or to link back to your server. The system will use either the app domain name or this value, depending where it makes sense. This is always the same main domain as the app domain!",
	"admin_settings_forward_desc": "This is the email domain that your users see when they are advised to forward emails, e.g. \"Please forward your emails to 12352j43hjhkjh2jk5h@helpmonks.net\" (the @helpmonks.net part is taken from the field above). Please enter the domain part with the @ sign.",
	"admin_settings_forward_header": "Forward address",
	"admin_settings_imap_settings_header": "IMAP Settings",
	"admin_settings_incomingmail_header": "Incoming mail settings",
	"admin_settings_incomingmail_header_desc": "Please enter the needed information for the incoming mail server. This is the server that is being used to pickup all emails for this installation. You only need to provide one 'catch all' account. The system will do the internal processing for you automatically. This has to be an IMAP compatible server.",
	"admin_settings_login": "Lock login",
	"admin_settings_login_desc": "For maintenance work or other tasks you can lock login to any hosts here. If you need to sign in to any hosts, simply append ?login=007 to the URL.",
	"admin_settings_mail_desc": "Enter your outgoing mail server (SMTP) settings below. You can add as many SMTP servers as you like and assign them to each host. However, within a host the user might choose another SMTP server.",
	"admin_settings_mail_header": "Outgoing mail settings",
	"admin_settings_server_brand_certificate": "Your certificate",
	"admin_settings_server_brand_certificate_desc": "Insert your certificate here. Copy the entire content of your certificate!",
	"admin_settings_server_brand_email_commands_label": "Email commands text",
	"admin_settings_server_brand_email_commands_label_text": "Enter the i18n value string here (from the i18n translation file) that is being used for the link to the email commands in the email to internal users, i.e. \"Use email commands\"",
	"admin_settings_server_brand_email_commands_link_label": "Email commands link",
	"admin_settings_server_brand_email_commands_link_text": "Define the full URL to your email commands guide, e.g. https://helpmonks.com/kb/support/p/email-commands (this is the default)",
	"admin_settings_server_brand_header": "Branding",
	"admin_settings_server_brand_header_desc": "Change the name, links, and so forth that is being used in all outgoing communications",
	"admin_settings_server_brand_name_desc": "Change the name that is being used for all outgoing communication, e.g. in email subjects: \"[Helpmonks] Reminder\" or in descriptions like \"Read this conversation in Helpmonks\". By default, \"Helpmonks\" is being used.",
	"admin_settings_server_brand_private_key": "Your private key",
	"admin_settings_server_brand_private_key_desc": "Insert your private key here. Copy the entire content of your private key!",
	"admin_settings_server_brand_short_id_url": "Custom URL Shortener",
	"admin_settings_server_brand_short_id_url_desc": "Enter your own custom URL for providing a short link to your conversations. By default, the format will be http://yourdomain/go/id.",
	"admin_settings_server_brand_url_desc": "Change the URL that is being used for descriptions. By default, \"https://helpmonks.com\" is being used.",
	"admin_settings_server_domain_header": "Application domain",
	"admin_settings_server_domain_header_desc": "Enter the domain name for this Helpmonks application, e.g. https://e.helpmonks.com. Note: Add the value with the port (if any) and the http(s):// part!",
	"admin_settings_smtp_advanced_settings": "SMTP general settings",
	"admin_settings_smtp_advanced_settings_desc": "These settings apply to all SMTP server settings",
	"admin_settings_smtp_domain": "SMTP Domain Name",
	"admin_settings_smtp_email_size_limit": "Size limit",
	"admin_settings_smtp_email_size_limit_desc": "Set the email size in bytes of outgoing messages. The default size is 25 MB (26214400 bytes).",
	"admin_settings_smtp_email_size_limit_override_desc": "Mailbox can overwrite the size limit, i.e. you might limit it here to 25 MB but a mailbox could allow 50 MB. Having this checked allows this.",
	"admin_settings_smtp_email_size_limit_override_header": "Mailboxes can override the size limit",
	"admin_settings_smtp_is_default": "Default SMTP server",
	"admin_settings_smtp_is_secondary": "Secondary SMTP server",
	"admin_settings_smtp_is_ssl": "Use SSL",
	"admin_settings_smtp_is_tertiary": "Tertiary SMTP server",
	"admin_settings_smtp_max_smtp_send_attempts": "Attempts",
	"admin_settings_smtp_max_smtp_send_attempts_desc": "Set how many times the system should try to send an email before it stops. Note: Increasing this number causes your server to throttle sending.",
	"admin_settings_smtp_new": "Add another SMTP server",
	"admin_settings_smtp_password": "SMTP Password",
	"admin_settings_smtp_port": "SMTP Port",
	"admin_settings_smtp_remove": "Remove this SMTP server",
	"admin_settings_smtp_remove_button": "Yes, remove this SMTP server",
	"admin_settings_smtp_remove_desc": "Are you really sure that you want to remove this SMTP server configuration?",
	"admin_settings_smtp_remove_note": "Upon removing, all hosts that are using this SMTP configuration, will be reset to use the default SMTP server",
	"admin_settings_smtp_set_default": "Set this SMTP server for all hosts",
	"admin_settings_smtp_set_default_desc": "By setting this SMTP server configuration for all hosts, you will set/reset the chosen SMTP setting for each host.",
	"admin_settings_smtp_set_default_note": "This will NOT overwrite the SMTP configuration, if the user configured his SMTP server",
	"admin_settings_smtp_username": "SMTP Username",
	"admin_settings_wl_email_sales": "Your sales email-address",
	"admin_settings_wl_email_sales_desc": "This is the email-address that is used for any sales related descriptions",
	"admin_settings_wl_email_support": "Your support email-address",
	"admin_settings_wl_email_support_desc": "This is the email-address that is used for any support related descriptions",
	"admin_signin_error": "Oops, we could not sign you in successfully.",
	"admin_signin_header": "Sign in to Admin",
	"admin_signout_timeout": "You have been logged out!",
	"admin_status_app_errors": "Application errors",
	"admin_status_app_errors_desc_%s": "Below you will find a list of application errors. If you feel that there is an error that impacts your production environment please contact us at %s and copy & paste the error message. Thank you.",
	"admin_status_elastic_desc": "Use this panel to view indexed versions of emails as well as current mappings for Elasticsearch indexes.",
	"admin_status_incoming_desc": "Below you will find the log for all incoming emails",
	"admin_status_outgoing_desc": "Below you will find the log for all outgoing emails",
	"admin_status_processing_stats_desc": "real-time processing stats information, which will eventually be updated in real-time without a manual refresh",
	"admin_storage_avatars_desc": "Configure where Avatars should be stored. You can store them locally or on Amazon S3.",
	"admin_subnavigation_attachment": "Attachments",
	"admin_subnavigation_backup": "Backup & Restore",
	"admin_subnavigation_db": "Database",
	"admin_subnavigation_email": "Email",
	"admin_subnavigation_email_permissions": "Permissions",
	"admin_subnavigation_email_permissions_desc": "Configure your email permissions below. The global Block & Allow-list values will take effect for all mailboxes and will supplement blocked email-addresses of an account, too.",
	"admin_subnavigation_file_types": "File Types",
	"admin_subnavigation_maintenance": "Maintenance",
	"admin_subnavigation_monitoring": "Monitoring",
	"admin_subnavigation_security": "Security",
	"admin_subnavigation_tools": "Tools",
	"admin_subnavigation_trusted": "Trusted Servers",
	"admin_subnavigation_workflows": "Workflows",
	"admin_user_desc": "Please find all the users who have access to this backend administration below. All these are in the System-Administrator group and have access to all hosts, also. You can add host users in the administration of each host!",
	"admin_user_edit_desc": "Note: This is only for users who have access to this backend administration. You will want to add/edit users for hosts in the host administration!",
	"admin_user_edit_view": "Users Edit View",
	"administration_subheading_1": "Modify who has access",
	"administration_subheading_2": "Extend your app and go further",
	"administration_subheading_3": "Tweak account-wide settings",
	"administrator": "Administrator",
	"adv_search_button": "Find emails with the above selection",
	"advanced": "Advanced",
	"advanced_configuration": "Advanced",
	"advanced_configuration_desc_%s": "Configure the below settings to fine tune your %s server some more",
	"advanced_search": "Advanced Search",
	"advanced_settings": "Advanced settings",
	"advanced_settings_cache": "Cache",
	"advanced_settings_cache_desc": "Configure where to store the cache. Note: For a load balanced setup you should not use the memory option.",
	"advanced_settings_cache_store": "Storage location",
	"advanced_settings_socket": "Socket",
	"advanced_settings_socket_desc": "Configure what message transmitter you want to use for socket messages. Note: For a load balanced setup you should not use the memory option.",
	"advanced_settings_socket_store": "Storage location",
	"advanced_spam_setting": "Advanced spam settings",
	"advanced_spam_setting_desc": "Spam filters use a lot of different criteria to judge incoming email. Each factor is weighed and added up to assign a spam score, which determines if an email will pass through the filter of the mail server. Below settings allow you to enable the \"Spam Flag\" and the \"Spam Score\".",
	"advanced_spam_setting_spam_flag_desc_%s": "Some email servers add an additional \"X-Spam-Flag\" header to messages. If this header is set to true it means that they deem the message as spam. Once enabled, %s will look for this header as well and move such messages to the \"Spam\" folder.",
	"advanced_spam_setting_spam_score_desc_%s": "Some email servers add an additional \"X-Spam-Score\" header to messages with a value. The value is defined by the mail server's spam software. However, every mail server determines their own score. A good explanation of this can be found at in this <a href='https://litmus.com/help/testing/spam-scoring/' target='_blank'>spam score article</a>. We recommend a value of \"3\". Once enabled, %s will look for this header as well and move such messages to the \"Spam\" folder.",
	"after_days_%s": "After %s days",
	"after_how_long_without_a_reply_should_the_workflow_execute": "After how much time awaiting a reply should the workflow be executed",
	"after_one_year": "After 1 year",
	"after_year_%s": "After %s years",
	"agents": "Agents",
	"agents_statistics": "Agents Statistics",
	"alert_attachments_over_limit": "Not all attachments could be added because the total size of attachments is larger than the allowed sending limit.",
	"alert_enter_email": "Oops, enter at least one recipient for your message!",
	"alert_enter_name": "Oops, looks like you forgot to enter a name",
	"alert_enter_subject": "Oops, looks like you forgot to enter a subject",
	"alert_made_changes": "It looks like you've made changes. Are you sure you want to continue without saving your changes?",
	"alert_mailbox_email_empty": "Please enter an email address for your mailbox",
	"alert_mailbox_name_empty": "Please enter a name for your mailbox",
	"alert_no_access_to_report": "Your Administrator limited access to the user reports. Please contact your Administrators if you feel this is an error.",
	"alert_sending_email": "An error occurred sending your email. Please try again. If the error continues to happen please let us know by copying the error message below in a message to us. Thank you.",
	"alert_workflow_saved": "The workflow has been successfully saved.",
	"all": "All",
	"all_actions": "All actions",
	"all_activities": "All activities",
	"all_archived": "All archived",
	"all_assigned": "All assigned",
	"all_closed": "All closed",
	"all_collision": "All collisions",
	"all_conversations": "All conversations",
	"all_conversations_page_selected": "conversations on this page are selected.",
	"all_conversations_page_selected_%s": "All %s conversations on this page have been selected",
	"all_conversations_selected": "All conversations have been selected",
	"all_conversations_selected_%s": "All %s conversations have been selected",
	"all_conversations_selected_dynamic": "All <span id='show_select_all_text_success_total'></span> conversations have been selected",
	"all_conversations_with": "All conversations with",
	"all_conversations_with_company": "Conversations with other users of",
	"all_draft": "All drafts",
	"all_error_types": "all error types",
	"all_files": "All files",
	"all_files_selected": "All files in here are now selected",
	"all_funnels": "All Funnels",
	"all_inboxes": "All inboxes",
	"all_mailbox": "All mailboxes",
	"all_mailbox_subscribers": "all subscribers",
	"all_mailboxes": "All mailboxes",
	"all_mine": "All assigned to me",
	"all_pending": "All pending",
	"all_reminders": "All reminders",
	"all_sent": "All sent",
	"all_spam": "All spam",
	"all_statuses": "all statuses",
	"all_trash": "All trash",
	"all_users": "All users",
	"already_registered_groups": "'Groups' has already been registered",
	"always_share_drafts_desc": "If enabled, any draft, be it a new message or a reply, is automatically shared with your team members. This is an account-wide setting and applies to all users.",
	"always_share_drafts_header": "Always share drafts",
	"am": "AM",
	"an_error_occurred": "An error occurred",
	"analytics_announcements_header": "Announcements with the most views",
	"analytics_for": "Analytics for",
	"analytics_pages_desc": "The top 10 pages ranked",
	"analytics_pages_header": "Pages with the most views",
	"analytics_searches_desc_%s": "We show the top %s search terms here",
	"analytics_searches_header": "Most used search terms",
	"analyze_text": "Analyze text",
	"analyze_text_result": "We could extract the following text from the image...",
	"analyze_text_result_fail": "We are not able to analyze text within your image",
	"and": "and",
	"and_what_happens_is": "Select one or multiple actions to be executed upon the email when all of the above conditions are met",
	"announcement_add": "Add announcement",
	"announcement_category": "Announcement category",
	"announcement_header": "What's the title of this announcement?",
	"announcements": "Announcements",
	"announcements_page": "This is main page for your announcements",
	"announcements_show_kb": "Show announcements in docs",
	"announcements_show_kb_desc": "When enabled, your announcements will show within your Docs site on the homepage, in the top navigation, and also on a dedicated announcements pages. Use it to communicate, e.g., product updates, the latest about your store, etc.",
	"announcements_show_widget": "Show announcements in Chat widget",
	"announcements_show_widget_desc": "When enabled, your announcements will show the latest entry within your chat widget. Additionally, you can also create notifications for your announcements within a page.",
	"announcements_sort_order": "Sort announcements by...",
	"another_folder": "another folder",
	"answered": "answered",
	"anyone": "Anyone",
	"anyone_lower_%s": "Trigger for CUSTOMERS or %s users",
	"anyone_lower_desc": "Trigger workflow for all arriving messages from any sender",
	"api_key": "API Key",
	"api_key_delete_confirm": "Yes, revoke this API key",
	"api_key_delete_desc": "Do you really want to revoke this API Key?",
	"api_key_delete_header": "Revoke API Key",
	"api_key_delete_note": "All API call using this key will not work anymore. This action is immediately!",
	"api_key_revoke": "Revoke API Key",
	"api_keys": "API Keys",
	"api_keys_button": "Add a new API Key",
	"api_keys_button_another": "Add another API Key",
	"api_keys_desc_%s": "Create an API key to access data in %s programmatically. Head over to our <a href='https://developer.helpmonks.com/api/overview/' target='_blank'>Developer Documentation</a> to see what you can do with the API.<br><br>Note: We apply a rate limit to all API calls that prevents you to make exessive API calls (There is really no need to call an API every second. If you need that, we encourage you to talk to us. I'm sure we can find a better way)",
	"app_access": "App access",
	"app_access_desc": "Define which app section each of your user groups is allowed to access. Administrators always have access to every app.",
	"app_errors": "Application Errors",
	"append": "Append",
	"apply_labels": "Apply labels",
	"apply_labels_in_background": "The label(s) are being applied to the selected records in the background",
	"apply_labels_option": "Add one or multiple labels to the conversation",
	"apply_labels_option_customers": "Add one or multiple labels to the customer",
	"apply_settings_to_all_mailboxes": "Apply settings to all your mailboxes",
	"apply_settings_to_all_mailboxes_error": "We could not apply the SMTP settings to other mailboxes. Please try again or contact your administrator.",
	"apply_settings_to_all_mailboxes_success": "The SMTP setting was successfully applied to all your other mailboxes",
	"apply_to_all": "Apply to all",
	"approval": "Approval",
	"approval_approve_notification_%s": "%s has approved the draft. The message has been sent.",
	"approval_approved_message_sent": "The message is approved",
	"approval_approved_message_sent_desc": "You've successfully approved the draft. The message has been sent in the name of the original user. Also, all related users have been notified already.",
	"approval_button_send": "Request approval",
	"approval_button_send_another": "Request approval & compose another",
	"approval_draft_open": "Click here to view the draft",
	"approval_log": "Approval log",
	"approval_log_tooltip": "Show the approval logs for this message",
	"approval_message_open": "Click here to view the message",
	"approval_message_sent": "Approval request has been sent",
	"approval_message_sent_another": "Click here to compose another message",
	"approval_message_sent_approval_by": "The following users have been requested to approve your message:",
	"approval_message_sent_view": "Until someone approves your request you can find your message in the \"Drafts\" folder",
	"approval_pending": "Approval pending",
	"approval_reject": "Reject draft",
	"approval_reject_button": "Reject draft",
	"approval_reject_desc": "Please state a reason why you reject this draft. As soon as you confirm the rejection all relevant users will be notified.",
	"approval_reject_header": "Reject this draft",
	"approval_reject_message": "The message has been rejected",
	"approval_reject_message_desc": "We have notified all users of the rejection",
	"approval_reject_notification_%s": "%s has rejected the draft.",
	"approval_rejected": "Approval rejected",
	"approval_rejected_already": "This draft has already been rejected. You can find the reason for the rejection below.",
	"approval_rejected_desc": "Your request for <strong>approval was rejected</strong>. You can find the reason for the rejection below. You can edit the draft accordingly and ask for approval again.",
	"approval_rejected_header": "Reason for rejection",
	"approval_request_notification_%s": "%s is requesting your approval to send this draft",
	"approval_send": "Looks good. Send now",
	"approval_settings_desc": "This setting allows you to block this user from sending any emails (new messages or replies) to your customers without the approval from specific users. To do so activate this setting and select at least one user who is required to approve or reject the message. The approval process takes place within the Draft section.",
	"approval_settings_enable": "Enable approval process",
	"approval_settings_enable_desc": "If you enable this setting, please select at least one user who needs to approve/reject messages for this user",
	"approval_settings_header": "Approval for sending",
	"approvals": "Approvals",
	"approve_access": "Approve access",
	"approved": "Approved",
	"approver": "Approver",
	"archive": "Archive",
	"archived_%s": "%s archived this message",
	"archived_on": "Archived on",
	"are_subscribed": "Are subscribed",
	"arrives_between_times_of": "The email arrives between",
	"arrives_on_day": "on any of the following days",
	"ascending": "Ascending",
	"assign": "Assign",
	"assign_email_to": "Assign the conversation to a mailbox user",
	"assign_now": "assign now",
	"assign_selected_%s": "Assign the selected conversation%s to...",
	"assign_to": "Assign to",
	"assign_to_me": "Assign to me",
	"assign_to_myself": "Assign to myself",
	"assign_to_option": "Assign the conversation to...",
	"assigned": "Assigned",
	"assigned_folder_empty": "No emails are assigned. Consider improving your delegation skills. Delegating tasks helps manage your workload and empowers your team to grow.",
	"assigned_search": "Assigned (to me and others)",
	"assigned_selection_desc": "<strong>Person replying (if unassigned):</strong> This means that the conversation will be assigned to the user who replies if it was previously unassigned.<br><strong>Person replying:</strong> This means that the conversation will be assigned to the user who replies, even if already assigned to someone else.<br><strong>Anyone:</strong> This means that the conversation will be unassigned upon a reply and any assignee will be removed!",
	"assigned_selection_reply": "Person replying (resets the assignee)",
	"assigned_selection_reply_unassigned": "Person replying (if unassigned)",
	"assigned_to": "assigned to",
	"assigned_to_assigned_user": "You assigned this conversation to yourself",
	"assigned_to_group": "Assigned to group",
	"assigned_to_header": "Assigned to",
	"assigned_to_me": "Assigned to me",
	"assigned_to_noone": "assigned to everyone",
	"assigned_to_noone_%s": "%s assigned this conversation to everyone",
	"assigned_to_noone_user": "You assigned this conversation to everyone",
	"assigned_to_others": "Assigned to others",
	"assignee": "Assignee",
	"assignee_noone": "Anyone",
	"assignee_of_box": "Assign this box to",
	"assignee_set": "The assignee has been set successfully",
	"assignees": "Assignee's",
	"assigning_to_multiple_users": "If you select multiple users then the workflow will assign incoming emails evenly among them (Round-Robin)",
	"assignments": "Assignments",
	"associate_users_link": "Click here to associate existing users to this company with the above domains",
	"associate_users_status": "All users of the selected domain(s) have been associated with this company. This page will refresh in a few seconds now.",
	"associate_with": "Associate with",
	"associated_with": "Associated with",
	"at_3_pm": "at 3 pm",
	"at_8_am": "at 8 am",
	"at_a_specific_time": "At a specific time",
	"attachment_change_desc_%s": "Note: If you change the attachment settings you need to restart the %s application!",
	"attachment_condition_message": "The email's attachment status is",
	"attachment_expiration": "Interval to clean local storage",
	"attachment_expiration_desc": "Set in how many hours the system will start to remove local attachments. This only applies if you store your attachments on S3!",
	"attachment_label_text": "Attachment: Label text",
	"attachment_limit_%s": "Attachment limit: %s",
	"attachments_draft_days_%s": "Please note, that attachments are only available for %s days",
	"attachments_not_saved": "Please note, that attachments are NOT saved in a draft!",
	"attachments_panel_header": "Attachments",
	"attachments_storage": "Attachments / Storage",
	"attention": "Attention",
	"audios": "Audios",
	"authenticate_mailbox_for_syncing": "Authorize Mailbox for Syncing",
	"authenticate_users_header": "Authenticate your users",
	"author": "Author",
	"auto_activate": "Automatically activate this plugin for new hosts",
	"auto_reply": "Auto Reply",
	"auto_reply_subject_desc": "The auto-reply will send an email with the same subject as the incoming email. However, if the subject is empty it will use the subject above.",
	"auto_tag": "Auto-tag",
	"auto_tag_limit_hit": "Hey there! Just a quick heads up: you've hit your auto-tagging limit for this month. But don't worry. We have a solution that will give you access to unlimited features without any restrictions! Our UNLIMITED paid plans are your ticket to hassle-free and efficient tagging. Why not upgrade today and take advantage of everything we have to offer?",
	"auto_tagging": "Auto-tagging",
	"auto_tagging_desc": "Razuna provides a convenient auto-tagging feature that automatically analyzes your images and offers them for inclusion in your file metadata.",
	"auto_tagging_desc_2": "Free accounts include 50 auto-tagging runs, whereas users of a paid Razuna account have auto-tagging included.",
	"auto_tagging_notification_false": "Auto-tagging for your uploads has been disabled",
	"auto_tagging_notification_true": "Your uploads are taking advantage of auto-tagging",
	"auto_tagging_off": "Turn auto-tagging off",
	"auto_tagging_off_desc": "This option will turn off automated tagging on upload. However, you will still have the option to use auto-tagging for your files.",
	"auto_tagging_used": "Auto-tagging applied",
	"auto_tagging_used_this_month": "Auto-tagging used this month",
	"auto_tags_click_to_add": "Click on a tag to add it to your keywords",
	"auto_tags_generated": "The following tags were automatically generated",
	"automate_with_ease": "Automate with ease",
	"automation": "Automation",
	"automations": "Automations",
	"autoreply_%s": "Auto-reply regarding : '%s'",
	"autoreply_desc": "This auto-reply is being sent to all users. Please note that we only send the auto-reply to a user once every 24 hours.",
	"autoreply_ignore_time": "Send the reply each time (ignore the 24 hours rule)",
	"avatar": "Avatar",
	"avatar_fetch_desc": "We will try to fetch any Avatar picture from the social media links above",
	"avatars": "Avatars",
	"average_customer_reply_time_short": "Avg. customer reply time",
	"average_first_reply_time_short": "Avg. first reply time",
	"average_reply_time": "Average reply time",
	"average_reply_time_short": "Avg. reply time",
	"average_response_time_data": "average_response_time_data",
	"average_response_times": "Averages Response/Resolution Times",
	"average_user_first_reply_time_short": "Avg. user first reply time",
	"average_user_reply_time_short": "Avg. user reply time",
	"avg_first_response_time": "Average first reply time",
	"avg_first_response_time_abr": "Avg. first reply time",
	"avg_resolution_time": "Average resolution time",
	"avg_response_time": "Average reply time",
	"avg_response_time_abr": "Avg. reply time",
	"back": "Previous",
	"back_to_active_tasks": "Back to active tasks",
	"back_to_campaigns": "Back to Campaigns",
	"back_to_collections": "Back to collections",
	"back_to_contacts": "Back to Contacts",
	"back_to_conversation": "Go back to the conversation",
	"back_to_files": "Back to files",
	"back_to_mailbox": "Back to Mail",
	"back_to_sequences": "Back to Sequences",
	"back_to_users": "Back to Users",
	"back_to_workspaces": "Back to workspaces",
	"banned_if_unverified": "banned if unverified",
	"bcc": "Bcc",
	"between_times_of": "between the hours of",
	"blacklist": "Blocked email-addresses or domains",
	"blacklisted_email_addresses_%s": "The following email-address(es) are blocked and may not be used when composing emails : %s",
	"blackwhitelist_log_desc": "Find all activities related to the Email-Filters below below",
	"block_emails_desc": "Block emails & Spam",
	"block_outgoing_communication_with_black_listed_addresses": "Block outgoing communication with addresses on the block/allow filters",
	"block_outgoing_communication_with_black_listed_addresses_desc_%s": "Enabling this setting prevents outgoing messages to be sent to any email addresses which don't pass the selected email-address filter validation above. When composing emails in %s the blocked addresses will be shown on send.",
	"blocking_email_addresses": "Define Email Filters",
	"blog": "Blog",
	"blog_%s": "%s Blog",
	"blog_desc_%s": "Get the latest news on %s",
	"bookmark": "Bookmark",
	"bookmark_record_added": "Saved to Bookmarks",
	"bookmark_record_removed": "The bookmark has been removed",
	"bookmarks": "Bookmarks",
	"bookmarks_empty": "Looks like you have no Bookmarks yet",
	"bookmarks_empty_desc": "Bookmarks are a convenient way to quickly open a contact, file, or a conversation. Click on the Bookmark Icon within a record and your Bookmarks will show up here.",
	"bookmarks_records_removed": "All bookmarks in this section have now been removed",
	"bookmarks_remove_all": "Remove all Bookmarks",
	"box_add_call": "added call log",
	"broadcast": "broadcast",
	"broadcast_message": "broadcast message",
	"browse_all_files": "Browse all files",
	"browse_files_add_collection": "Browse your files and add them to the collection to get started.",
	"browser": "Browser",
	"browsers": "browsers",
	"btn_activate_users": "Activate Users",
	"btn_deactivate_users": "Deactivate Users",
	"button_add_company": "Add Company",
	"button_add_host": "Add new host",
	"button_add_user": "Add new user",
	"button_add_workflow": "Create a new workflow",
	"button_create_news": "Add new entry",
	"button_remove_record": "Yes, remove now",
	"button_save_note": "Save note",
	"button_send_another": "Send & New",
	"button_send_message": "Send message",
	"button_send_reply": "Send reply",
	"button_text": "Button text",
	"button_update_setting": "Yes, update my settings",
	"button_yep": "Yep, let's do this",
	"by_date_added": "Date added",
	"by_email": "By email",
	"by_file_size": "File size",
	"by_sender": "By sender",
	"by_size": "By size",
	"by_subject": "By subject",
	"cache_browser_desc_%s_%s": "%s uses your browser cache to store static files without reloading them each time from the server. This speeds up your experience in %s manifold.<br><br>However, it can happen that some elements become stale.<br><br>By clicking the button below, your browser will flush the cache, reload, and fetch everything as new from the server",
	"cache_browser_flush_button": "Flush browser cache now",
	"cache_desc_%s": "%s uses cache were needed. Sometimes it can be that the cache gets out of sync. With the button below you can flush the cache for all users. This renews the cache and fetches updates values from the database directly.<br><br><strong>Once you click the button below, the caches of your account and the cache of each user that is currently online will be refreshed. This is an noninvasive action and the users will not need to sign-in again or lose any data.</strong><br><br>Note: You only need to do this if you feel there is an issue or have been instructed by us to do so.",
	"cache_flush_button": "Flush cache now",
	"cache_flushed": "The cache has been successfully flushed",
	"cache_header": "Cache",
	"cache_header_browser": "Flush your browser cache",
	"cache_reset_desc": "We use different kinds of caches and cookies to speed up your experience. Sometimes it can be that elements get out of sync, i.e., the cache gets stale.<br><br>When this happens you might have difficulties seeing updated content or other issues. While you usually don't need to worry about this, it might still happen.<br><br>By clicking the button below, all you application data will be refreshed. Think of it, as a restart of your machine. Just here you don't need to restart or wait :)",
	"cache_reset_desc_%s_%s": "%s uses different kinds of caches and cookies to speed up your experience. Sometimes it can be that elements get out of sync, i.e., the cache gets stale.<br><br>When this happens you might have difficulties seeing updated content or other issues. While you usually don't need to worry about this, it might still happen.<br><br>By clicking the button below, all %s data will be refreshed. Think of it, as a restart of your machine. Just here you don't need to restart or wait :)",
	"call": "Call",
	"call_external_url": "Enter the full URL to which the conversation gets sent to",
	"call_external_url_desc": "Make sure your URL accepts POST requests.<br><br>Additionally, you can add dynamic custom fields as parameters that will be evaluated, e.g., if you have a custom field call \"projectId\" and \"shippingInfo\", and you want to send the value in the URL, you would then use: \"https://yoururl?project_id=$projectId__REPLACE_WITH_TRANSLATION__shipping_info=$shippingInfo$\".<br><br>Please see <a href='https://helpmonks.com/kb/support/p/how-to-use-dynamic-variables-in-your-url' target='_blank'>this guide to explore all available variables</a>.",
	"call_external_url_option": "Web-hook (call external url)",
	"call_made_on": "Call made on",
	"call_task": "What do you want to call it",
	"calls": "Calls",
	"campaign": "Campaign",
	"campaign_archive_empty": "Looks like you haven't sent any email campaigns",
	"campaign_archive_remove_desc": "Please confirm that you want to remove this campaign",
	"campaign_archive_remove_header": "Remove send campaign",
	"campaign_begin": "Begin campaign",
	"campaign_date_send": "Send campaign on selected date and time",
	"campaign_draft_remove_button": "Yes, remove draft",
	"campaign_draft_remove_desc": "Please confirm that you want to remove this draft",
	"campaign_draft_remove_header": "Remove draft",
	"campaign_drafts": "Your Campaigns Drafts",
	"campaign_drafts_empty": "Looks like you have no drafts or scheduled campaigns",
	"campaign_drafts_scheduled": "Your Scheduled Campaigns Drafts",
	"campaign_drafts_scheduled_desc": "Scheduled Campaigns are saved as drafts until they are sent",
	"campaign_final_preview": "Final preview & Send campaign",
	"campaign_list_remove_desc": "Please confirm that you want to remove this list",
	"campaign_list_remove_header": "Remove distribution list",
	"campaign_name": "Campaign name",
	"campaign_new": "Create a new campaign",
	"campaign_new_desc": "Share your latest news, announce a new event, or a new product or service by sending a new email campaign.",
	"campaign_ready_templates": "Ready-to-use Templates",
	"campaign_review_desc": "Please make sure to check everything before sending the campaign",
	"campaign_review_header": "Final preview",
	"campaign_running": "Campaign running",
	"campaign_scheduled_confirmation_desc": "The campaign is scheduled to be sent on the selected date and time",
	"campaign_scheduled_confirmation_header": "Campaign scheduled",
	"campaign_sent_confirmation_desc": "The campaign is in the sending queue and will soon go out. Go to \"Sent Campaigns\" to monitor and retrieve real-time statistics for your campaigns.",
	"campaign_sent_confirmation_header": "Campaign is queued for sending",
	"campaigns": "Campaigns",
	"campaigns_smtp_header": "How should we send your email campaigns?",
	"can_download": "Can download",
	"can_download_desc": "View and download files",
	"can_edit": "Can edit",
	"can_edit_desc": "Edit, delete, download, and upload files",
	"can_view": "Can view",
	"can_view_desc": "View files only",
	"cancel": "Cancel",
	"cancel_send": "Cancel send",
	"cancel_sending": "Cancel sending",
	"canonical_desc": "If this page is a duplicate of another page, update the canonical URL to point to the original page. A canonical URL is a fully qualified URL address. Leave this empty to keep the canonical URL of this page.",
	"card_description": "card_description",
	"catch_all_header": "Catch all email address",
	"catch_all_header_desc": "Enter the catch all email address. This is the address where all emails for the hosts of this server are being sent to and is usually the same value as you sign in to your IMAP server (the user setting above)",
	"categories": "Categories",
	"category": "Category",
	"category_delete": "Delete this list",
	"category_delete_note_todo": "If you delete this list, the list will also be removed from all To-Dos",
	"category_delete_text": "Are you sure that you want to delete this list?",
	"category_edit": "Edit this list",
	"cc": "Cc",
	"change_dashboard_view": "Change View",
	"change_restart_%s": "Note: If you change these settings you need to restart the %s application!",
	"change_status": "Change status",
	"change_status_option": "Change the status of the conversation to...",
	"change_status_options": "Change the status of the conversation",
	"change_status_to": "Update the status of the conversation to...",
	"change_takes_effect_immediately": "Any modifications made will take effect immediately",
	"chat_active": "Active Chats",
	"chat_feature_announcement_desc": "Show a \"News/Announcement\" section in the widget. This will show a dedicated announcement section to your customers.",
	"chat_feature_feedback_desc": "Show a \"Feedback\" section in the widget. Customers can add ideas or report issues directly within the widget.",
	"chat_feature_welcome_header": "Show welcome section",
	"chat_feature_welcome_header_desc": "Show a \"Home/Welcome\" section in the widget with information, quick links, and more as defined by you.",
	"chat_go_to": "Go to chat",
	"chat_group": "Chat Group",
	"chat_groups": "Chat Groups",
	"chat_header": "Chat",
	"chat_icon_desc": "Set a custom icon for your chat bubble. Chose the icon you like from the <a href='https://fontawesome.com/search' target='_blank'>FontAwesome library</a> and insert the icon name in the field above. This is the part AFTER fa-",
	"chat_info": "Chat info",
	"chat_integration": "Chat integration",
	"chat_messages_desc": "Create or edit chat messages here. You can use these to invite a customer to chat or make them aware of a special promotion or news that you want them to see.",
	"chat_messages_header": "Chat Messages",
	"chat_recent": "Recent Chats",
	"chat_trigger_saved_reply": "Hit \"#\" for your saved replies",
	"chat_variations": "Widget behavior",
	"chat_variations_both": "Show the option to search in the knowledge-base and the option to live-chat with your team. This is the most customer friendly solution.",
	"chat_variations_chat": "Show a \"chat\" section in the widget so customers can live chat with your agents.",
	"chat_variations_desc": "Define what should be included in your chat widget, e.g., if you use a knowledge-base, you can have your visitors search in the knowledge-base directly and optionally chat with you. Alternatively, you can enable chat only or only show the knowledge-base.",
	"chat_variations_kb": "Show a knowledge-base section in the widget. Customers will then be able to search and browse your documents within the widget directly.",
	"chat_widget": "Chat widget",
	"chats": "Chats",
	"checkbox_workflow": "Enable workflow",
	"choose_default_folder": "Choose a default folders",
	"choose_specific_time": "Choose a specific date and time",
	"cities": "cities",
	"classic": "Classic",
	"clear_activity_log": "Remove all activity log items",
	"clear_activity_log_desc_modal": "Clearing the activity log will remove ALL items from the log",
	"clear_activity_log_desc_modal_note": "Please note, this action cannot be undone!",
	"clear_activity_log_header": "Clear Activity Log",
	"clear_filter": "Clear filter",
	"clear_log": "Clear log",
	"clear_log_entries": "Do you want to remove all entries from this log?",
	"clear_log_entries_note": "Note: the removed entries are irretrievable",
	"clear_selection": "Clear selection",
	"click_action_to_add": "Click on action to add",
	"click_goto_conversation": "Click here to go to the conversation now",
	"click_to_copy": "Click on the forwarding address to copy it to your clipboard.",
	"click_to_refresh": "Click here to refresh this conversation now",
	"click_to_refresh_page": "Click to refresh page",
	"click_to_sort": "Click to sort",
	"click_to_view": "Click to view",
	"clicked": "Clicked",
	"clicked_on_link": "Clicked on any link",
	"clicked_on_link_no": "Have not clicked on any link",
	"clicks": "Clicks",
	"client_users": "Client Users",
	"clone": "Clone",
	"close": "Close",
	"close_chat": "Close chat",
	"close_chat_button": "Yes, close chat",
	"close_chat_desc": "Are you sure that you want to close this chat now?",
	"close_chat_note": "Closing the chat will close the chat for the customer too.",
	"close_window": "Close window",
	"close_window_long": "You can safely close this window now",
	"closed": "Closed",
	"closed_%s": "%s closed this message",
	"co_administrators": "Co-Administrators",
	"co_administrators_desc": "Add members here who you want to act as Co-Administrators for your account. Co-administrators can manage users and groups and gain access to all workspaces under your control (except your personal workspace).",
	"collapse_all": "Collapse all",
	"collect_plus_browser_ext_prompt": "Use Collect+ in your browser",
	"collect_plus_empty_subline": "Collect anything - URLs, images, videos, notes, files, etc. Add them here, and never forget a thing again!",
	"collection_click_on": "Click on a collection to add the file(s) to it",
	"collection_is_private": "This collection is for my eyes only",
	"collection_remove": "Remove from collection",
	"collection_removed_done": "The selected file(s) have been removed from your collection",
	"collection_update_successfully": "The collection has been updated successfully",
	"collections": "Collections",
	"collision": "Collision",
	"collision_detection_desc": "Collision detection automatically checks replies from your team if someone else already replied and prevents the message from being sent to the customer.<br><br>Here you can select how many days, weeks, or months you want the collision detection to detect a collision. For example, if you set the below to one week, then a follow-up reply wouldn't be a collision if written after one week",
	"collision_detection_header": "Collision detection",
	"collision_header_personal": "Hey, someone from your team already replied",
	"collision_remove_selected_header": "Remove the selected collision(s)",
	"collision_remove_selected_text": "Are you sure that you want to remove the selected collision(s)?",
	"collision_wrote_%s": "%s wrote",
	"collisions": "Collisions",
	"collisions_remove_after": "Collisions are automatically removed after 30 days",
	"color": "Color",
	"combine_replies_by_subject": "Combine customer's replies by subject",
	"combine_replies_by_subject_desc": "Enable this option to automatically match replies from this sender to existing conversations (with the sender) with same subject. This can be useful with Amazon and Ebay proxy emails, which do not include standard headers to match replies.",
	"comma_or_tab": "Hit tab or use a comma to separate values",
	"comma_separate_values": "separate multiple values with a comma (,)",
	"comment_is_public": "This is a public comment",
	"comments": "Comments",
	"community_forums_desc": "Please use the Helpmonks Community Forums for feature requests or bug reports, unless you have a running support and update contract with us.",
	"companies": "Companies",
	"company": "Company",
	"company_header_last_15": "The latest 15 messages from company",
	"company_list": "Company List",
	"company_logo": "Your Company Logo",
	"company_logo_desc": "Add your own company logo for the login screen, within the system, and on all shared screens. For best results, use a logo with a maximum height of 35px.",
	"company_logs": "Company logs",
	"company_members": "Members of this company",
	"company_name": "Company name",
	"company_user_logs": "Customer",
	"company_users": "Company Users",
	"complete_now": "complete now",
	"completed": "Completed",
	"completed_%s": "%s changed the status to completed (closed and marked as answered)",
	"completed_cards": "Completed cards",
	"compose_mailbox_switch_button": "Switch mailbox",
	"compose_mailbox_switch_desc": "Please note that switching the mailbox will reset the message, status, and assignee selection.",
	"compose_user_panel_only_one": "We only display the first user found",
	"conditions": "Conditions",
	"configure": "Configure",
	"configure_forwarding_%s_%s_%s_%s_%s_%s": "We're sure you will love your new mailbox. When you are ready to complete your setup simply begin forwarding emails from <span class='fw-bolder'>%s</span> to your %s shared mailbox address at <a href='mailto:%s' target='_blank' class='dont-break-out'>%s</a> and let %s manage the rest. Simply copy the address below and configure your mail server.",
	"configure_forwarding_%s_%s_%s_%s_%s_%s_BEFORE": "We are sure you love your new mailbox. When you are ready to get serious, simply forward emails from <a href='mailto:%s' target='_blank' class='dont-break-out'>%s</a> to your %s mailbox address at <a href='mailto:%s' target='_blank' class='dont-break-out'>%s</a> and let %s manage the rest. Simply copy the address below and configure your mail server.",
	"configure_forwarding_help_desc_%s_%s_%s_%s": "Jump over to our <a href='https://helpmonks.com/kb/support' target='_blank' class='dont-break-out'>how-to guides</a> to get help immediately. See how to <a href='https://helpmonks.com/kb/support/p/forward-email-from-google-to-helpmonks-gmail-and-google-apps-' target='_blank' class='dont-break-out'>forward emails from a Google Apps/Gmail account</a>, <a href='https://helpmonks.com/kb/support/p/forward-emails-from-office365-microsoft365-to-helpmonks' target='_blank' class='dont-break-out'>forward emails from Office365</a>, or how to <a href='https://helpmonks.com/kb/support/p/how-to-disable-spam-filter-in-google-apps-and-or-gmail' target='_blank' class='dont-break-out'>disable the SPAM filter on Google Apps/Gmail</a>. (Check <a href='https://helpmonks.com/kb/support' target='_blank' class='dont-break-out'>our how-to guides</a> for other mail-servers)<br><br>Also, make sure to familiarize yourself <a href='https://helpmonks.com/kb/support/p/how-mailboxes-work' target='_blank' class='dont-break-out'>how a mailbox in %s works</a> and <a href='https://helpmonks.com/kb/support/p/working-with-conversations' target='_blank' class='dont-break-out'>how to work with conversations</a>.<br><br>In addition to manage emails in %s you can also keep using your email client and leverage email commands. The <a href='https://helpmonks.com/kb/support/p/email-commands' target='_blank' class='dont-break-out'>email commands how-to page</a> gets you quickly going.<br><br>Last but not least, please always reach out to us at <a href='mailto:%s' target='_blank' class='dont-break-out'>%s</a> or chat with us directly here in Helpmonks. We will be happy to help!",
	"confirm_delete": "Yes, delete record",
	"connection_established": "Connection established",
	"connection_established_desc": "We could successfully establish a connection to your mailbox. Your import will start soon.",
	"connection_header": "Connection details",
	"contact": "Contact",
	"contact_form": "Contact form",
	"contact_form_email": "Send contact form to...",
	"contacts": "Contacts",
	"contacts_and_companies": "Contacts & Companies",
	"contacts_subheading_1": "Get to know your customers",
	"contains": "contains",
	"contains_any_of_these_labels": "The email contains any of the following labels",
	"contains_any_of_these_labels_customers": "The customer record contains any of the following labels",
	"contains_labels": "contains labels",
	"contains_labels_option": "The email conversation contains one or many labels",
	"contains_labels_option_customers": "The conversation is from a customer containing one or many labels",
	"content": "Content",
	"continue": "Continue",
	"Continue": "Continue",
	"conversation": "Conversation",
	"conversation_changed": "The conversation has been changed to be with",
	"conversation_moved_to_%s": "The conversation has been moved to the \"%s\" folder in the background.",
	"conversation_original_by": "Conversation started by",
	"conversation_started_by": "Conversation with",
	"conversation_with_more_%s": "and with %s other people",
	"conversation_with_more_singular": "and with 1 other person",
	"conversations": "Conversations",
	"conversations_are_selected": "conversations are selected.",
	"conversations_assigned_to_me": "Conversations assigned to me",
	"conversations_assigned_to_others": "Conversations assigned to others",
	"conversations_count_%s": "%s conversations",
	"conversations_count_page_%s_%s": "%s of %s conversations",
	"conversations_moved_to_%s": "The selected conversations have been moved to the \"%s\" folder in the background.",
	"conversations_selected_%s": "%s selected",
	"copied_to_clipboard": "Copied to your clipboard...",
	"copied_token": "I have copied the token",
	"copy": "Copy",
	"copy_email_address": "Copy email address to clipboard",
	"copy_paste_images": "Tip: You can copy & paste images into the text above",
	"copy_paste_import_header": "Copy & Paste Import",
	"copy_to_clipboard": "Copy to clipboard",
	"copy_to_mailbox": "Copy to mailbox",
	"copy_to_workspace": "Copy to workspace",
	"copy_to_workspace_desc": "We will copy all tags to the selected workspace and will not overwrite any existing tags in the workspace with the same name.",
	"copy_to_workspace_status": "All tags have been copied to the selected workspace",
	"copyright": "Copyright status",
	"copyright_info_url": "Copyright info URL",
	"copyright_notice": "Copyright notice",
	"copyrighted": "Copyrighted",
	"countries": "countries",
	"create_box_in": "Create box in",
	"create_first_tag": "Create your first tag",
	"create_folder": "Create a new folder",
	"create_folder_button": "Add this folder",
	"create_link": "Create link",
	"create_new_collection": "Create new collection",
	"create_new_list": "Create a new list",
	"create_new_mailbox": "Create new mailbox",
	"create_new_mailbox_desc": "Click on the button below to create a new mailbox. Each mailbox has its own settings. You need a new mailbox in order to receive email for another shared email address, e.g. if you already have a sales@yourdomain.com mailbox and want to receive emails for support@yourdomain.com. Creating a new mailbox only takes a second.",
	"create_new_sequence": "Create a new sequence",
	"create_option": "Create",
	"create_page": "Create page",
	"create_template": "Create a new template",
	"create_this_list": "Create this list",
	"created": "Created",
	"created_by": "Created by",
	"created_by_%s_%s": "Created by %s on %s",
	"cron_schedule_and_run_once_date_are_mutually_exclusive": "a scheduled task cannot have both a cron schedule and a run once date",
	"csv_import_header": "CSV Import",
	"csv_select": "Please select your CSV file",
	"current_index": "Current Index",
	"current_plan": "Current Plan",
	"custom_css": "Custom CSS",
	"custom_css_desc": "Add custom CSS into the field above. Your CSS is automatically wrapped with a style element.",
	"custom_css_link": "Custom CSS URL",
	"custom_css_link_desc": "Link to a CSS file and it will automatically added to the page.",
	"custom_domain": "Custom domain",
	"custom_domain_desc": "Set your custom domain above, e.g., docs.mydomain.com and then set up a CNAME on your DNS to point to our subdomain.",
	"custom_favicon": "Custom favicon",
	"custom_field_delete_desc": "Are you sure that you want to delete this custom field? All related data of the field will be removed as well.",
	"custom_field_delete_header": "Delete custom field",
	"custom_js": "Custom JS",
	"custom_js_desc": "Add custom JS into the field above. Your JS is automatically wrapped with a script element.",
	"custom_js_link": "Custom JS URL",
	"custom_js_link_desc": "Link to a JS file and it will automatically added to the page.",
	"custom_login_image": "Custom login image",
	"custom_login_image_desc": "By default, we randomly select an image from over 300 beautiful full-screen photos on the login screen. Upload your custom login image to show this image instead. Make sure the image size is at least 1920 x 1280 pixels.",
	"custom_time_range": "Custom time range",
	"custom_url_desc": "Run Helpmonks with your domain. Create a CNAME record in your DNS server and point it to our domain.",
	"custom_url_desc_2": "This is your custom URL WITHOUT http(s)://, e.g., inbox.mydomain.com.",
	"customer": "Customer",
	"customer_list": "Customer List",
	"customers": "Customers",
	"customers_only": "Trigger for CUSTOMERS",
	"customers_only_desc": "Trigger only when a message arrives from a customer",
	"customization_lead": "Add your own company logo and background image for the login screen. For best results, we recommend to use a logo with a width of 120px. Background images should be of 1920px width and 1080px height.",
	"danger_zone": "Danger zone",
	"dashboard": "Dashboard",
	"dashboard_activity_over_days_%s": "My activity over the last %s days",
	"dashboard_activity_over_months_%s": "My activity over the last %s months",
	"dashboard_count_assigned_%s": "Assigned to others (%s)",
	"dashboard_count_assigned_one_%s": "Assigned to others (%s)",
	"dashboard_count_me_%s": "Assigned to me (%s)",
	"dashboard_count_me_one_%s": "Assigned to me (%s)",
	"dashboard_customize": "Customize Dashboard",
	"dashboard_customize_cancel": "Cancel customization",
	"dashboard_customize_desc": "Customize your dashboard arrangement by drag and dropping the below panel and/or activating the panels and buttons.",
	"dashboard_customize_done": "Leave customization screen",
	"dashboard_customize_reset": "Reset customization",
	"dashboard_customize_save": "Save customization",
	"dashboard_header": "My Dashboard",
	"dashboard_users_average_responses": "Average number of replies per day",
	"data_sent": "Data sent",
	"date": "Date",
	"date_changed": "Modified on",
	"date_created": "Created on",
	"date_example_%s": "i.e. %s",
	"date_format": "YYYY-MM-DD",
	"date_format_text": "Date Format",
	"date_on": "On ",
	"date_page_created": "The date the page was created",
	"date_page_name": "The title of the page",
	"date_page_updated": "The date the page was last updated",
	"date_received": "Date received",
	"date_sent_header": "Date Sent",
	"date_within": "Date within",
	"day": "day",
	"days": "Days(s)",
	"days_plural": "Days",
	"deactivate": "Deactivate",
	"deactivate_message": "If you deactivate the user, you can activate the user at any time again, however the user will need to be re-added to any mailboxes for which they should have permission.",
	"deactivate_user_note": "The user can be reactivated at any time as needed, however upon reactivation they will need to be added to any mailboxes to which they will need access.",
	"deactivate_user_prompt_%s": "This action will deactivate the user, he or she will no longer receive emails as a mailbox user nor be able to log into %s.",
	"deactivated": "deactivated",
	"deauthorize_account_for_syncing": "Deauthorize Account for Syncing",
	"debug_option": "Debug",
	"default": "Default",
	"default_assignee": "Default Assignee",
	"default_assignee_desc_%s": "Set the default assignee when you reply",
	"default_assignee_desc_new": "Set the default assignee for the new email",
	"default_mailbox": "Default mailbox",
	"default_mailbox_roadmap_desc": "The selected mailbox is the one that will receive notifications on posts, comments, and any other related activities of a post.",
	"default_reply_email": "default from address",
	"default_reply_email_desc": "Specify the from address to use when sending system email notifications",
	"default_reply_email_help_%s": "If left blank, all notification email messages will be sent from %s",
	"default_status": "Default status",
	"default_status_assignee_header": "Set the status and assignee for emails",
	"default_status_assignee_header_desc": "Set which status and assignee option should automatically selected for replies or for composing or forwarding an email.",
	"default_status_assignee_header_desc_notes": "Set which status and assignee option should automatically selected for notes.",
	"default_status_assignee_header_notes": "Set the status and assignee for notes",
	"default_status_desc": "This is the status that will be set when you reply to an email",
	"default_status_desc_2": "Please note that changing the status to \"INBOX\" will remove the assignee!",
	"default_status_desc_new": "This is the status that will be set for the new email",
	"deferred": "Deferred",
	"delete": "Delete",
	"delete_after_days_%s": "Conversations that are not updated for %s days will be deleted.",
	"delete_after_one_year": "Conversations that are not updated for 1 year will be deleted.",
	"delete_after_year_%s": "Conversations that are not updated for %s years will be deleted.",
	"delete_all_in_company": "Delete all conversations of this company after",
	"delete_all_in_contact": "Delete all conversations of this contact after",
	"delete_all_in_mailbox": "Delete all conversations in this mailbox after",
	"delete_all_spam_now": "Delete all spam messages now",
	"delete_box": "Delete this box",
	"delete_box_desc": "Are you sure that you want to remove this box?",
	"delete_box_note": "No emails or contact information will be removed. However, all notes, call and meeting logs, reminders, and tasks will be irrevocably deleted.",
	"delete_collection": "Delete collection",
	"delete_collection_desc": "Do you really want to delete this collection?",
	"delete_collisions": "Delete selected collisions",
	"delete_cookies_%s": "Also remove all cookies that %s sets (Note: You will need to sign in again)",
	"delete_drafts": "Delete selected drafts",
	"delete_folder": "Delete folder",
	"delete_folder_confirm": "Yes, delete this folder",
	"delete_folder_desc": "Are you sure that you want to delete this folder? Note: All files and sub-folders within will also be deleted.",
	"delete_forever": "Delete forever",
	"delete_group": "Delete this group",
	"delete_group_desc": "Please confirm that you want to delete this group?",
	"delete_link": "Delete link",
	"delete_link_confirm": "Yes. Delete link.",
	"delete_link_desc": "Do you really want to delete this link? The link will become inaccessible immediately.",
	"delete_mailbox": "Delete Mailbox",
	"delete_mailbox_desc": "<strong>Delete Mailbox</strong><br>If you don't need this mailbox anymore you can remove it here. Please know that all email conversations will be lost forever and this action is irreversible!<br>",
	"delete_mailbox_desc_modal": "Please click on the button below to confirm that you want to remove this mailbox.",
	"delete_mailbox_desc_modal_note": "Please know that all email conversations will be lost forever and this action is irreversible!",
	"delete_post": "Delete post",
	"delete_post_note": "Note: The post and all comments will be permanently removed. This action is irreversible.",
	"delete_post_text": "Please confirm that you really want to delete this post",
	"delete_record": "Yes, delete this record",
	"delete_record_header": "Delete record",
	"delete_selected_companies": "Remove selected companies",
	"delete_selected_companies_desc": "Do you really want to remove the selected companies?",
	"delete_this_mailbox": "Delete this mailbox",
	"delete_this_mailbox_confirm": "Yes, delete this mailbox",
	"delete_this_mailbox_confirm_text": "Please confirm once more that you understand the implication of removing this mailbox.",
	"delete_workspace": "Delete workspace",
	"delete_workspace_confirm": "Yes, delete workspace",
	"delete_workspace_desc": "Are you sure that you want to delete this workspace? Note: All files and sub-folders within will also be deleted.",
	"deleted_mailbox": "deleted mailbox",
	"deleted_user": "deleted user",
	"delivery_log": "Delivery log",
	"deregister": "De-register",
	"Deregistered": "De-registered",
	"desc_forgot_password": "Enter your email address below and we'll send you a password reset email.",
	"desc_not_found_user": "A user with this email address could not be found",
	"desc_reset_password": "Please enter your new password below.",
	"desc_sent_forgot_password": "Instructions for signing in have been emailed to you",
	"descending": "Descending",
	"describe_workflow_listing": "Active workflows will execute in the order in which they appear above.  Drag and drop workflows to update their execution order.",
	"description": "Description",
	"deselect": "Deselect",
	"deselect_all": "Deselect all",
	"design": "Design",
	"desktop": "Desktop",
	"desktop_notification_enabled": "Desktop notifications are now enabled. Happy times ahead :)",
	"desktop_notification_header": "Desktop notification",
	"desktop_notification_not_supported": "We are sorry, but it looks like this browser does not support desktop notifications!",
	"details": "Details",
	"didnt_receive_the_verification_email": "Didn't receive a verification email?",
	"direct_links": "Direct links",
	"direct_links_desc": "Use the following links to embed files into your website or for direct access. Alternatively, use the share option to create more interactive shares with expiration dates, passwords, and other options.",
	"disable": "Disable",
	"disable_adding_workspace": "Disable adding workspaces",
	"disable_adding_workspace_desc": "With this option, you can prevent users from creating personal workspaces.",
	"disable_collect_plus": "Disable Collect+",
	"disable_collect_plus_desc": "Collect+ is a tool that allows users to collect images, text, URLs, and more from the web and store in their personal space. To disable it for all users, select this option.",
	"disable_help_meeting": "Disable meeting link",
	"disable_help_meeting_desc": "Our help section allows users to schedule meetings directly with us. Choosing this option will remove the scheduling link.",
	"disable_help_reddit": "Disable community (Reddit) link",
	"disable_help_reddit_desc": "Our help section provides an option for users to connect with us on Reddit. Selecting this option will remove the link to Reddit.",
	"disabled": "Disabled",
	"disabled_email_templates_%s": "Email templates are not editable during the trial period. If you'd like updates made to the template during your trial please reach out to %s along with the updates you'd like made to the Welcome Email",
	"disabled_mailbox_email_change": "A mailbox's email address cannot be changed once set here. Either create a new mailbox or contact us as we can make the change for you.",
	"discard": "Discard",
	"distribution_lists": "Distribution lists",
	"dkim_configure_header": "Please create the following DKIM records to your DNS",
	"dkim_configured": "DKIM record is configured",
	"dkim_desc": "Create the following records in your DNS server",
	"dkim_not_configured": "DKIM record is not configured",
	"dkim_record_desc_%s": "DKIM is used to verify the authenticity of messages sent from your domain. It allows the receiver to check that an email claimed to come from a specific domain was indeed authorized by the owner of that domain. It is intended to prevent forged sender addresses in emails, a technique often used in phishing and email spam.<br>Please see our <a href='https://helpmonks.com/kb/support/p/using-dkim-to-help-with-email-delivery' target='_blank'>how-to guide on configuring your DKIM records</a>.",
	"dns_associations": "Associated Domains",
	"dns_issue_alert_desc": "We detected an issue with your DNS settings. This could severely impact your email deliverability!",
	"dns_issue_alert_header": "Sending not optimized",
	"dns_subline": "Please note, that some DNS servers take up to 48 hours to propagate your changes.",
	"do_not_disturb": "Do not disturb",
	"do_not_disturb_desc": "if \"Do not disturb\" is enabled, the user will not receive any email or desktop notifications.",
	"do_not_disturb_desc_user": "If \"Do not disturb\" is enabled, you will not receive any email or desktop notifications.",
	"do_not_disturb_disable": "Disable \"Do not disturb\"",
	"do_not_disturb_disabled": "<strong>Do not disturb has been disabled.</strong><br><br>You will now receive email or desktop notifications again.",
	"do_not_disturb_enable": "Enable \"Do not disturb\"",
	"do_not_disturb_enabled": "<strong>Do not disturb has been enabled.</strong><br><br>You will not receive any email or desktop notifications when \"Do not disturb\" is enabled.",
	"do_not_disturb_user_enabled": "Do not disturb is enabled",
	"do_not_include_any_replies": "Do not include any previous replies",
	"do_not_reply_email_text": "Please do not reply to this email, as this is just a notification message",
	"do_not_update_setting": "Don't update settings",
	"do_you_want_to_continue": "Do you still want to continue?",
	"doc_search": "Doc search",
	"doc_sub_directory_desc": "This site is also available under the above subdirectory. Go to this URL directly to view your doc site or point your web server configuration to this URL and use it as a subdirectory on your website.",
	"doc_view_unpuplished_page": "You are viewing an unpublished page",
	"docs": "Docs",
	"docs_add_category_desc": "Create categories to assign them to your announcements, e.g., \"New feature\" or \"Enhancements\". Visitors will be able to filter your entries by these categories.",
	"docs_announcement_empty_desc_1": "The announcement section is a dedicated area in your Doc site to make announcements for your product or services. Think of it, as a news site.",
	"docs_announcement_empty_desc_2": "Each page represents a news entry where you can highlight what is new. You can show your updates on the Docs homepage, in the main navigation, and/or in the Chat widget.",
	"docs_announcement_empty_desc_3": "Please visit the <a href=\"%s\" target=\"_blank\">this update page</a> to see an example of how this could be used.",
	"docs_announcement_empty_header": "Announcement introduction",
	"docs_announcement_image_per_page": "Default page image",
	"docs_announcement_image_per_page_desc": "Set the default image, if you don't select an image for your announcement page.",
	"docs_create_another_site": "Add docs site",
	"docs_create_site": "Create docs site",
	"docs_delete_announcement_desc": "Please click on the button below to confirm that you want to remove this category",
	"docs_delete_announcement_header": "Delete category",
	"docs_delete_announcement_note": "This will only remove the category and not any related pages",
	"docs_delete_navigation_desc": "Please click on the button below to confirm that you want to remove the navigation item",
	"docs_delete_navigation_header": "Delete navigation item",
	"docs_delete_navigation_note": "This will only remove the navigation item and not any related sections",
	"docs_delete_section_desc": "Please click on the button below to confirm that you want to remove this category",
	"docs_delete_section_header": "Delete this category",
	"docs_delete_section_note": "This will only remove the category and not any related pages",
	"docs_delete_site_button": "Yes, delete this docs site",
	"docs_delete_site_desc": "Please click on the button below to confirm that you want to remove this docs site",
	"docs_delete_site_header": "Delete this docs site",
	"docs_delete_site_note": "Please know that all pages will be permanently removed and your site will not be available anymore. This action is irreversible!",
	"docs_desc_desc": "An optional description for your site.",
	"docs_editor_search": "Search for a docs article",
	"docs_first_time_desc": "A docs site contains your categories, pages, design, and more. Depending on your plan you can create several public or private docs sites.",
	"docs_first_time_header": "Create your first docs site",
	"docs_form_desc": "The sub-header",
	"docs_form_header": "The header",
	"docs_name": "Name of this site",
	"docs_name_desc": "This is usually the name of the main category or your product name. This also makes up part of the URL, i.e., you can only use letters and numbers and \"-\" or \"_\" and no spaces or any other characters. Don't worry, you can always change it later.",
	"docs_name_empty": "Please give your docs site a name",
	"docs_name_invalid": "Please enter a valid name for your docs site",
	"docs_navigation_description_desc": "This is an optional description that shows below the header on the dedicated navigation page",
	"docs_navigation_enabled": "Show navigation",
	"docs_navigation_enabled_desc": "If you have sections, the sections will also not show if this is disabled.",
	"docs_no_site_%s": "It looks like you have no Docs Site configured (yet). With Docs you can create public or private Knowledge-Base sites. Create your <a href='%s'>first Docs site now</a>",
	"docs_offline": "The Docs site is now offline",
	"docs_online": "The Docs site is now published and available online",
	"docs_pages_fail": "Please enter a name for this page",
	"docs_pages_in_cat": "Pages in",
	"docs_pages_new_btn": "Add a new page",
	"docs_pages_new_name": "What do you want to call this page?",
	"docs_private": "Is this a private site for internal use only?",
	"docs_private_false": "The Docs site is publicly accessible (again)",
	"docs_private_true": "The Docs site is now private and can only be accessed by team members",
	"docs_section_description_homepage": "This is an optional description that shows on your Homepage along with the section",
	"docs_section_description_homepage_header": "Description on Homepage",
	"docs_section_description_page": "This is an optional description that shows on top of the section page, i.e., before all pages of this section are shown",
	"docs_section_description_page_header": "Description on section page",
	"docs_section_empty_desc_1": "Sections are used to divide your content into several dedicated sections within your Docs site. You can create sections, for instance, for \"Billing\", another section for \"Guides\", and so forth.",
	"docs_section_empty_desc_2": "You can also add sections to a \"Navigation\" to create a multi-product knowledge page.",
	"docs_section_empty_desc_3": "Please visit the <a href=\"%s\" target=\"_blank\">Doc site help center</a> to read further.",
	"docs_section_empty_header": "Section introduction",
	"docs_section_icon_desc_%s_%s": "You can also use an icon for your section. Chose the icon you like from the <a href='%s' target='_blank'>%s</a> and insert the icon name in the field above. This is the part AFTER bi-",
	"docs_section_image": "Image for this section",
	"docs_section_image_desc": "Upload an image that it then shown along with the title and description",
	"docs_select": "Select Knowledge-Base",
	"docs_show_form": "Show contact form",
	"docs_show_form_desc": "Enabling this will show a \"Contact us\" link in the main navigation.",
	"docs_site": "Docs site",
	"docs_site_description_internal_optional": "This is an optional internal description for your site",
	"docs_site_design_branding": "Branding",
	"docs_site_design_header": "Customize your site",
	"docs_site_domain_desc": "Your docs sites are all hosted on our high-availability platform. You can either use our exclusive subdomain or configure your webserver to show the content from a directory (this is usually better for SEO).",
	"docs_site_insight": "Knowledge-Base Insight",
	"docs_site_logo_desc": "Your logo will show on top of every site. We recommend a size with a maximum of 30px height.",
	"docs_site_missing_values": "Looks like the name or slug is missing",
	"docs_site_navigation_add": "Add navigation item",
	"docs_site_navigation_desc": "By default, your sections will show within one area. However, if you like to create multiple areas and add menu items to the top navigation you can do so with creating a navigation here. You can add sections to a navigation item or create custom navigation items.",
	"docs_site_navigation_sections": "Add sections",
	"docs_site_navigation_sections_desc": "Add your sections to this navigation item and it will be added to the top navigation and used as a header for your sections.",
	"docs_site_navigation_url": "Custom URL",
	"docs_site_navigation_url_desc": "If you add a custom URL here, the navigation item will be added to the top navigation but it will not hold any sections.",
	"docs_site_sso_desc": "If you want your users to be automatically authenticated within your docs site use the SSO login here",
	"docs_site_sub_domain_desc": "You can share the below address with your customers, or you can configure your custom domain for your docs site.",
	"docs_site_wording_desc": "You will find most wordings already within each section. However, here you have the option to re-word or translate some common phrases that your Docs site is using.",
	"docs_text_form_link": "Link text",
	"docs_text_form_link_desc": "Customize the link text to the contact form.",
	"docs_wording_back_to": "Back to",
	"docs_wording_contact_us": "Contact us link text",
	"docs_wording_filter": "Header above filter option",
	"docs_wording_home": "Home link text",
	"docs_wording_last_updated": "Last updated on",
	"docs_wording_need_help": "Need help?",
	"docs_wording_pages": "Header above page summary",
	"docs_wording_rating": "Ask for rating",
	"docs_wording_rating_feedback": "Feedback for rating",
	"docs_wording_related_pages": "Header above related pages",
	"docs_wording_search": "Header of search results",
	"docs_wording_search_placeholder": "Text placeholder for search input field",
	"docs_wording_show_all": "Show all link text",
	"docs_wording_show_more": "Button text to show more updates",
	"docs_wording_subscribe_header": "Header for subscribe panel",
	"docs_wording_subscribe_rss": "Subscribe to RSS feed",
	"docs_wording_subscribe_rss_atom": "Subscribe to ATOM feed",
	"docs_wording_subscribe_rss_json": "Subscribe to JSON feed",
	"docs_wording_ww_chat": "Chat link text",
	"docs_wording_ww_docs": "Docs link text",
	"docs_wording_ww_form": "Form link text",
	"docs_wording_ww_search_desc": "Short description for search box",
	"docs_wording_ww_search_header": "Header for search box",
	"documents": "Documents",
	"does_not_contain": "does not contain",
	"does_not_contain_any_of_these_labels": "The email conversation does NOT have any of the following labels",
	"does_not_contain_any_of_these_labels_customers": "The customer does NOT have any of the following labels",
	"does_not_contain_labels_option": "The conversation does NOT contain the labels",
	"does_not_contain_labels_option_customers": "The conversation is from a customer who does NOT have one of the following labels",
	"does_not_have_a_status_of_option": "The email conversation does NOT have a status of",
	"does_not_have_text_of": "Enter the text you want to match, to filter out the message from this workflow. Note: The whole text will be matched, e.g. if you enter \"Please reply\" then it will look for \"Please reply\" as a whole word and not for \"Please\" and \"reply\" as individual words. This also works for hidden comments within the email body!",
	"does_not_have_text_of_option": "The message does NOT contain specified text in the subject or message body",
	"does_not_match": "does not match",
	"domain": "Domain",
	"domains": "Associated Domains",
	"done": "Done",
	"download_all_attachments": "Download all",
	"download_all_attachments_link": "Click to download attachments now",
	"download_done": "The file is now in your Download folder",
	"download_large_thumbnail": "Download large thumbnail",
	"download_link": "Click here to download the file",
	"download_original": "Original",
	"download_thumbnail": "Download thumbnail",
	"download_upper": "Download",
	"download_vcard": "Download vCard",
	"download_your_files": "Download your files",
	"draft_approval_header": "Approval requested",
	"draft_desc_%s": "You started to write a reply on %s. ",
	"draft_desc_approval_requested_%s_%s": "You saved this draft on %s and asked for approval from the following users: %s",
	"draft_desc_approval_requested_2": "Until the request is approved, you can edit the draft and save it again.",
	"draft_desc_approval_user_%s_%s_%s": "%s saved this draft on %s and asked for approval from the following users: %s",
	"draft_desc_approval_user_2": "As a person who is selected to approve this message, please use the \"Approve\" or \"Reject\" buttons below. If you approve, the message will get sent immediately.",
	"draft_desc_autoload_%s_%s_%s": "You started to write a reply on %s. We loaded the draft into the editor below already. In case you don't need it anymore you can <a href='#' onclick='deleteDraftFromEditor(\"%s\", \"%s\")'>delete it now</a>.",
	"draft_desc_new_%s_%s_%s": "You started to write a new message on %s. We loaded the draft into the editor below already. In case you don't need it anymore you can <a href='#' onclick='deleteDraftFromEditorNew(\"%s\", \"%s\")'>delete it now</a>.",
	"draft_desc_question_%s": "Do you want to <strong><a href='#' onclick='loadDraftIntoEditor()' style='text-decoration:underline'>continue writing</a></strong> or <a href='#' onclick='deleteDraftFromEditor(\"%s\")' style='text-decoration:underline'>delete</a> it?",
	"draft_desc_user_%s_%s_%s_%s": "%s saved this draft on %s. We loaded the message into the editor below already. In case you don't need it anymore you can <a href='#' onclick='deleteDraftFromEditorNew(\"%s\", \"%s\")'>delete it now</a>.",
	"draft_header": "Draft",
	"draft_not_found": "Oops, we could not find your draft!",
	"draft_remove": "Your draft has been deleted",
	"draft_remove_selected_header": "Remove the selected draft(s)",
	"draft_remove_selected_text": "Are you sure that you want to remove the selected draft(s)?",
	"draft_saved": "Your draft has been saved",
	"draft_status_desc": "Below you will find your drafts and drafts that are shared with you",
	"drafts": "Drafts",
	"drafts_shared_confirmation_false": "Your draft is not shared with your team anymore",
	"drafts_shared_confirmation_true": "Your draft is now shared with your team",
	"drafts_shared_desc": "By default, drafts are not being shared with other team members. Enabling this option will change the default. Note: You can still change it when you compose a message.",
	"drafts_shared_header": "Share drafts with your team",
	"drafts_shared_header_none": "There are no shared drafts",
	"drafts_shared_header_status": "Shared drafts",
	"drafts_shared_message": "Share draft",
	"drafts_shared_notification": "Draft Notification",
	"drafts_shared_notification_false_%s": "%s unshared a draft",
	"drafts_shared_notification_true_%s": "%s shared a draft with you",
	"drip_new_desc": "Create a new email sequence to send a series of emails.",
	"drip_new_select_template": "Start by selecting and adapting one of our templates or by creating your own.",
	"drip_remove": "Remove sequence",
	"drip_remove_desc": "Please confirm that you want to remove this sequence",
	"drip_remove_user_desc": "When you remove the user all the history will be removed as well. However, you can always add the user to this or another campaign again.",
	"drip_remove_users_desc": "When you remove these users all their history will be removed as well. However, you can always add users to this or another campaign again.",
	"due": "Due",
	"due_date": "Due date",
	"duration": "Duration",
	"edit": "Edit",
	"edit_campaign": "Edit campaign",
	"edit_collection_name": "Edit collection name",
	"edit_draft": "Edit your draft",
	"edit_my_profile": "My account",
	"edit_name": "Edit name",
	"edit_profile": "Edit account",
	"edit_profile_security": "Sign-in & security",
	"edit_profile_security_desc_%s": "Please find security settings of this user below.<br><br>If you are looking to enable Two-Step authentication for yourself then head over to your profile to do so. Each user must enable Two-Step authentication themselves.",
	"edit_profile_security_disabled_%s": "This user does not use Two-Step authentication. Maybe now it's a good time to let them know about the benefits of adding an extra layer of security to their account?<br><br>If you are looking to enable Two-Step authentication for yourself then head over to your profile to do so. Each user must enable Two-Step authentication themselves.",
	"edit_profile_security_enabled": "Great. This user uses Two-Step authentication.",
	"edit_profile_security_recovery_key": "This is the users backup key. Give this key to the user if he has trouble signing into his account or he misplaced it.",
	"edit_sla_direction": "To edit any of the service level agreements listed simply click on its name",
	"edit_sla_link": "Edit SLAs",
	"edit_subject": "Edit Subject",
	"elasticsearch_indexing_error": "Elasticsearch indexing error",
	"elasticsearch_panel": "Elasticsearch status",
	"email": "Email",
	"email_address": "Email address",
	"email_addresses_errors_desc": "Enter the email address (or many separated with a comma) where the system should send error messages to. Leave this blank to NOT send emails. The system will still log errors in the error log for you to see. You should only add yourself here if you are a developer or want to be informed of any system errors.",
	"email_addresses_errors_header": "Error email address(es)",
	"email_already_in_use": "Email address is already in use",
	"email_already_in_use_by_mailbox": "The email address is already being used for a shared mailbox",
	"email_already_in_use_by_user": "The email address is already in use by another user",
	"email_attachments": "Email attachments",
	"email_automation": "Email Sequences",
	"email_automation_desc": "Automated email sequences are great to send emails on certain events, e.g., to follow-up automatically with email replies, the customer decides to abandon the shopping cart or when a customer signs up for your services. Tip: You can use the API, too.",
	"email_body": "Email Body",
	"email_campaigns": "Email Campaigns",
	"email_campaigns_desc": "Send email newsletters, manage your distribution lists, and more with our all-in-one marketing platform.",
	"email_chain_logs": "Email message logs",
	"email_commands": "Use Email Commands",
	"email_contains_notes": "This email contains notes",
	"email_count": "Email count",
	"email_delivery_logs": "Email delivery",
	"email_failed_validation_%s": "Email '%s' is not valid for use",
	"email_forgot_password_message_%s_%s_%s_%s": "<h1>Reset your password</h1><p>To reset the password for your %s account %s click on the link below:</p><p>%s</p><p>If clicking the link above doesn't work, please copy and paste the URL in a new browser window instead.</p><p>If you've received this mail in error, it's likely that another user entered your email address by mistake while trying to reset a password. If you didn't initiate the request, you don't need to take any further action and can safely disregard this email.</p><p>Thank you for using %s.</p>",
	"email_forgot_password_subject_%s": "%s Password Request",
	"email_header_email_commands": "Email commands are never sent to the customer",
	"email_header_reply_to": "Your reply will be sent to",
	"email_history": "Email History",
	"email_identities": "Email identities",
	"email_in_use_validation_error": "The email address is already in use by another mailbox or user.",
	"email_is_from_forwarder": "email is from the subscriber that forwarded the email",
	"email_is_from_original_sender": "email is from the original sender of the forwarded email",
	"email_is_not_a_real_email_address_%s": "Email '%s' is not an existing email address, and cannot be used",
	"email_is_not_a_valid_format_%s": "Email '%s' is not a valid format for an email address",
	"email_label_text": "Email: Label text",
	"email_limited_to_six_replies": "This email is limited to the last 6 replies.",
	"email_marketing": "Email Marketing",
	"email_marketing_subheading_1": "Create & send beautiful newsletters",
	"email_marketing_subheading_2": "Delight & inform your customers",
	"email_my_preferences": "My email preferences",
	"email_new_contacts": "Email from new contacts",
	"email_not_valid": "Email is not a valid email address",
	"email_parse_to_cc_desc_%s_%s": "Some email providers will put the source account into the CC when forwarding emails to your %s mailbox. For example, your email account is sales.uk@domain.com, but your email address in %s is sales@domain.com. If we see this, we honor the email headers and will put the sales.uk@domain.com address into the CC field.<br><br>If you disable this behavior below, the source account will not be put into the CC field anymore.",
	"email_parse_to_cc_header": "Email parse setting",
	"email_preferences": "Email Preferences",
	"email_sequences": "Email Sequences",
	"email_sequences_subheading_1": "Create automated email sequences",
	"email_sequences_subheading_2": "Follow-up with everyone on auto-pilot",
	"email_settings": "Email settings",
	"email_settings_change_desc_%s": "Note: If you change the incoming or outgoing email setting you need to restart the %s application!",
	"email_should_not_be_a_personal_email_address": "Email should not be a personal email address",
	"email_shown_from_sending_emails": "To use other email addresses, please make sure to add them to the allowed sending emails. You can add emails for sending within the email marketing options.",
	"email_status_desc": "Enter the status email address. This is the FROM email address that is being used to send, e.g. error emails or status messages from.",
	"email_status_header": "Status email address",
	"email_subject": "Email Subject",
	"email_subject_assigned": "assigned this conversation to you",
	"email_successfully_sent": "Your message was successfully sent",
	"email_successfully_sent_desc": "You will find your sent message in the \"Inbox\" or if assigned to yourself in \"Mine\", resp. \"Assigned\"",
	"email_template": "Email Template",
	"email_template_desc": "Choose the email template to use. This is the template used for replies to customers.",
	"email_template_extended": "Extended Template",
	"email_template_extended_desc": "The Extended Template shows your reply on top and contains the last seven replies in the conversation. Additionally, it contains HTML formats and each replies has its own box.",
	"email_template_logs": "Email template logs",
	"email_template_simple": "Simple Template",
	"email_template_simple_desc": "The Simple Template shows your reply on top and quotes the email from the customer.",
	"email_templates_variables": "Use variables to dynamically populate values in your email template.",
	"emailchain_not_found": "We could not find a conversation here",
	"emails": "Emails",
	"emails_not_assigned": "Emails not assigned to any user",
	"emails_trash_notice": "Messages are automatically removed after 30 days",
	"empty_emails_of_folder_permanently": "Are you sure that you want to delete all messages in this folder now?",
	"empty_folder_global": "Do you feel like this space is a bit too dull and lonesome? I hear you! Let's spice things up by getting some emails in here. Trust me, you won't regret it!",
	"empty_folder_notification": "We kicked off a background process to remove all messages here. You can safely navigate away from this page.",
	"empty_inbox_desc": "You've successfully managed to get a handle on all your emails. You should celebrate this achievement. It's not every day that you accomplish something like this.",
	"empty_mailbox_configure_forwarding_%s_%s_%s_%s_%s_%s_%s_%s": "We noticed that you haven't sent any messages to your shiny new mailbox<br><br>To send messages to your %s mailbox, send a message to <a href='mailto:%s' target='_blank' class='dont-break-out'>%s</a> now<br><br>When you are ready to get serious, simply forward emails from <a href='mailto:%s' target='_blank' class='dont-break-out'>%s</a> to your %s mailbox address at <a href='mailto:%s' target='_blank' class='dont-break-out'>%s</a> and let %s manage the rest</p>",
	"empty_mailbox_header": "No conversations have arrived yet",
	"empty_trash": "Empty trash",
	"empty_trash_desc": "Are you sure that you want to empty this trash now?",
	"empty_trash_notification": "We are emptying the trash with a background process now. You can safely navigate away from this page.",
	"enable": "Enable",
	"enable_account_controls": "Enable account controls",
	"enable_account_controls_desc": "Account controls will be enabled for all users who signed up with the email domain",
	"enable_custom_server": "Enable my SMTP server",
	"enable_custom_server_desc": "Before you enable your SMTP server, please make sure that the test email went out successfully. Once enabled, all outgoing emails will be using your SMTP server. We do not take any responsibility for unsent messages or delivery issues",
	"enable_default_server_%s": "Send emails with the %s SMTP servers",
	"enable_for_mailbox": "Enable for mailbox",
	"enable_form": "Enable form",
	"enable_link": "Enable link",
	"enable_shareable_link": "Enable shareable link",
	"enable_shortcuts": "Enable shortcuts",
	"enable_shortcuts_false": "No, I don't use shortcuts",
	"enable_shortcuts_true": "Yes, enable shortcuts (default)",
	"enabled": "Enabled",
	"end_date": "End date",
	"end_of_results": "End of Search Results",
	"ended_processing_at": "Ended processing at",
	"enter_absolute_path": "Please enter an absolute path, i.e. /opt/... or C:\\apps\\",
	"enter_all_required": "Please enter all required values",
	"enter_email_address": "Enter email address(es)",
	"enter_id_of_email_chain": "View the indexed version of an email conversation by id",
	"enter_password": "Enter password",
	"enter_search_term": "Search Term",
	"entire_email_history_company": "Entire email history with this company",
	"entire_email_history_contact": "Entire email history with this contact",
	"error_date": "error date",
	"error_logs": "error logs",
	"error_message": "message",
	"error_on_sending": "Errors on sending",
	"error_option": "Error",
	"error_resetting_password_%s_%s": "There was an error resetting your password.<br>Please contact <a href='mailto:%s?subject=Reset Password Request' target='_blank'>%s</a> to request a password reset.",
	"error_retrieving_user_info": "error retrieving user info",
	"error_saving_user": "there was an error saving the user",
	"error_type": "Type",
	"errored_while_processing": "Processing Errors",
	"exactly_matches": "exactly matches",
	"excerpt": "Excerpt",
	"execution_label_folder_now": "Move conversation now",
	"execution_label_folder_revert_seconds_%s_%s": "You have %s seconds to cancel the move<br><a href=\"#\" onclick=\"%s\" style=\"text-decoration:underline;\">Cancel now</a>",
	"execution_revert_seconds_%s_%s": "You have %s seconds to cancel the assignment<br><a href=\"#\" onclick=\"%s\" style=\"text-decoration:underline;\">Cancel now</a>",
	"existing_entries": "Existing entries",
	"existing_keywords": "Existing keywords",
	"existing_links": "Existing links",
	"expand_all": "Expand all",
	"expected_count_to_be": "expected count to be",
	"expire_link": "Expire link",
	"explore_extensions_link": "Explore extensions",
	"export": "export",
	"export_eml_download_ready_header": "EML download",
	"export_eml_download_ready_text": "Download your messages in the EML format",
	"export_eml_header": "Export all messages to EML",
	"export_eml_tooltip": "Export all messages to EML",
	"export_eml_tooltip_one": "Export this message to EML",
	"export_log": "export log",
	"export_log_as_chosen_format": "export log as chosen format",
	"export_report": "Export report",
	"export_users": "Export Users",
	"extensions": "Extensions",
	"fa_icon_desc": "Set a custom icon here. Chose the icon you like from the <a href='https://fontawesome.com/search' target='_blank'>FontAwesome library</a> and insert the icon name in the field above. This is the part AFTER fa-",
	"fantastic_news": "Fantastic news!",
	"fast_transfer_desc": "Your Razuna subscription includes Fast Transfer, which enables up to 400% faster upload performance. Choose the upload region closest to your location below for the best speed.",
	"fast_transfer_header": "Fast Transfer",
	"favicon": "Your favicon",
	"favicon_desc": "A favicon is the little image you see in the browser tab. This file should not be larger than 50x50 pixels.",
	"favorite_add": "Add to favorites",
	"favorite_remove": "Remove from favorites",
	"favorites_add_done": "The selected files have been added to your favorites",
	"favorites_removed_done": "The selected file(s) have been removed from your favorites",
	"feedback_card_form_desc": "This form provides an option for customers to vote on how important the roadmap item is for them. Customize the importance option, color, and optionally let them add a comment. Only signed in users will be able to do this.",
	"feedback_docs_show": "Show feedback in docs",
	"feedback_filetypes_updated": "FileTypes have been successfully updated",
	"feedback_form": "Feedback form",
	"feedback_form_desc": "Enable a feedback form for your roadmap here. Customize the form wording below. Form submissions are private.",
	"feedback_header": "Feedback",
	"feedback_host_forgot_name": "Please enter at least the name for your host!",
	"feedback_patch_apply": "The selected files are being updated in the background with your values",
	"feedback_port_must_be_a_number": "SMTP port must be a number!",
	"feedback_show_kb_desc": "When enabled, an additional feedback option will show within your Docs site in the top navigation, and also on a dedicated page.",
	"feedback_success_save": "Record has been saved successfully!",
	"feedback_success_save_with_link_%s": "Record has been saved successfully! <a href='%s'>Go back</a>",
	"feedback_system_settings_updated": "System settings have been successfully updated",
	"feedback_tooltip": "Found an issue? Please report it here",
	"feedback_trusted_error": "Error updating the trusted servers",
	"feedback_trusted_success": "Trusted servers updated",
	"feedback_user_forgot_email": "Please enter an email address!",
	"field_always_shown": "This field is always shown",
	"file": "File",
	"file_added_to_comments": "File added to comments",
	"file_name": "File name",
	"file_restore_from_trash_to_update": "Restore this file from the trash to edit",
	"file_type_cannot_be_shared": "This file type cannot be shared",
	"file_types": "File types",
	"file_types_extensions": "Extension",
	"file_types_mime_content": "Mime Content",
	"file_types_sub_mime_content": "Mime Sub-Content",
	"file_types_type": "File type",
	"file_upload_max_files_%s": "Your plan includes adding %s files in one upload session.",
	"file_usage": "File usage",
	"files": "Files",
	"files_already_exists": "File already exists",
	"files_already_exists_notification_%s": "The file \"%s\" already exists in this workspace.",
	"files_custom_logo": "Upload your logo here and it will be shown in your Workspace, shareable links and folders. The optimal size is 110x25 pixels.",
	"files_custom_logo_workspace": "By default, the logo of your default Workspace is shown on all your Workspaces. To show a specific Workspace logo, upload it here. The optimal size is 130x30 pixels. Please note, that the maximum height is 30 pixels.",
	"files_empty_folder": "This place feels very lonely",
	"files_empty_folder_subline": "Drag & drop your files here to get going",
	"files_empty_trash": "Data storage uses a lot of server power and pollutes the planet. Thank you for taking your trash out responsibly.",
	"files_favorites_empty": "Love your files",
	"files_favorites_empty_desc": "All your favorite files are here. Well, once you add some.",
	"files_found": "files found",
	"files_here_%s": "%s files here",
	"files_link_phrase": "Looking for a specific file?",
	"files_members_desc": "Easily add new members to your team by entering their email addresses below.\nOnce invited, they'll receive an email with a link to join. You can add multiple users at once by separating each email address with a comma.",
	"files_moved_successfully": "The files have been moved successfully",
	"files_moved_to_all_files": "Files will be moved into the \"All Files\" folder",
	"files_moved_to_trash": "The selected files have been moved to the trash",
	"files_no_tags": "You haven't added any tags. Use tags to provide more meaning to your files and make them further sortable.",
	"files_notification_desc_%s_%s": "We show a maximum of %s notification at once and recycle automatically after %s days.",
	"files_remove_team_desc": "Are you sure that you want to remove this team?",
	"files_remove_team_note": "The team will be removed from all users. All users will lose access to any workspace or folder the team has access to.",
	"files_remove_user_desc": "Are you sure that you want to remove this user?",
	"files_remove_user_note": "The member will be removed from all Workspaces and Teams. However, the member will continue to have access to their personal Razuna account.",
	"files_restore_done": "We are restoring the selected files to the All Files folder",
	"files_search_index_desc": "Below are the total number of files and the total number of indexed files for this workspace. If there is a significant difference, you may trigger a re-index.",
	"files_selected": "files selected",
	"files_subheading_1": "All your files in one place",
	"files_tags_desc": "Create Tags here which you can use to tag your files. You create for each of your Workspaces a different set of tags.",
	"files_trash_desc": "Files in the trash will automatically be removed after 30 days",
	"files_trash_desc_%s": "Files in the trash will automatically be removed after %s days",
	"files_usage_desc": "Please note that the usage is calculated once a day.",
	"files_users_tmp_header": "Users invited, but still need to register",
	"filter_by": "Filter by",
	"filter_by_action_type": "Filter results by action type",
	"filter_by_assignee": "Filter by assignee",
	"filter_by_label": "Filter by label",
	"filter_by_last_reply_user": "Filter by the user who replied last",
	"filter_by_mailbox": "Filter by mailbox",
	"filter_by_mailbox_%s": "Filter %s by mailbox",
	"filter_by_new": "New conversations",
	"filter_by_status": "Filter by status",
	"filter_by_tags": "Filter by tags",
	"filter_by_type": "Filter by type",
	"filter_by_user": "Filter by user",
	"filter_log": "Filter log",
	"filter_logs": "Show with selected status",
	"filter_nothing_found_%s": "<strong>Nothing found!</strong> There are no conversations that are assigned to %s. Please select another user from the drop-down menu above",
	"filter_results": "Filter results",
	"filter_table_heading": "Filter",
	"find_everything": "Find everything. Anywhere.",
	"find_indexed_email_chain": "Find Indexed Email",
	"find_similar_images": "Find similar images",
	"first": "First",
	"first_name": "First name",
	"first_name_label_text": "First name: Label text",
	"fix_sla_button": "SLA maintenance",
	"folder": "Folder",
	"folder_archive": "Archived",
	"folder_archived": "Archived",
	"folder_assigned": "Assigned",
	"folder_closed": "Closed",
	"folder_collisions": "Collisions",
	"folder_contributor": "Folder contributor",
	"folder_drafts": "Drafts",
	"folder_inbox": "Inbox",
	"folder_Inbox": "Folder Inbox",
	"folder_inbox_plain": "Inbox",
	"folder_mine": "Assigned to me",
	"folder_mine_groups": "Assigned (mine & group)",
	"folder_pending": "Pending",
	"folder_reminders": "Reminders",
	"folder_sent": "Sent Mail",
	"folder_settings": "Folder settings",
	"folder_shared_with_you_%s": "The %s folder is now shared with you.",
	"folder_spam": "Spam",
	"folder_thumbnail": "Folder thumbnail",
	"folder_thumbnail_desc": "Upload a custom thumbnail to make this folder yours. We recommend a square image with a maximum size of 120x120 px.",
	"folder_trash": "Trash",
	"folder_unshared_with_you_%s": "The %s folder is no longer shared with you.",
	"follow_box": "Follow box",
	"following": "Following",
	"for": "for",
	"for_me": "For me",
	"for_new": "For new or forwarding",
	"for_replies": "For replies",
	"for_this_group": "For this group",
	"for_you": "For you",
	"forgot_password_reset_desc": "Your password has been successfully reset. Keep an eye on your inbox for an email containing your new password. Enjoy!",
	"form_active": "Active forms",
	"form_add": "Create this form",
	"form_addons_failure_save": "Configuration settings could not be updated.",
	"form_addons_success_save": "Configuration settings have been successfully updated.",
	"form_builder": "Forms",
	"form_empty_values": "Hey. It looks like not all form fields have a value!",
	"form_inactive": "Inactive forms",
	"form_mailbox_email_help": "Decide if the FROM address should appear as the mailbox name or the sender's name",
	"form_mailbox_email_placeholder": "Please enter the email address of this mailbox",
	"form_mailbox_from_mailbox": "Mailbox's name",
	"form_mailbox_from_user": "User's name",
	"form_mailbox_name_placeholder": "Please enter a name for this mailbox",
	"form_new": "Add a new form",
	"form_remove": "Remove this form",
	"form_remove_desc": "Please confirm that you really want to remove this form?",
	"forms": "Forms",
	"forms_desc": "Create a form, e.g., a subscriber form, and the contact automatically gets added to your distribution list or drip email campaign. You can also add labels to the contact and have the form sent to your mailbox. Forms can be used with landing pages, as standalone page, or you can embed the form into your pages, HTML, Wordpress, etc.",
	"forms_empty": "Looks like you haven't created any forms",
	"forward": "Forward",
	"forward_all_attachments": "Upon forwarding send all attachments",
	"forward_attachment.desc": "All the attachments that are being included with the forward. Remove the ones you don't want to include.",
	"forward_conversation": "The following conversation will be forwarded",
	"forward_conversation_desc": "No internal notes or status messages will be included in the outgoing message. Click on the \"Remove\" icon to prevent a reply to be forwarded",
	"forward_conversation_header": "Forward the conversation",
	"forward_email": "Forward email",
	"forward_email_desc_%s": "Once ready, simply forward emails from <b>%s</b> to email address ",
	"forward_email_desc_2_%s": "in order to fully take advantage of what %s can do for your team.",
	"forward_email_header": "Forward Emails",
	"forward_include": "Include it again",
	"forward_messages_desc": "All the messages that are being included with the forward. Remove the ones you don't want to include.",
	"forward_not_include": "This message will not be included in the forward.",
	"forward_originated": "This message originated from a forward",
	"forward_to_addresses": "Forward a copy of the message to one or more email addresses",
	"forwarded_to": "forwarded this conversation to",
	"forwarded_to_user_%s": "You forwarded this conversation to %s",
	"forwarding_desc": "Set the default behavior when forwarding emails. This setting is inherited by all mailboxes. However, each mailbox can be configured to override these settings.",
	"forwarding_help": "Our <a href='https://helpmonks.com/kb/support' target='_blank'>how-to guides</a> show how to forward emails for different email services.",
	"forwarding_mailbox_desc_%s_%s": "Set the behavior when a %s user forwards a conversation within %s. This setting is inherited from the <a href='/settings/general'>account wide configuration</a>. Though, you can override it here.",
	"forwarding_mailbox_desc_admin_%s_%s": "Set the behavior when a %s user forwards a conversation within %s.",
	"forwarding_mailbox_header_%s": "Attachments when forwarding within %s",
	"found": "found",
	"friday": "Friday",
	"from": "From",
	"from_address": "Default from address",
	"from_date_%s": "from date %s",
	"from_lowercase": "from",
	"full_name": "Full name",
	"funnels": "Funnels",
	"general_error": "An error occurred while saving without any further information. Please try it again and if the issue persist please contact us.",
	"general_permissions": "General Permissions",
	"general_settings": "General settings",
	"generate_key": "Generate a key",
	"generate_password": "Generate password",
	"get_desktop_browser_desc": "Use our app or browser extension for easy collecting.",
	"get_help": "Get help",
	"get_indexed_email": "View Indexed Email",
	"get_new_plugins": "Get New Plugins",
	"get_shareable_link": "Get a shareable link",
	"get_workflow_results": "Get Workflow Results",
	"global_apply_settings_desc": "All settings on this page are for the system-wide operation of your account. Any changes will be applied immediately.",
	"global_mail_settings": "Global mail settings",
	"global_mail_settings_desc": "All settings on this page are for the system-wide operation of your account. Any changes will be applied immediately.",
	"global_notification_header": "Global notification",
	"global_settings": "Global Settings",
	"global_settings_link": "Global settings",
	"go_to": "Go to",
	"go_to_admin": "Go to the Administration",
	"go_to_admin_link": "Click here for system wide settings (labels, users, plugins, etc.)",
	"go_to_campaigns": "Go to campaigns",
	"go_to_chats": "Go to chats",
	"go_to_company": "Go to company",
	"go_to_conversation": "Go to conversation",
	"go_to_history": "Go to history",
	"go_to_inbox_%s": "Go to Inbox of %s",
	"go_to_my_account": "Go to my profile",
	"go_to_new_mailbox": "Go to my new mailbox",
	"go_to_todos": "Go to to-dos",
	"go_to_top": "Go to the top",
	"go_to_user": "Go to user record",
	"good_job": "Good job",
	"goto_feature_notification_%s": "Next time, try the new Jump feature to search<br><br>Just hit %s+/ and search<br><br>This is also a great way to find everything in your account and navigate through the system",
	"goto_loading": "Taking you there",
	"goto_placeholder": "Go to a conversation, customer, company, or find a message...",
	"great_check_options": "Sounds great. Let me check my options.",
	"group_by_assignee": "Group by assignee",
	"group_by_from": "Group by sender",
	"group_by_subject": "Group by subject",
	"group_drafts": "Drafts of this group",
	"group_logs": "group logs",
	"group_settings": "Group Settings",
	"grouping_show_more": "Show more conversations",
	"groups_sorted_by_name": "Groups are sorted by name",
	"guess_what": "Hey, guess what?",
	"has_a_status_of": "has a status of",
	"has_a_status_of_option": "The email conversation has a status of",
	"has_a_subject_of": "The email has a subject which",
	"has_a_subject_of_option": "email's subject",
	"has_a_text_of": "Enter the text you want to match for the body or the subject. Note: The whole text will be matched, e.g. if you enter \"Please reply\" then it will look for \"Please reply\" as a whole word and not for \"Please\" and \"reply\" as individual words. This also works for hidden comments within the email body!",
	"has_a_text_of_option": "The message contains or matches specified text in the subject or message body",
	"has_access": "Has access",
	"has_attachment": "The email has an attachment",
	"has_attachment_option": "The email conversation has an attachment or not",
	"has_no_attachment": "The email does not have an attachment",
	"has_reminder_set_for": "has a reminder set for",
	"has_sla_of": "has_sla_of",
	"has_sla_of_option": "The conversation is associated with a Service Level Agreement",
	"have_unsubscribed": "Have unsubscribed",
	"header": "Header",
	"header_background_color": "Top navigation background color",
	"header_favorites": "Favorites",
	"header_file_type_add": "Add a new file type",
	"header_file_type_existing": "Existing file type",
	"header_filters": "Filters",
	"header_folders": "Folders",
	"header_forgot_password": "Can't sign in?",
	"header_forward": "Forwarding behavior",
	"header_forward_desc_%s": "By default any email forwarded by a subscriber to the mailbox is parsed to retrieve the original sender, who then becomes the customer for the message, so any reply to the forwarded email will be sent to the customer. If you don't want to parse forwarded messages in this manner you can disable this option below, and have the message come from the subscriber who forwarded it.<br><br>This only applies to messages that a %s user *manually* forwards to this mailbox, e.g. you receive a message in your email client that was intended for the mailbox. This setting decides from WHOM the forward appears from. From your or from the original sender. Most of the time, this should be set to be from the original sender.",
	"header_labels": "Labels",
	"header_layout_style": "Layout style",
	"header_links_color": "Top navigation menu color",
	"header_plugin_deregister_button": "Yes, Uninstall Plugin",
	"header_plugin_deregister_header": "Uninstall Plugin",
	"header_plugin_deregister_note": "Doing so will uninstall this plugin and all related records will be removed. This action is irreversible!",
	"header_plugin_deregister_text": "Do you really want to uninstall this plugin?",
	"header_projects": "Projects",
	"header_reset_password": "Reset Password",
	"header_security_add_new_user": "Add new user",
	"header_security_basic_auth_disable": "Disable Basic Authentication",
	"header_security_basic_auth_enable": "Enable Basic Authentication",
	"header_security_basic_auth_enable_desc": "Please make sure to add users BEFORE you enable the Basic Authentication as it will take effect immediately! This setting is only saved, by clicking the \"Update\" button below.",
	"header_security_basic_auth_enable_header": "Enable Basic Authentication",
	"header_security_existing_users": "Existing users",
	"header_settings_backup_desc": "Execute backup below...",
	"header_settings_custom_fields_desc": "Custom fields description",
	"header_settings_filetypes_desc": "Here you are able to add or remove certain file types according to their extensions. The system will look into this table to organize what file belongs to which category. Changes here will affect all tenants. If you are adding an extension make sure that ImageMagick or FFMpeg will be able to handle the extension or else you will get errors! Only edit this table if you know what you are doing!",
	"header_settings_general_desc": "Here you will find system wide settings. Host specific settings can be found in the Administration of the host itself. Changes will take effect immediately!",
	"header_settings_groups_desc": "Find all groups below",
	"header_settings_labels_desc": "Create and edit labels",
	"header_settings_logs_desc": "Inspect logs",
	"header_settings_servaices_desc": "Integrate with other services",
	"header_settings_system_information_desc": "Here are some useful system informations",
	"header_settings_templates_desc": "Add templates below",
	"header_settings_tools_dcraw": "Dcraw",
	"header_settings_tools_dcraw_desc": "DCRaw is being used by the system to convert RAW images to different formats.",
	"header_settings_tools_desc_%s": "%s uses different third party libraries for creating thumbnails, converting images, videos and audio files to different formats, to extract metadata and more. You need to install these third party libraries first and then enter the absolute path to each library below.",
	"header_settings_tools_exiftool": "Exiftool",
	"header_settings_tools_exiftool_desc": "Exiftool is being used by the system to read and write metadata.",
	"header_settings_tools_images": "Image Renditions",
	"header_settings_tools_images_desc": "Enter the absolute path to the library used for image renditioning. By default it uses ImageMagick (recommended), but if you want to use another one, you have the option to enter it here. Note: If you do use another tool you also have to use other commands in the advanced options!",
	"header_settings_tools_mp4box": "Mp4box",
	"header_settings_tools_mp4box_desc": "MP4Box is being used by the system to encode mp4 video for streaming properly.",
	"header_settings_tools_videos": "Video Renditions",
	"header_settings_tools_videos_desc": "Enter the absolute path to the library used for video renditioning. By default it uses FFmpeg (recommended), but if you want to use another one, you have the option to enter it here. Note: If you do use another tool you also have to use other commands in the advanced options!",
	"header_settings_trusted_desc_%s": "You need to enter the front end servers you trust below. These trusted servers are able to connect to the API server. You only need to enter a trusted server if you have a front end server on another network/server, i.e. 127.0.0.1 or localhost is allowed by default. You can enter either a IP address or a DNS name.<br><br>Note: This is ONLY used for API calls to the host API! Please read the <a href='http://developer.%s.com' target='_blank'>API documentation</a>.",
	"header_settings_trusted_header": "Trusted Servers",
	"header_settings_users_desc": "Find all users below",
	"header_trusted_existing": "Existing trusted servers",
	"heading": "Heading",
	"heading_background_color": "Heading background color",
	"heading_desc": "An optional heading that shows on top of the page.",
	"heads_up": "Heads up",
	"heads_up_selected_many_%s": "You've selected %s records and processing this amount might take a bit longer than usual to reflect on the mails and the counts.",
	"hey_there": "Hey there!",
	"hidden_action": "Hide statuses",
	"hidden_message": "Hide messages",
	"hidden_note": "Hide notes",
	"hidden_password": "password hidden",
	"hide": "hide",
	"hide_customer": "Hide contact",
	"hide_customer_desc": "A hidden contact will not be selectable for sending emails and will not show in the list, also",
	"hide_headers": "Hide headers",
	"hide_user": "Hide user",
	"him_herself": "themself",
	"hint_use": "Hint: Use",
	"history": "History",
	"hit_enter_to_add": "Hit enter to add",
	"homepage": "Homepage",
	"homepage_description": "Homepage description",
	"homepage_description_desc": "This is an optional description that will show below your header.",
	"homepage_header": "Homepage header",
	"homepage_header_desc": "This is the header for your homepage and shows above the search bar.",
	"homepage_logo_link": "Homepage URL",
	"homepage_logo_link_desc": "By default, a click on your logo will go to your docs homepage. Provide a different URL if you want to change that.",
	"homepage_show_type": "What to show on homepage",
	"homepage_show_type_both": "Show my sections and recent pages",
	"homepage_show_type_desc": "Select if you want to show your sections, the latest pages, or both on your homepage.",
	"homepage_show_type_pages": "Show the most recent pages",
	"homepage_show_type_sections": "Show my sections",
	"host": "host",
	"host_dns_name": "Subdomain",
	"host_dns_name_custom": "Custom Domain Name",
	"host_dns_name_custom_desc_%s": "Optional: Enter a custom domain name for this host. Please make sure to create a CNAME records in your DNS to the subdomain host, e.g. help.mydomain.com to subdomain.%s.",
	"host_dns_name_desc_%s": "Optional: If you want to serve this host under its subdomain then enter the subdomain (without .%s) here.",
	"host_name": "Hostname",
	"host_name_desc": "Enter the name of this host. This host can be accessed with yourdomain.com/hostname.",
	"host_no_stats_found": "There is no data in the selected time frame",
	"host_settings_general_desc": "The settings below take effect for all mailboxes in your organization.",
	"host_settings_slas_desc": "List of service level agreements",
	"host_settings_spam_desc": "Define here any email-address and/or domain that should be blocked, i.e., automatically be moved to the Spam folder. Any defined account-wide addresses will be appended to your list. Please note, that you can only enable a Block or an Allow list at the same time.",
	"host_settings_spam_desc2": "The lists below are appended to the mailbox’s lists when checking block settings. The type of filter, blocking or allow listing, is decided per mailbox and can be configured in the mailbox's settings. By default mailboxes use blocking.",
	"host_settings_spam_desc_mailbox": "The lists added in the administration spam settings are appended to the lists below.",
	"host_settings_spam_white_desc": "Any email NOT on the white list will not be able to send you any email. The same applies for sending an email from within your mailbox. Email addresses on the list below will automatically be added to the white list in the mailbox.",
	"host_settings_templates_desc": "Below you will find an email template that you can customize. This email template is being used for sending out a welcome email to users. You can send this email when you check the \"send welcome email\" box in the user section.",
	"host_upper": "Host",
	"host_url_%s": "Your %s url",
	"hour": "hour",
	"hours": "Hour(s)",
	"hours_minutes_seconds_%s_%s_%s": "%s hrs. %s min. %s sec.",
	"hours_plural": "Hours",
	"how_many_announcements": "How many announcements",
	"how_many_conversations_to_show": "How many conversation to show",
	"how_many_times_downloaded": "times downloaded",
	"how_many_times_viewed": "times viewed",
	"how_to_guides": "Visit the How-to Guides",
	"i_take_my_chances": "I take my chances",
	"i_understand": "I understand",
	"icon": "Icon",
	"id": "id",
	"images": "Images",
	"imap": "IMAP",
	"imap_import_from": "The import will import messages from a folder called \"Archive\" or \"[Gmail]/All Mail\" (Gmail account only).",
	"immediately": "immediately",
	"import_company_paste_button": "Import contacts",
	"import_company_paste_confirmation": "Your email addresses have been added as new contacts",
	"import_company_paste_desc": "Copy and paste email addresses into the field below. Note: Only one email address per line is accepted. Existing records will not be updated.",
	"import_company_users_desc": "Upload your CSV file here to bulk import users.<br><br>IMPORTANT: Your CSV must have a header with \"email\", \"first_name\", \"last_name\", \"company_id\", \"labels\", and \"labels_id\" (in this order). Empty values have to be provided for all columns. You can also import values into your custom fields.",
	"import_company_users_working": "Importing contacts. Please wait...",
	"import_contacts_template_link": "Download our CSV template to get started",
	"import_emails": "Import emails",
	"import_emails_desc": "You can import your email history from Gmail, Outlook, and other IMAP compatible servers. Please note that this is a one-time import and not a constant sync. However, you can execute an import multiple times as it only ever fetches the latest messages from your server.",
	"import_emails_desc_2": "Start your import by authenticating your account on your current IMAP mail server now.",
	"import_users": "Import Users",
	"import_users_desc": "Upload your CSV file here to bulk import users.<br><br>Please note that the CSV should have a header with \"email\", \"first_name\", \"last_name\", \"password\" and \"active\" (in this order) to successfully import your users. Records with the same email address will get updated with your new values. Please see <a href='https://helpmonks.com/kb/support/p/bulk-import-customer-s-list-and-helpmonks-users-record' target='_blank'>our How-to page for importing users</a>.",
	"in": "in",
	"in_1_hour": "in 1 hour",
	"in_1_week": "in 1 week",
	"in_3_days": "in 3 days",
	"in_3_hours": "in 3 hours",
	"in_12_hours": "in 12 hours",
	"in_24_hours": "in 24 hours",
	"in_30_minutes": "in 30 minutes",
	"in_days_%s": "In %s days",
	"in_effect": "In effect",
	"in_effect_as_of": "In effect as of",
	"in_effect_until": "In effect until",
	"in_hour_1": "In 1 hour",
	"in_hours_%s": "In %s hours",
	"in_one_week": "In 1 week",
	"in_the_last_day": "within the last day",
	"in_the_last_fifteen_minutes": "within the last 15 minutes",
	"in_the_last_five_minutes": "within the last 5 minutes",
	"in_the_last_hour": "within the last hour",
	"in_the_last_month": "within the last month",
	"in_the_last_three_days": "within the last 3 days",
	"in_the_last_three_hours": "within the last 3 hours",
	"in_the_last_twelve_hours": "within the last 12 hours",
	"in_the_last_two_weeks": "within the last 2 weeks",
	"in_the_last_week": "within the last week",
	"in_timezone": "in time zone",
	"in_weeks_%s": "In %s weeks",
	"inactive": "Inactive",
	"inactive_drip_campaigns": "Inactive sequences",
	"inactive_helpmonks_users": "Deactivated Users",
	"inbox_%s": "%s moved this message to the inbox",
	"include_all_attachments": "Include all attachments",
	"include_all_replies": "Include all replies",
	"incoming": "Incoming",
	"incoming_completed_box_name": "Completed",
	"incoming_completed_box_name_desc": "Mailbox name to move emails that have been processed successfully",
	"incoming_emails": "Incoming emails",
	"incoming_loop_box_name": "Loop",
	"incoming_loop_box_name_desc": "Mailbox name to move emails to that would be loops",
	"incoming_mailboxes": "Define mailboxes",
	"incoming_max_simultaneous_processing_messages": "Limit processing",
	"incoming_no_mailbox_box_name": "No mailbox found",
	"incoming_no_mailbox_box_name_desc": "Mailbox name to move emails to that could not be processed because there was no mailbox found",
	"incoming_processing_box_name": "Processing",
	"incoming_processing_box_name_desc": "Mailbox name to move emails to that are being processed",
	"incoming_query_mailbox_limit": "Limit query",
	"incoming_unprocessable_box_name": "Unprocessable",
	"incoming_unprocessable_box_name_desc": "Mailbox name to move emails to that could not be processed",
	"index_date": "Date Indexed",
	"index_email_chain_now": "Index the Email Now",
	"index_name": "Index Name",
	"index_type": "Index Type",
	"indexed_email_chain": "Indexed Email",
	"individual_signature": "Your email signature",
	"individual_signature_mb_desc": "Below, you have the possibility to design individual signatures per mailbox, including adding images. Please note that remote images should be loaded over HTTPS. Tip: You can switch to the \"code view\" to copy & paste or edit HTML directly.",
	"info": "Info",
	"initial_company_user_message_condition_message": "The customer's message...",
	"initial_message_condition_message": "The current message...",
	"initial_password_required": "Passwords are required when creating new users.",
	"insert": "Insert",
	"insert_variable": "insert into the email body variable",
	"insufficient_rights": "You have insufficient rights, and cannot make these changes",
	"Integrations": "Integrations",
	"internal_users": "Internal users",
	"internal_users_sending": "Your email will be sent to your team members",
	"interval_every_minute_%s": "Every %s minute",
	"interval_header": "Check every...",
	"intro": "Intro",
	"intro_desc": "The intro is used for a preview of the page and used in the overviews and for announcements and the text that shows to your users. For optimal impact, try to keep this under 170 chars.",
	"intro_image": "Featured image",
	"intro_image_alt": "Alternative text",
	"intro_image_alt_desc": "Enter an alternative text for the image. It's good for screen readers and a good practice to boost SEO.",
	"intro_image_desc": "The featured image is shown on top of the page. It is also used within the overview of all pages. We recommend to have an image with 1200px width.",
	"invalid_email_addresses": "Please correct the invalid email addresses in the highlighted fields above.",
	"invalid_url": "Please provide a valid url.",
	"invite": "Invite",
	"invite_members": "Invite members",
	"invite_members_to_razuna": "Invite members to Razuna",
	"invite_people": "Invite people",
	"invite_team": "Invite your team",
	"invite_team_desc": "A shared mailbox begins to show its real potential with multiple team members being active. Swiftly <a href='/settings/users'>add and invite other team members</a>.</br><span class='text-muted lead fst-italic'>User management can be accessed from the main menu at any time.</span>",
	"invite_team_desc_%s": "A shared mailbox is really useful when shared with your team members. You can easily create users and add them to your shared mailbox in the <a href='%s'>settings</a> section of your new mailbox.",
	"invite_team_desc_old": "If you have not already done so, start inviting your team members. A shared mailbox is really useful when shared with your team members. You can create users and add them to your shared mailbox in the settings of the mailbox. Oh yeah, and we <strong>do not charge for any additional users.</strong>",
	"invite_to_workspace": "Invite user to this workspace",
	"invite_user_desc": "Search for the user(s) or type the email address to invite a new user to this workspace",
	"invited_on": "Invited on",
	"invited_people": "Invited people",
	"is_active": "active",
	"is_active_upper": "Active",
	"is_admin": "Is admin",
	"is_assigned_to": "is assigned to",
	"is_assigned_to_option": "The email conversation is already assigned to someone",
	"Is_fully_authenticated": "Is fully authenticated",
	"is_in_label_folder": "The email conversation is in folder",
	"is_indexed": "Indexed",
	"is_initial_company_user_message": "Is the first message from the customer",
	"is_initial_company_user_message_mailbox_option": "The message is the first or a reply from the customer",
	"is_initial_company_user_message_phone_option": "When the message is the first time a customer has texted this phone number via SMS",
	"is_initial_message": "Is the first message of the conversation",
	"is_initial_message_mailbox_option": "The message is the first or the message is a reply",
	"is_initial_message_phone_option": "When the message is the initial communication with a phone number via SMS",
	"is_not_assigned_to_option": "The email conversation is NOT already assigned to someone",
	"is_not_in_label_folder": "The conversation is NOT in label folder",
	"is_not_initial_company_user_message": "Is a reply by the customer",
	"is_not_initial_message": "Is NOT the first of the in the conversation, it is a reply",
	"is_not_suspicious": "Is not spam",
	"is_suspicious": "Is spam",
	"is_verified": "Is Verified",
	"is_within_the_hours_of": "The conversation arrives within a certain time",
	"item": "Item",
	"item_name_contains": "item name contains",
	"items": "items",
	"joined": "Joined",
	"just_now": "Just now",
	"keyboard_shortcuts": "Keyboard Shortcuts",
	"keyboard_shortcuts_desc_%s": "Navigate in %s faster",
	"keys": "Keys",
	"keywords": "Keywords",
	"knowledge_base": "Knowledge Base",
	"knowledge_base_subheading_1": "Create public and private docs for your team and customers",
	"knowledge_base_subheading_2": "Keep your team or your customers informed",
	"knowledge_bases": "Knowledge bases",
	"label": "Label",
	"label_add_new": "Add label",
	"label_added_to_conversation": "The label has been added to the conversation",
	"label_added_to_selected_conversations": "The label has been added to the selected conversations",
	"label_available": "Label is available in the following mailboxes",
	"label_cannot_be_deleted_until_%d_conversations_are_removed_from_the_%s_folder": "Label cannot be deleted until %d conversations are removed from the '%s' folder",
	"label_email_forgot_password": "Enter your email address",
	"label_folder_moved": "The selected conversations have been moved to the specified folder",
	"label_folder_to_selected_conversations_%s": "The selected conversations have been moved to the folder %s",
	"label_header_last_15": "The latest 15 messages with the label",
	"label_hide_as_folder": "Your label will not show up in the folder list anymore, but stays as a label",
	"label_logs": "Label logs",
	"label_move_main_desc": "When you label a conversation, the conversation will remain in the current status, e.g., in the Inbox. If you enable this feature, it will move conversations that your team and you label, into the \"Pending\" status. Note: This will only affect conversations that do *not* have the status \"Closed\" and \"Archived\".",
	"label_move_main_header": "Label conversation behavior",
	"label_name": "Name this label",
	"label_name_update": "The label has successfully been renamed!",
	"label_nest_label": "Nest label under...",
	"label_nest_root": "Move label to the top level",
	"label_nest_root_upper": "MOVE LABEL TO THE TOP LEVEL",
	"label_no_label_message": "Do you know that you can create labels and folders to organize your conversations? Labels and folders will show in this spot.<br><br><a href='/settings/labels'>Add one now</a>",
	"label_placeholder_name": "Please enter name of label",
	"label_select_mailbox_error": "Please select at least one mailbox for this label",
	"label_selected_%s": "Add a label to the selected conversation%s",
	"label_show_as_folder": "Your label will now show up as a folder!",
	"label_tip": "Tip: To quickly edit the name simply click on the name",
	"labels": "Labels",
	"labels_folders": "Labels / Folders",
	"labels_lowercase": "labels",
	"labels_roadmap_feedback": "Labels for Roadmap and Feedback items",
	"landing_page_crm": "the CRM",
	"landing_page_dashboard": "the Dashboard",
	"landing_page_desc": "Set your \"Go-To\" page when you sign into the system",
	"landing_page_field": "When I sign in I want go to...",
	"landing_page_groups": "a Group",
	"landing_page_header": "Set your landing page",
	"landing_page_mailbox": "a Mailbox",
	"landing_page_new": "Create a new page",
	"landing_page_preview": "Preview page",
	"landing_page_view": "View page",
	"landing_pages": "Landing pages",
	"landing_pages_desc": "Create beautifully crafted landing pages to capture new users for your mailing lists. You can use any of your dynamic forms or create a standard form on the page itself. Pages are hosted on our network, but you can map a custom domain too. Be sure to let us know if you are missing something.",
	"large_thumbnail": "Large thumbnail",
	"largest_processed": "Largest Processed Emails",
	"last_2_weeks": "Last 2 weeks",
	"last_3_correspondences": "Last three conversations",
	"last_3_months": "Last 3 months",
	"last_6_months": "The last 6 months",
	"last_7_days": "Last 7 days",
	"last_12_months": "The last 12 months",
	"last_action": "Last Action",
	"last_correspondence": "Last conversations",
	"last_correspondences_%s": "The last %s conversations",
	"last_customer_reply_was": "last customer reply was",
	"last_customer_reply_was_option": "The email conversation has a reply from the customer within a period of time",
	"last_internal_reply_was": "last internal reply was",
	"last_internal_reply_was_option": "The email conversation has a reply from a mailbox user within a period of time",
	"last_login_date": "Last login date",
	"last_message_from_no_reply": "We believe the TO email address is incorrect or that it is a no-reply email address. Please check before sending.",
	"last_month": "Last month",
	"last_name": "Last name",
	"last_name_label_text": "Last name: Label text",
	"last_updated": "Last updated",
	"lastest_comment_by": "Latest comment by",
	"latest_activities": "Latest activities",
	"latest_reply_user_header": "Reply from",
	"leave_empty_to_no_show": "Empty will not show",
	"legend": "Legend",
	"let_me_change_it": "Let me change it",
	"let_me_check_that": "Let me check that",
	"lets_get_started": "Create my first mailbox now",
	"link_autoreply_signature": "Auto reply & Signature",
	"link_autoreply_signature_desc": "You can define the signature and an auto-reply for your mailbox below.",
	"link_back_to_sign_in": "Go back to the log in screen",
	"link_blackwhitelist_log": "Email Filters Log",
	"link_blackwhitelist_settings": "Email Filters",
	"link_conversation": "Link conversation",
	"link_download": "Allow download",
	"link_download_desc": "Allow downloading of files",
	"link_edit": "Allow editing",
	"link_edit_desc": "Anyone knowing this link is allowed to edit files",
	"link_expiration": "Link expiration",
	"link_forgot_password": "I forgot my password",
	"link_go_back": "Go back",
	"link_merge_conversations_button_modal": "Merge/link another conversation",
	"link_merge_conversations_button_sidebar": "Merge or link conversations",
	"link_merge_header": "Find one or many conversations to merge or link to",
	"link_merge_short": "Link or Merge",
	"link_password": "Password protect",
	"link_send_another": "Compose another message",
	"link_upload": "Allow upload",
	"link_upload_desc": "Allow uploading of files",
	"linked_conversations": "Linked conversations",
	"linked_conversations_notification": "The conversation has been linked. You can find it in the right panel in the conversation window now",
	"linked_conversations_notification_error": "An error occurred during linking this conversation. If this happens again, please contact us. Thank you",
	"linked_conversations_notification_same": "You cannot link the same conversation into each other!",
	"linked_conversations_remove": "Remove this linked conversation",
	"list_add": "Add this list",
	"list_add_placeholder": "Name of list",
	"list_add_to": "Add to list",
	"list_add_to_desc": "Adding this feedback to a list will make it appear on the roadmap",
	"list_delete": "Delete this list",
	"list_growth": "List growth",
	"list_in": "In list",
	"list_name": "What's the name of this list?",
	"list_new": "New list",
	"list_publish": "Publish list",
	"live_chat": "Live Chat",
	"live_chat_desc": "Use our Live Chat to talk with your customers in real-time. You can use the chat widget on any website you like. By adding chat groups, you have the option to use Live Chat on more than one website with individual settings.",
	"live_chat_subheading_1": "Live-Chat and Push notifications for your sites",
	"live_chat_subheading_2": "Use push notifications",
	"live_chats": "Live Chats",
	"load_all_messages": "Load all messages",
	"load_more_data": "Load more",
	"located_in": "Located in",
	"lock_login": "Lock login to all hosts",
	"log": "Log",
	"log_add_contact": "added the contact",
	"log_add_meeting": "added meeting notes",
	"log_added_note": "created note",
	"log_added_pipeline": "added the pipeline",
	"log_added_task": "added the task",
	"log_assigned_box": "assigned the box",
	"log_completed_task": "completed task",
	"log_created_box": "created box",
	"log_entry": "Log entry",
	"log_for": "Log for",
	"log_remove_after_%s": "Logs are automatically removed after %s days",
	"log_removed_contact": "removed the contact",
	"log_removed_task": "removed the task",
	"log_renamed_box": "renamed box",
	"log_update_box_stage": "updated stage of box",
	"log_updated_task": "updated task",
	"login_image": "Login Image",
	"login_image_desc": "We randomly show beautiful full screen images on our login screens. If you rather want to use a static image, upload one here. You background image should be of 1920px width and 1080px height.",
	"login_logo": "Custom login logo",
	"login_option": "Login",
	"login_url": "Login url",
	"logout_option": "Logout",
	"logs_desc": "The activity logs show the different activities of different sections",
	"look_and_feel": "Look & Feel",
	"magic_link_button": "Send me my Magic Link",
	"magic_link_desc": "Enter your email address below and we will email you a personal magic link to log in",
	"magic_link_header": "Magic Link Log in",
	"magic_link_sent": "Go check your inbox for your Magic Link",
	"mail": "Mail",
	"mail_server": "Mail-server",
	"mail_server_email_address_desc_%s_%s": "Please enter the hostname of your mail server. The email address DOES NOT need to exist if you are using the %s appliance or a managed %s cloud server!",
	"mail_settings": "Mail Settings",
	"mail_settings_individual_desc": "Some settings can be overwritten within each individual mailbox.",
	"mail_update_arrived": "A new message just arrived",
	"mailbox": "Mailbox",
	"mailbox_access": "Mailbox Access",
	"mailbox_activity": "Mailbox Activity",
	"mailbox_address": "Your shared mailbox has the address",
	"mailbox_color_choose": "Choose another color for this mailbox.",
	"mailbox_color_current": "The current color for this mailbox.",
	"mailbox_color_desc": "Choose the color for your mailbox.",
	"mailbox_color_header": "Color of mailbox",
	"mailbox_done_header": "That's it! Did you expect more?",
	"mailbox_done_how_to_%s": "Our <a href='%s' target='_blank'>How-To Guides</a> describe how to forward emails in Google Apps, Office 365, Exchange Server and many others.",
	"mailbox_email_address": "Mailbox Email Address",
	"mailbox_email_address_desc": "This is the email address where you already receive emails, and would like to expand it to a shared mailbox.",
	"mailbox_email_address_desc_sub_%s": "Tip: Until you are ready to forward from your email address to %s, you can use the unique email address of the mailbox for testing",
	"mailbox_email_address_examples": "e.g. info@acme.com, sales@acme.com, support@acme.com, etc.",
	"mailbox_email_address_warning": "Please note that the email address must be associated with an existing mailbox that is able to send and receive emails for your shared inbox to work properly.",
	"mailbox_email_desc": "Select what you would like to show in the FROM of the email header. By default your mailbox name is shown. If you rather would like to show the username, select the \"Show username\" option.",
	"mailbox_email_desc_gold": "You can also select \"Send from the primary email address of the user\" which will send the email from the users email address directly. Please note, that with this option enabled, replies will go to the user and NOT to the shared inbox address!",
	"mailbox_logs": "Mailbox",
	"mailbox_name_external": "External mailbox name",
	"mailbox_name_external_desc": "This is the name that your email recipients will see if you select \"Mailbox\" in the \"Send email from\" option below.",
	"mailbox_name_internal": "Internal mailbox name",
	"mailbox_name_internal_desc": "This is the mailbox name you use within your team. Your email recipients will never see this name.",
	"mailbox_new_desc": "This can be any name you want, it simply identifies this mailbox to your team.",
	"mailbox_notification": "Notifications for your mailbox(es)",
	"mailbox_notifications_approval_user": "... any approval changes (requests, rejected, and approved)",
	"mailbox_notifications_assignment": "... when the conversation is not assigned to the user",
	"mailbox_notifications_assignment_group": "... when the conversation is not assigned to this group",
	"mailbox_notifications_assignment_user": "... the conversation is not assigned to me",
	"mailbox_notifications_comments": "... a team member replies or creates a note on a conversation not assigned to the user",
	"mailbox_notifications_comments_group": "... a team member replies or creates a note not assigned to this group",
	"mailbox_notifications_comments_user": "... a team member replies or creates a note on a conversation not assigned to me",
	"mailbox_notifications_desc": "Define the default notifications for this mailbox. Users can overwrite these notifications in their profiles.",
	"mailbox_notifications_desktop_header": "Send a desktop notifications when...",
	"mailbox_notifications_header": "Default Notifications",
	"mailbox_notifications_mentions": "... when someone mentions the user",
	"mailbox_notifications_mentions_group": "... when someone mentions this group",
	"mailbox_notifications_mentions_user": "... when someone mentions me",
	"mailbox_notifications_mine": "... when the conversation is assigned to the user",
	"mailbox_notifications_mine_group": "... when the conversation is assigned to this group",
	"mailbox_notifications_mine_user": "... any action and email (new emails and replies) that are assigned to me",
	"mailbox_notifications_new_message": "... a new email arrives (new messages and replies that are unassigned)",
	"mailbox_notifications_replies": "... the customer replies to a conversation not assigned to the user",
	"mailbox_notifications_replies_group": "... the customer replies to a conversation not assigned to this group",
	"mailbox_notifications_replies_user": "... a customer replies to a conversation not assigned to me",
	"mailbox_notifications_todo": "... any To-Do that is assigned, receives a comment, or user get notified",
	"mailbox_notifications_todo_user": "... any To-Do that is assigned to me, receives a comment, or I'm being notified",
	"mailbox_notifications_tracking": "... when the conversation is viewed by the recipient (tracking)",
	"mailbox_permissions": "Mailbox Permissions",
	"mailbox_selection": "Show all mailboxes",
	"mailbox_settings": "Mailbox Settings",
	"mailbox_settings_for_%s": "Settings for %s",
	"mailbox_settings_labels_desc": "If you want to show all the labels as folders on the left side then activate the Label-Explorer below. Note: After changing this setting please refresh your browser window to see/hide the labels on the left.",
	"mailbox_settings_lower": "mailbox settings",
	"mailbox_settings_smtp_email_size_limit_desc_%s_%s": "Set the email size in bytes of outgoing messages. The system administrator has it set to %s (%s bytes).",
	"mailbox_settings_spam_black_list_desc": "List emails addresses and domains for which you would like to block communication.",
	"mailbox_settings_spam_white_list_desc": "Any email NOT on this list will not be able to send you any email. The same applies for sending an email from within your mailbox!",
	"mailbox_users": "Mailbox users",
	"mailbox_users_active_%s": "%s users that are subscribed to this mailbox",
	"mailbox_users_inactive_%s": "%s users that are not subscribed to this mailbox",
	"mailboxes": "Mailboxes",
	"mailboxes_list": "Mailboxes",
	"main_admin_desc": "This is the back-end administration. Create hosts, activate plugins, set default settings and more here.",
	"make_primary": "Make primary",
	"make_private_note": "Note to myself",
	"manage_labels": "Manage labels",
	"manage_mailboxes": "Manage existing mailboxes",
	"manage_plugins": "Installed plugins",
	"manage_plugins_directory": "Plugins directory",
	"manage_users_in_admin": "Head over to your admin settings to manage users and teams",
	"manage_users_link": "Manage users",
	"manage_workspace": "Manage this workspace",
	"manage_workspace_tags": "Manage workspace tags",
	"mapping": "mapping",
	"mark_all_as_read": "Mark all as read",
	"mark_all_read": "Mark all read",
	"mark_as_read": "Mark answered",
	"mark_as_spam_customer": "Mark as Spam",
	"mark_as_spam_customer_desc": "Marking this contact as Spam will hide it and incoming messages are automatically marked as Spam, too",
	"mark_as_unread": "Mark unanswered",
	"mark_completed": "Mark as completed",
	"mark_read_note_%s": "%s marked this conversation as answered",
	"mark_running": "Mark as running",
	"mark_status_message_%s_%s": "%s marked the conversation as %s",
	"mark_unread_note_%s": "%s marked this conversation as unanswered",
	"mark_unsubscribed": "Mark as unsubscribed",
	"marked_as_spam_%s": "This message has been marked as SPAM by our system. Either because the sender is in your blocked email-address list or the email has SPAM headers that identifies this email as SPAM.<br><br>Your friends at %s.",
	"marked_filter_and_move_to_spam_desc": "Enabling this setting will put the sender of the email on the blocked email list when you mark the email conversation as Spam. Any future emails from the sender will automatically be moved to the Trash. Please note that you will not get a notification for messages that are moved to the Spam-folder.",
	"marked_filter_and_move_to_spam_header": "Upon marking emails as Spam",
	"marketing": "Marketing",
	"marketing_automation_header": "Marketing - Email Sequences",
	"marketing_campaigns": "Marketing Campaigns",
	"marketing_campaigns_header": "Marketing - Email Campaigns",
	"match_replies_by_subject": "Match email replies by subject and sender",
	"match_replies_by_subject_desc": "Enable this option on the mailbox to automatically match emails as replies when the email conversation is with the same user and contains the same subject. This setting is disabled by default, but is useful with Amazon and Ebay proxy emails, which do not include standard headers to match replies.",
	"match_replies_by_subject_note": "With this setting enabled individual customers can be marked to avoid combining their replies by subject. Marking selected customers as such, is suggested for addresses that sends messages that should not get grouped together",
	"maybe_filter": "Maybe you have a filter set? Click here to reset it",
	"me": "Me",
	"meet_funnels": "Meet Funnels",
	"meet_funnels_desc": "Funnels, aka Pipelines, are your perfect companion for staying on top of your support and sales pipelines. Create a funnel and track the progress of your projects (boxes) with stages. To create your first funnel, hit the \"plus\" button above.",
	"meet_stages": "Add stages",
	"meet_stages_desc": "Create your first stage. A stage is used to keep track on the progress of your funnel. Once you create a stage you add boxes to the stage. To create your first stage now, hit the \"plus\" button above.",
	"meeting": "Meeting",
	"meeting_notes": "Meeting notes",
	"meeting_on": "Meeting on",
	"meeting_with": "Meeting with",
	"meetings": "Meetings",
	"member_of": "Member of",
	"member_of_teams": "Member of team(s)",
	"member_since": "Member since",
	"members": "Members",
	"members_and_teams": "Members & Teams",
	"members_who_requested_access": "Members who requested access",
	"mentions": "Mentions",
	"merge_conversation": "Merge conversation",
	"merge_conversations_notification_error": "An error occurred during the merge. If this happens again, please contact us. Thank you",
	"merge_conversations_notification_same": "You cannot merge the same conversation into each other!",
	"merge_from_%s_%s": "This %s was merged from another conversation at %s",
	"merge_modal_button": "Yes, merge the conversations",
	"merge_modal_header": "Merge conversations",
	"merge_modal_note": "Please note, that once a conversation is merged, it cannot be un-merged",
	"merge_modal_text": "Merging a conversion will merge notes and emails into the conversation in the back. Don't worry, labels, status and assignee will stay the same",
	"message": "Message",
	"message_from_sequence": "This is a message from your follow-up sequence",
	"message_has_cc_option": "The email conversation contains specific CC values",
	"message_in_helpmonks_%s": "Read conversation in %s",
	"message_info_delivered_error": "Not delivered",
	"message_info_delivered_success": "Sent",
	"message_info_delivered_to": "Delivered to",
	"message_info_delivery": "The delivery log for this message",
	"message_info_desc": "Please note, that we only keep delivery results for up to 30 days from the time of delivery",
	"message_info_nothing": "We could not find any delivery logs for this message. Either sending was disabled or nothing had to be sent",
	"message_info_tootip": "View the delivery log for this message",
	"message_is_from": "Message is from",
	"message_is_from_option": "The email conversation is from a specific sender",
	"message_is_not_from_option": "The message is NOT from a specific sender",
	"message_label_text": "Message: Label text",
	"message_lower": "message",
	"message_moved_to_mailbox_%s": "The conversation has been moved to the \"%s\" mailbox",
	"message_new": "New message",
	"message_original_header": "Original message",
	"message_original_tootip": "Show the original message",
	"message_placeholder": "Message placeholder",
	"message_sent": "Your message has been sent",
	"message_spam_desc": "<p>Our spam filter has identified content in your marketing email that exceeds the threshold for acceptable text, links, or specific words. Please revise your message accordingly.</p><p>Please get in touch with us for further verification if you believe this is a mistake.</p>",
	"messages": "Messages",
	"messages_%s": "%s messages",
	"messages_marked_answered": "The selected messages have been marked as answered",
	"messages_marked_answered_one": "The message has been marked as answered",
	"messages_marked_unanswered": "The selected messages have been marked as unanswered",
	"messages_marked_unanswered_one": "The message has been marked as unanswered",
	"messages_moved": "The message has been moved successfully",
	"messages_moved_to_mailbox_%s": "The selected conversations have been moved to the \"%s\" mailbox",
	"messages_moved_to_trash": "The conversation has been moved to the trash",
	"messages_moved_to_trash_many": "The selected conversations have been moved to the trash",
	"messages_shared_at": "Messages are publicly shared at",
	"messages_status_updated": "The status has been changed successfully",
	"messaging": "Messaging",
	"messaging_subheading_1": "All your shared inboxes unified",
	"messaging_subheading_2": "Email, marketing & support in one hub",
	"metadata": "Metadata",
	"method": "Method",
	"middle_name": "Middle name",
	"mine": "Mine",
	"minute": "minute",
	"minutes": "Minute(s)",
	"minutes_plural": "Minutes",
	"miscellaneous": "Miscellaneous",
	"modal_button_remove_host": "Yes, remove host now",
	"modal_button_remove_user": "Yes, remove record now",
	"modal_deregister_note": "Note: All plugin data for this plugin for all hosts will be permanently removed",
	"modal_deregister_question": "Are you sure you want to deregister this plugin?",
	"modal_dergister_header": "De-register Plugin",
	"modal_fix_sla_button": "Execute SLA maintenance now",
	"modal_fix_sla_done": "The SLA maintenance task has ran. Please find some information about the task below.",
	"modal_fix_sla_done_delete": "Please, click here to acknowledge the task",
	"modal_fix_sla_done_delete_success": "Thank you for acknowledging the task. You can now close this window.",
	"modal_fix_sla_error": "The SLA maintenance task cannot be started at the moment due to an error",
	"modal_fix_sla_header": "SLA maintenance",
	"modal_fix_sla_note": "Depending on how many conversation you have, this can take a long time. We will notify you by email when the SLA maintenance has finished.",
	"modal_fix_sla_running_already": "There is already SLA maintenance task running. Please wait until the task is done.<br><br>Please find some information about the task below.",
	"modal_fix_sla_started": "The SLA maintenance task has started. You can close this window now and can come back here to check the status.",
	"modal_fix_sla_text": "The SLA maintenance allows you to associate existing conversations with companies and their SLA. Thus conversations are automatically assigned to a company with their respective SLA.",
	"modal_header_clear_log_entries": "Clear Log Entries",
	"modal_header_deactivate_user": "Deactivate User",
	"modal_header_label": "Label",
	"modal_header_news_add": "Add new entry",
	"modal_header_note": "Edit note",
	"modal_header_reactivate_user": "Reactivate User",
	"modal_header_remove_host": "Remove host",
	"modal_header_remove_record": "Remove record",
	"modal_header_remove_user": "Remove member",
	"modal_header_remove_users": "Remove members",
	"modal_header_saved_replies": "Saved Replies",
	"modal_header_saved_reply": "Saved Reply",
	"modal_remove_alert_button": "Yes, remove this alert",
	"modal_remove_alert_header": "Remove this alert",
	"modal_remove_alert_text": "This will remove the alert. If the alert is currently active, the alert will also be removed from the host pages.",
	"modal_remove_company_header": "Remove Company",
	"modal_remove_company_note": "Note : This action is permanent",
	"modal_remove_company_text": "Are you sure you want to remove this company?",
	"modal_remove_company_user_header": "Remove Customer",
	"modal_remove_company_user_note": "Note : This action is permanent",
	"modal_remove_company_user_text": "Are you sure you want to remove this customer?",
	"modal_remove_label_header": "Remove label",
	"modal_remove_label_note": "Note: If the label has any sub-labels they will also be removed. This action is final and cannot be undone!",
	"modal_remove_label_text": "Do you really want to remove this label?",
	"modal_remove_mail_server_button": "Yes, remove this mail-server",
	"modal_remove_mail_server_header": "Remove this mail-server",
	"modal_remove_mail_server_note": "This action cannot be undone. This will also remove all the monitoring logs of this mail-server.",
	"modal_remove_mail_server_text": "Are you sure that you want to remove the mail-server from monitoring?",
	"modal_remove_savedreply_header": "Remove Saved Reply",
	"modal_remove_savedreply_text": "Do you really want to remove this saved reply?",
	"modal_remove_sla_btn": "Remove this SLA",
	"modal_remove_sla_header": "Remove Service Level Agreement",
	"modal_remove_sla_note": "You will not be able to undo this action.",
	"modal_remove_sla_text": "Are you sure you want to remove this service level agreement?",
	"modal_remove_workflow_header": "Remove Workflow",
	"modal_remove_workflow_note": "You will not be able to undo this action.",
	"modal_remove_workflow_text": "Are you sure you want to remove this workflow?",
	"monday": "Monday",
	"monitoring_cloud_servers_%s": "Customers of the %s Cloud Servers to do need to enable monitoring as this is already configured and part of our support service for you.",
	"monitoring_desc": "Configure monitoring for sending and receiving emails",
	"monitoring_incoming_desc": "Configure monitoring of your receiving mail-server(s). This will send an email from the monitoring server to the email-servers below and checks that emails can be delivered to your mail-server(s).",
	"monitoring_receiving": "Receiving emails",
	"monitoring_sending": "Sending emails",
	"monitoring_sending_desc": "Configure monitoring of sending emails, i.e., that your mail server(s) are operating properly. This will send an email to the monitoring server and check it it arrives.",
	"monitoring_settings_desc": "Configure the monitoring SMTP and IMAP servers here. This is used to send test emails and for picking up emails over IMAP",
	"month_starting": "month starting",
	"more": "More",
	"more_actions": "More actions",
	"more_conversations": "More conversations",
	"more_send_options": "More sending options",
	"more_than_a_month_ago": "more than one month ago",
	"most_active_companies": "Most active companies",
	"most_active_contacts": "Most active contacts",
	"most_active_conversations": "Most active conversations",
	"most_assigned_emails": "User with most assigned emails",
	"most_recent_conversations": "Most recent conversations",
	"most_replied_emails": "User with most replies",
	"most_used_labels": "Most used labels",
	"most_used_workflows": "Workflows with the most executions",
	"move": "Move",
	"move_all_boxes_to": "Move all boxes to",
	"move_all_cards_to": "Move all cards to",
	"move_confirm": "Confirm move",
	"move_conversation": "Move conversation",
	"move_conversations": "Move conversations",
	"move_folder_to_root": "Move folder to the top level",
	"move_label_to": "Move label to...",
	"move_page": "Move page",
	"move_page_to": "Move page to...",
	"move_section_to_root": "Move section to the top level",
	"move_selected_%s": "Move the conversation%s to...",
	"move_to": "Move to",
	"move_to_folder": "Move to folder",
	"move_to_label_folder": "Move the conversation to label folder",
	"move_to_label_folder_desc": "Select the folder to which the conversation should be moved",
	"move_to_mailbox": "Select the mailbox to which the conversation should be moved to",
	"move_to_mailbox_option": "Move the conversation to another mailbox",
	"move_to_mailbox_short": "Move to mailbox",
	"move_to_spam_desc": "Enable this setting to automatically move messages to the Spam-folder that are sent from contact records which are marked as spam. Please note that you will not get a notification for messages that are moved to the Spam-folder.",
	"move_to_spam_enable": "Enable \"Move to Spam-folder\"",
	"move_to_spam_header": "Move messages from contacts marked as spam to the Spam-folder",
	"my_activity": "My activity",
	"my_dashboard": "My Dashboard",
	"my_distribution_list": "My distribution list",
	"my_drafts": "My Drafts",
	"my_helpmonks_account": "My Helpmonks account",
	"my_profile": "My profile",
	"my_razuna_account": "My Razuna account",
	"my_sent_email_campaigns": "My sent email campaigns",
	"my_templates": "My templates",
	"myself": "Myself",
	"myself_lowercase": "myself",
	"name": "Name",
	"name_category": "Name of category",
	"name_of_page": "The name of this page",
	"navigation": "Navigation",
	"navigation_administration": "Administration",
	"navigation_bar": "Top navigation header",
	"navigation_global_settings_megamenu": "Edit account-wide settings",
	"navigation_labels_megamenu": "Edit your labels",
	"navigation_logs_megamenu": "Browse the delivery, tracking, and other logs",
	"navigation_pipelines": "Pipelines",
	"navigation_plugins_megamenu": "Enhance your workflow with additional features",
	"navigation_products": "Products",
	"navigation_report": "Report",
	"navigation_reports": "Reports",
	"navigation_settings_addons": "Plugins",
	"navigation_settings_addons_desc_%s": "Manage your installed plugins below. To see what other plugins are available please visit <a href='%s'>our Plugins directory</a>.",
	"navigation_settings_addons_gallery": "Available Plugins",
	"navigation_settings_addons_gallery_desc": "Find all the available Plugins below. Click on each Plugin to see more details and for installation.",
	"navigation_settings_backup": "Backup & Restore",
	"navigation_settings_custom_fields": "Custom Fields",
	"navigation_settings_customers": "Customers & Companies",
	"navigation_settings_customers_megamenu": "Edit Customers & Companies",
	"navigation_settings_general": "General",
	"navigation_settings_general_desc": "Here you will find all the mailbox settings. Changes will take effect immediately!",
	"navigation_settings_helpmonks_users_%s": "%s Users",
	"navigation_settings_helpmonks_users_megamenu_%s": "All users of %s",
	"navigation_settings_labels": "Labels",
	"navigation_settings_logs": "Activity Logs",
	"navigation_settings_maintenance": "Maintenance",
	"navigation_settings_services": "Services",
	"navigation_settings_system_information": "System Information",
	"navigation_settings_templates": "Templates",
	"need_help": "Need help?",
	"need_help_or_want_to_learn_more": "Need help, or want to learn more?",
	"needs_unsubscribe_desc": "It looks like you did not include the required unsubscribe link in your template.<p>Adding the option to unsubscribe from your mailing list is not only a good email etiquette but also required by law in many countries.</p><p>Please add the $unsubscribe$ link now.</p>",
	"nest_folder": "Nest folder",
	"nest_section": "Nest section under...",
	"never": "never",
	"never_been_registered": "This plugin has never been registered",
	"never_mind": "Never mind",
	"new": "New",
	"new_campaign_title": "What do you want to call this campaign?",
	"new_collection_placeholder": "What do you want to call this collection?",
	"new_comment": "New comment",
	"new_conversation": "Compose",
	"new_conversation_desc": "The new conversation will be stored in the \"Inbox\" or when assigned in \"Assigned\", resp. \"Mine\". Tip: Start typing to find saved email-addresses. To add a new email-address just hit \"Enter\".",
	"new_conversation_header": "Compose a new conversation",
	"new_drip_campaign": "Add a new sequence",
	"new_mailbox": "New Mailbox",
	"new_mailbox_forward_text_%s": "Configure %s to forward incoming emails to",
	"new_mailbox_header": "Create a new mailbox",
	"new_mailbox_header_almost_done": "High five. Your mailbox is ready!",
	"new_mailbox_header_desc": "Add a new mailbox to your system to streamline conversations",
	"new_mailbox_header_done": "Mailbox setup successfully",
	"new_mailbox_header_done_desc_%s": "From here on out, you and your team will benefit from a more collaborative email experience with your new %s shared mailbox",
	"new_mailbox_header_users": "Add your team members to the mailbox",
	"new_mailbox_header_users_desc_%s": "All team members that are given access will have permission to read emails. Other permissions are set in the general user permission settings. You can add and edit users in the <a href='%s'>Administration</a>. Administrator have access to all mailboxes at any time.",
	"new_mailbox_header_users_skip": "You can also skip this for now and add users later",
	"new_password_reset": "Hey there! Below is your new login password. Make sure to keep it safe in your password manager. Remember, the password is only shown once.",
	"new_shareable_link": "Create a new shareable link",
	"new_tab": "Add a new tab",
	"newest": "Newest",
	"newest_first": "Newest first",
	"news_desc": "Stay informed with the latest news, updates, enhancements, and changes.",
	"news_entry": "New entry",
	"news_external_link": "Link to outside post",
	"news_external_link_desc": "If you want to point to a related post on another site, enter the full URL here.",
	"news_header": "News",
	"news_in_modal": "Show in a popup-window",
	"news_in_modal_desc": "If checked, will show this news record in a popup-window to the user when the user is within the system. The news record will only show one time. This can be rather annoying to the user and we recommend to only use this option for very important news.",
	"news_nothing_found": "There are no news published at this time.<p>Please check again later</p>",
	"news_on_page": "Show on page",
	"news_on_page_desc": "Show this news entry only on a specific page",
	"news_popover_more_news_link": "More news",
	"next": "Next",
	"next_action": "Next action",
	"next_week": "Next week",
	"no_access": "No access",
	"no_access_to_folder": "You don't have the permission to access the content of this folder. If you feel this is in error, please contact your administrator.",
	"no_actions": "Please select at least one action to save the workflow",
	"no_boring_avatars": "No boring avatars",
	"no_boring_avatars_desc": "Choose your avatar style from over 30 styles and shapes, and break free from avatar boredom. You and your team will all appear in this style, but only for you.",
	"no_change": "Don't change",
	"no_chats_%s": "%s never had a live chat conversation with you",
	"no_collection_desc": "Collections are a handy tool to organize your files. You can create a collection and add files to it. Once created you can share the collection with others or keep it private.",
	"no_collection_header": "Hey, looks like you have no collection (yet)",
	"no_collisions_emails": "No collisions for any replies sent by email",
	"no_collisions_found": "No collisions found",
	"no_collisions_replies": "No collisions for any replies",
	"no_company_association": "No Company Association",
	"no_company_users_label": "There are no labels here.<br><br>Create a new label now and use it to add the label(s) your customer records accordingly. Adding a label, allows you to sort your customer records, also.",
	"no_contacts": "No contacts (yet)",
	"no_contacts_desc_1": "There are no contacts here (yet). You can add new contacts or import them from another system.",
	"no_contacts_desc_2": "Tip: Create labels to categorize them and use labels to add or remove contacts automatically to your distribution lists or automated email campaigns. You can also add contacts with our API.",
	"no_conversation_selected": "No conversation selected",
	"no_conversations_for_contact": "This contact has had no conversation with you (yet)",
	"no_conversations_here": "No conversations here",
	"no_data": "no data",
	"no_drafts": "You have no drafts saved",
	"no_due_date": "No due date",
	"no_email": "No email notifications at all",
	"no_email_desc": "If \"no email\" is activated the user will not receive any emails from the system (as there are always internal notifications about assignment and other notifications that still get sent). Turn this on, if your user works in the system solely or uses a fake email address.",
	"no_email_desc_user": "If \"no email\" is activated you will not receive any emails from the system (as there are always internal notifications about assignment and other notifications that still get sent). Turn this on, if you work in the system solely or use a fake email address.",
	"no_files": "This contact never sent any files to you",
	"no_history": "No history",
	"no_host_specified": "no host specified",
	"no_labels": "You got no labels yet. Use labels to tag your conversations and make it easier to get organized. Besides, you can use labels with your workflows and automate your business processes automatically, too. Tip: Labels can also be used as \"Folders\", i.e., to move conversation to a Label-Folder.",
	"no_list_%s": "%s is not a subscriber of any of your distribution lists",
	"no_logs": "Looks like there are no history logs (yet) available. Check back later again.",
	"no_mail_server_found": "No mail-server found for monitoring. Add a new one with the button below.",
	"no_mailbox_assigned": "Hey there.<br><br>It looks like you didn't assign this user to any mailboxes. The user can still sign in, but has no access to any mailbox.",
	"no_mailbox_settings_access": "Hey there.<br><br>It looks like your Administrators did not give you any access to the mailbox settings. I bet that if you ask them nicely, they will bestow their mercy upon you!",
	"no_mailboxes": "You do not have access to any mailboxes. Please contact your administrator if this is in error or to get access to mailboxes.",
	"no_mailboxes_available": "There are no other mailboxes available",
	"no_mailboxes_available_starred": "You have no starred mailboxes",
	"no_mailboxes_header": "No mailboxes here",
	"no_manage_notifications_permission": "You do not have permission to manage your notification settings, please contact your administrator to request updates",
	"no_manage_profile_permission": "You do not have permission to manage your profile, please contact your administrator to request updates",
	"no_marketing_campaigns_%s": "%s never received a marketing campaign from you",
	"no_marketing_campaigns_auto_%s": "%s never received an email sequence from you",
	"no_matching_route": "no matching route",
	"no_messages": "Inbox zero! No conversations found here",
	"no_messages_collisions": "No collisions found. You and your team are in total sync",
	"no_messages_general": "No conversations in here",
	"no_messages_trash": "Your trash is empty",
	"no_more_results": "no more results",
	"no_one": "No one",
	"no_plugin_activation_updates": "No plugins were selected to be activated or deactivated",
	"no_records": "The records created yet",
	"no_reply_for_day": "after 1 day",
	"no_reply_for_fifteen_minutes": "after 15 minutes",
	"no_reply_for_five_minutes": "after 5 minutes",
	"no_reply_for_half_an_hour": "after 30 minutes",
	"no_reply_for_hour": "after 1 hour",
	"no_reply_for_month": "after more than a month",
	"no_reply_for_three_days": "after 3 days",
	"no_reply_for_three_hours": "after 3 hours",
	"no_reply_for_twelve_hours": "after 12 hours",
	"no_reply_for_two_days": "after 2 days",
	"no_reply_for_two_hours": "after 2 hours",
	"no_reply_for_two_weeks": "after 2 weeks",
	"no_reply_for_week": "after 1 week",
	"no_reports_found": "Looks like there is no report for your account yet. Please come back in 24 hours.",
	"no_response_time": "no guaranteed response time",
	"no_results": "no results",
	"no_retention_policy": "No retention policy",
	"no_retention_policy_desc": "Use to keep all emails in the mailbox. Manually delete emails when needed.",
	"no_root_folder_sharing": "The root folder of a workspace cannot be shared. Any other folders can freely be shared.",
	"no_search_results_found": "No conversations found",
	"no_search_results_found_%s": "No results found for term '%s'",
	"no_sections": "No sections",
	"no_sections_desc_1": "Sections allow you to create your custom view of mails.",
	"no_sections_desc_2": "Add a custom section by clicking on the \"Section\" icon",
	"no_shared_with_me": "Looks like no one shared any files with you...",
	"no_smtp_servers": "There is no SMTP server configured. Please do that first and then come back to this page to set up monitoring.",
	"no_subject_available": "-- No Subject Available --",
	"no_trailing_backslash": "Make sure to remove any trailing \"/\"",
	"no_user_or_host_specified": "neither host nor user specified",
	"no_users_found": "No users to assign",
	"no_users_found_long": "It looks like, that there are no users responsible for this mailbox",
	"non_specific_host_id": "General System Errors (no host specified)",
	"none": "None",
	"not_associated_with_a_company": "(No company affiliation)",
	"note": "Note",
	"note_add_label_%s_%s": "%s added the label \"%s\"",
	"note_change_subject_%s_%s_%s": "%s changed the subject from \"%s\" to \"%s\"",
	"note_edit_note_%s": "%s edited the note",
	"note_moved_to_%s_%s": "moved this message from the %s mailbox to the %s mailbox",
	"note_moved_to_label_%s_%s": "%s moved this conversation into \"%s\"",
	"note_remove_label_%s_%s": "%s removed the label \"%s\"",
	"note_remove_note_%s": "%s removed a note",
	"note_to_add": "Add the following note to the conversation",
	"noted": "noted",
	"notes": "Notes",
	"notes_desc": "Notes are internal comments and will never be sent to the customer.",
	"nothing_found": "Nothing found with your search term",
	"nothing_going_on_here": "Nothing going on in this space.",
	"notification_email_thread_%s": "%s just added something new to this conversation.<br><br>Click here to refresh this conversation now!",
	"notification_for_user": "Your update just arrived.",
	"notification_from_%s": "Notification from %s",
	"notification_sign_out": "If you change your <strong>desktop notification</strong> settings above, you will need to sign out and log back in for them to take effect!",
	"notification_sign_out_admin": "Changes to the <strong>desktop notification</strong> settings only take effect when the user signs out and logs back in again!",
	"notifications": "Notifications",
	"notifications_buttons_assigned": "Show assigned to activities",
	"notifications_buttons_delete": "Show delete activities",
	"notifications_buttons_delete_all": "Delete all activities",
	"notifications_buttons_delete_one": "Delete this activity",
	"notifications_buttons_mention": "Show my mentions",
	"notifications_buttons_move": "Show move to another mailbox activities",
	"notifications_buttons_new": "Show new message activities",
	"notifications_buttons_note": "Show note activities",
	"notifications_buttons_refresh": "Refresh list of activities",
	"notifications_buttons_reminder": "Show your reminders",
	"notifications_buttons_reply": "Show reply activities",
	"notifications_buttons_status": "Show status change activities",
	"notifications_collision_%s": "We detected a collision with the recent reply from _%s",
	"notifications_collision_user": "We detected a collision with your reply. Please view the message to resolve the collision.",
	"notifications_desc_remove_after_%s": "Notifications are automatically removed after %s days",
	"notifications_in_mailbox_desc_%s_%s_%s_%s": "<a href='%s' id='notification_item_url'>%s</a>",
	"notifications_mention_header": "mentioned you in the following note",
	"notifications_new_message_from": "New message from",
	"notifications_nothing_found": "You have no notifications. Go take a break. You deserve it.",
	"notifications_number_found_%s": "%s activities",
	"notifications_reminder_text": "You wanted to be reminded of this conversation. Click on this card to see the conversation.",
	"notifications_show_only_new_tooltip": "Show only new notifications",
	"notifications_tracking_text_%s": "The recipient just viewed this message on %s",
	"notifications_user_desc": "Here you can configure how you want to be notified on certain events, e.g. get an email or a desktop notification when a new message arrives",
	"notifications_user_desc_admin": "These are the notifications for this user. The user himself has the option to set them under \"My profile\" (if they have the permission to do so)",
	"notifications_user_wrote_note": "wrote the following note",
	"notifications_user_wrote_reply": "wrote the following reply",
	"notify_me": "Send a notification on...",
	"notify_on": "Notify on...",
	"notify_subscribers": "Notify myself or any other user, regardless of the notification settings",
	"notify_subscribers_regardless_of_notifications": "Select the users of this mailbox that should be notified, regardless of their notification settings",
	"notify_user": "Notify users on...",
	"num_assigned": "#assigned",
	"num_closed": "Number Resolved",
	"num_email_chains": "# Email Chains",
	"num_emails_resolved": "number of resolved emails",
	"num_executions": "count of executions",
	"num_first_responses": "Number of First Replies",
	"num_from_new_addresses": "number of emails from new addresses",
	"num_inbox": "#inbox",
	"num_indexed": "Indexed Conversations",
	"num_list_items": "# List Items",
	"num_mailbox_stats": "number of mailbox stats",
	"num_new_emails": "number of emails",
	"num_reminders": "#reminders",
	"num_responses": "Number of Replies",
	"num_unindexable": "Unindexable Conversations",
	"num_unindexed": "Unindexed Conversations",
	"num_user_mailbox_stats": "number of user mailbox stats",
	"number_of_files": "Number of files",
	"number_responses": "Number Of Replies/Resolutions",
	"of": "of",
	"oh_no": "Oh no",
	"ok": "Ok",
	"ok_continue": "Ok. Continue...",
	"oldest": "Oldest",
	"oldest_first": "Oldest first",
	"on": "on",
	"on_behalf_of": "on behalf of",
	"one_place_for_everything": "One place for everything",
	"only_for_same_domain": "Only applies to mailboxes with the same domain",
	"open_conversation": "Open conversation",
	"open_conversation_in_modal": "Open conversation in a preview window",
	"open_conversation_new_window": "Open conversation in a new window",
	"open_mailbox": "Open mailbox",
	"opened": "Opened",
	"opened_email": "Opened campaign",
	"opened_email_no": "Have not opened campaign",
	"option": "Option",
	"optional_note": "Optional note",
	"options": "Options",
	"or": "or",
	"or enter an email address or domain to match on": "or enter an email address or domain to match on",
	"or_another_workspace": "or another workspace",
	"or_select_following_folder": "Or select one of the following folders",
	"organization_name": "Name of your organization",
	"original": "Original",
	"original_email_headers": "Headers",
	"os": "os",
	"other": "Other",
	"other_files": "Other files",
	"outgoing_email": "Send emails",
	"outgoing_email_desc": "Select to use our default mail servers (there are no limitations in how many emails you can send) or select your SMTP server to send emails to your customers",
	"outgoing_emails": "Outgoing emails",
	"overdue": "Overdue",
	"override_workspace_permissions_desc": "Turn this setting on if you want to override this folder's workspace access settings with this folder's users' and teams' access permissions.",
	"override_workspace_permissions_header": "Override workspace access",
	"overview": "Overview",
	"page_background_color": "Page background color",
	"page_offline": "This page is now offline",
	"page_online": "This page is now published and available online",
	"page_remove": "Remove this page",
	"page_remove_button": "Yes, remove this page",
	"page_remove_desc": "Please confirm that you really want to remove this page?",
	"page_remove_note": "Note that the page will be entirely removed and will be inaccessible. This action cannot be undone.",
	"paid_feature_button": "Let's pay for Helpmonks",
	"paid_feature_desc": "This option is only available with a paid plan.",
	"paid_feature_header": "You found a paid feature",
	"params_received": "Parameters received",
	"parsing_behavior": "Parsing behavior",
	"parsing_info_panel": "Parsing info",
	"password": "Password",
	"password_field_required": "Oops. Looks like you forgot to add a password.",
	"password_length_invalid": "Password must be at least 8 characters",
	"passwords_do_not_match": "The entered passwords do not match.",
	"patch": "Batch actions",
	"pause_campaign_edit": "Pause campaign to edit",
	"pause_temporarily": "Pause temporarily",
	"paused": "Paused",
	"pending": "Pending",
	"pending_%s": "%s changed the status to pending",
	"people_with_access": "People with access",
	"per_user_delete_email": "Users are allowed to delete emails",
	"per_user_manage_customers_and_companies": "Users are allowed to manage customer and company details",
	"per_user_manage_labels": "Users are allowed to manage labels",
	"per_user_manage_notes": "Users are allowed to create and edit their notes",
	"per_user_manage_notifications": "Users are allowed to edit their notification settings",
	"per_user_manage_profiles": "Users are allowed to edit their profiles",
	"per_user_manage_templates": "Users are allowed to manage saved replies",
	"per_user_manage_workflows": "Users are allowed to manage workflows",
	"per_user_see_reports": "Users are allowed to see user reports",
	"per_user_see_reports_desc": "if unchecked, users can only see their own reports",
	"perm_collection_admin": "Collection admin",
	"perm_collection_admin_desc": "Manage collection, members, content, and everything else.",
	"perm_collection_contributor": "Collection contributor",
	"perm_collection_contributor_desc": "Able to add and remove content of collection.",
	"perm_collection_view": "Collection viewer",
	"perm_collection_view_desc": "Can only view collection.",
	"permissions_export_contacts": "Users are allowed to export contacts (including copying the email address)",
	"person_%s_replied_message_given_status_%s_as_per_mailbox_settings": "%s replied and the message was moved to '%s' as per mailbox's settings",
	"person_%s_replying_becomes_assignee_as_per_mailbox_settings": "%s replied and became the assignee, as per the mailbox's settings",
	"person_composing": "Person composing",
	"person_replying": "Person replying",
	"personal_workspace": "Personal workspace",
	"personal_workspace_desc": "This is your personal workspace. Create another workspace to collaborate with others. Alternatively, you can also share a folder.",
	"pin_note_tooltip": "Pin note to the top",
	"pinned": "Pinned",
	"please_contact_admin": "Please contact your administrator if you believe you have reached this in error",
	"please_enter_email_chain_id_of_email_to_execute_workflows_on": "Please enter an email chain id on which to test against it's mailbox's workflows",
	"please_enter_email_chain_id_of_email_to_search_for": "Please enter an email_chain id",
	"please_wait_for_download": "Please wait while we work our magic...",
	"plugin_activation_error_%s_%s": "Error activating %s for %s",
	"plugin_activation_success_%s_%s": "%s has been activated for %s",
	"plugin_deactivation_error_%s_%s": "Error deactivating %s for %s",
	"plugin_deactivation_success_%s_%s": "%s has been deactivated for %s",
	"plugin_detail": "Plugin Detail",
	"plugin_errors_heading": "The following errors were encountered while changing plugin activation status",
	"plugin_install": "Enable Plugin",
	"plugin_name": "Plugin Name",
	"plugin_uninstall": "Disable Plugin",
	"pm": "PM",
	"port_number": "Port Number",
	"preferences": "Preferences",
	"preparing_your_download": "Preparing your download",
	"press": "Press",
	"press_g_g": "Press \"g g\" to get to this menu from anywhere",
	"preview": "Preview",
	"preview_features_off": "We've disabled any preview features for you now",
	"preview_features_on": "Thank you for opting in to try new features. We will notify you once we have something available.",
	"previous": "Previous",
	"primary_email": "Primary email address",
	"print": "Print",
	"print_converation": "Print the entire conversation",
	"print_message": "Print this message",
	"priority": "Priority",
	"private": "Private",
	"private_note": "this is a note to myself",
	"process": "process",
	"process_and_broadcast": "process and broadcast",
	"process_and_broadcast_message": "process and broadcast message",
	"process_background_list": "The list will automatically update when the process is done",
	"process_background_notification": "The change has been pushed to the background.",
	"process_message": "process message",
	"processed_but_not_broadcast": "Not Broadcast (Successfully Processed)",
	"processing_duration": "processing duration",
	"processing_group": "processing group",
	"processing_info_list": "Incoming emails log",
	"processing_info_list_outgoing": "Outgoing emails log",
	"processing_info_overview": "Incoming emails summary",
	"processing_info_overview_outgoing": "Outgoing emails summary",
	"processing_stats": "Processing Stats",
	"profile_avatar_refresh": "Refresh avatar",
	"profile_avatar_refresh_all": "Refresh all avatars",
	"profile_avatar_upload": "Click on the picture above to upload",
	"profile_avatar_use": "Use this picture",
	"profile_avatar_use_initials": "Use initials",
	"profile_detail": "Profile detail",
	"public_domain": "Public Domain",
	"publish": "Publish",
	"publish_news_entry": "Publish entry",
	"published": "Published",
	"publishing": "Publishing",
	"purge_host": "Purge Host",
	"purge_host_desc_modal": "This action is irreversible!  All host data will immediately be removed",
	"purge_host_desc_modal_note": "Purge!",
	"purge_host_description": "Purging the host will permanently remove the host and ALL related data to that host.",
	"purge_host_header": "purge host",
	"push_notifications": "Push Notifications",
	"push_notifications_add": "New notification",
	"push_notifications_add_button": "Add new notification",
	"push_notifications_add_header": "Add a new notification",
	"push_notifications_alert": "Custom Notification",
	"push_notifications_alert_desc": "This notification can be shown at different positions on the user screen and is best used for alerts or important announcements.",
	"push_notifications_chat_notification": "Chat Notification",
	"push_notifications_chat_notification_desc": "This is the traditional chat notification and appears on top of your chat icon. This type is mostly used to invite a user to chat or create an unobtrusive notification.",
	"push_notifications_desc": "Create or edit push notifications here. You can use these to invite a customer to chat or make them aware of a special promotion or news that you want them to see.",
	"push_notifications_enabled_desc": "Only active notifications will show",
	"push_notifications_group_desc": "If you have multiple widgets installed you can define where the notification will show.",
	"push_notifications_group_header": "Notification Group",
	"push_notifications_header": "Your push notifications",
	"push_notifications_name": "Name of notification",
	"push_notifications_new_header": "Start by selecting the type of notification you like to add",
	"push_notifications_remove": "Remove notification",
	"push_notifications_remove_desc": "Please confirm that you want to remove this notification",
	"push_notifications_what_header": "What should this notification do?",
	"push_notifications_when_header": "When should this notification show?",
	"question_field_value": "What do you want to call it?",
	"quick_links": "Quick links",
	"razuna_custom_url_cdn_desc": "Add the subdomain for the CDN",
	"razuna_custom_url_desc": "Run Razuna under your own app domain.",
	"razuna_custom_url_desc_2": "This is your custom URL WITHOUT http, e.g., media.mydomain.com.",
	"razuna_dns_record_desc": "Add the following record(s) to your DNS server",
	"razuna_dns_record_desc_3": "Once you've added the DNS record, click on the \"Verify DNS\" button below. Please note, that some DNS servers take up to 48 hours to propagate your changes.",
	"reactivate": "Reactivate",
	"reactivate_user_note": "Please note that you need to assign the user to mailboxes again!",
	"reactivate_user_prompt_%s": "This action will reactivate the user, allowing them to log into %s.",
	"read": "Read",
	"read_more": "Read more...",
	"ready_to_get_rolling": "Begin forwarding emails",
	"ready_to_see_more": "Ready to see more",
	"reason": "Reason",
	"rebroadcast": "rebroadcast",
	"rebuild_index": "Rebuild index",
	"rebuild_index_desc": "If you feel like the index got out of sync or are instructed by us, you can rebuild the search index",
	"received_at": "Received at",
	"recent_automation": "Recent sequences",
	"recent_campaign": "Recent campaign",
	"recent_files": "Recent files",
	"recent_folders": "Recent folders",
	"recent_workspaces": "Recent workspaces",
	"recently_visited": "Recently visited",
	"recipient": "Recipient",
	"recipients": "Recipients",
	"recipients_and_delivery": "Recipients & Delivery Report",
	"record": "Record",
	"records_found_%s": "%s records found",
	"redirect_on_login": "Redirect on login",
	"redirect_on_login_desc": "Redirecting users to a workspace is a great way to ensure that they are always working in the right environment. It also means that the users do not have a private workspace!",
	"redirect_to_workspace": "Redirect to Workspace",
	"redirect_to_workspace_desc": "Select the workspace you want to redirect users to when they login",
	"refine_your_search": "Refine search",
	"refresh": "Refresh",
	"refresh_days_stats": "Refresh day's stats",
	"refresh_log": "Refresh Action Log",
	"refresh_results_for_content": "refresh results for content",
	"refresh_stats": "Refresh",
	"refresh_stats_lower": "refresh_stats_lower",
	"regarding": "regarding",
	"regenerate_report_data": "Regenerate Report Data",
	"regenerate_report_data_desc_modal": "Regenerating the report stats for the day will first delete all the stats for that day and then regenerate them fresh. Depending on the number of emails, mailboxes and users the report generation can take some time",
	"regenerate_report_data_desc_modal_note": "Regenerate Stats",
	"register": "register",
	"registered": "Registered",
	"reindex_emails": "Reindex Emails",
	"reindex_emails_desc_modal": "Depending on the size and number of emails present this could take a while to complete",
	"reindex_emails_desc_modal_note": "Reindex Emails",
	"reindex_files": "Reindex files",
	"reindex_files_button": "Understood. Reindex files.",
	"reindex_files_confirm": "We have started to reindex your files",
	"reindex_files_desc": "Do you really want to reindex all your files? This can take a long time and until reindexed, navigation and search will not work properly!",
	"reject_access": "Reject request",
	"reject_request_desc": "Do you really want to reject this request for access? The member will not be able to join your team and has to request access again.",
	"rejected": "Rejected",
	"related_announcements": "Related announcements",
	"related_pages": "Related pages",
	"related_roadmap_posts": "Related roadmap posts",
	"remind_me": "Remind me",
	"remind_me_no_reply": "Set when we should remind you when no one replies",
	"remind_when_noone_replies_desc": "By default we will remind you if no one replies to this message within 48 hours",
	"remind_when_noone_replies_header": "Remind me when I don't get a reply",
	"reminder": "Reminder",
	"reminder_for_%s": "Reminder for %s",
	"reminder_remove_on_reply_chk": "Do not remove this reminder when someone replies",
	"reminder_remove_on_reply_false": "Your reminder will be removed when someone replies",
	"reminder_remove_on_reply_true": "Your reminder will not be removed when someone replies",
	"reminder_removed_message_%s_%s": "your reminder, which was set for %s, was removed when a new reply arrived on %s.",
	"reminder_set": "Your reminder has been set successfully",
	"reminder_set_at": "You have a reminder set for",
	"reminder_set_selected": "The reminder has been successfully set for the selected messages",
	"remmove_sending_email": "Remove sending email",
	"remmove_sending_email_button": "Yes. Remove email",
	"remmove_sending_email_desc": "Are you sure that you want to remove this sending email?",
	"remove": "Remove",
	"remove_access": "Remove access",
	"remove_all_attachments": "Remove all attachments",
	"remove_all_labels": "Remove all labels",
	"remove_all_replies": "Remove all replies",
	"remove_all_subscribers": "Remove all subscribers",
	"remove_attachment": "Remove attachment",
	"remove_call_desc": "Are you sure that you want to remove this call log?",
	"remove_call_header": "Remove call log",
	"remove_chat": "Remove chat",
	"remove_chat_button": "Yes, remove chat",
	"remove_chat_desc": "Are you sure that you want to remove this chat now?",
	"remove_co_admin": "Remove Co-Administrator",
	"remove_co_admin_desc": "Are you sure that you want to remove this Co-Administrator? The member will not have access to manage groups and teams anymore.",
	"remove_collection_team": "Remove team from collection",
	"remove_collection_team_desc": "Are you sure that you want to remove this team from this collection? If you do, all members of the team will no longer be able to edit the collection.",
	"remove_collection_user": "Remove member from collection",
	"remove_collection_user_desc": "Are you sure that you want to remove this user from this collection? If you do, the user will no longer be able to edit the collection.",
	"remove_comment_text": "Are you sure that you want to remove this comment? All related comments will also be removed.",
	"remove_company": "Remove company",
	"remove_company_box": "Are you sure that you want to remove this company? The company is only removed from this box.",
	"remove_contact": "Remove contact",
	"remove_contact_box": "Are you sure that you want to remove this contact? The contact is only removed from this box.",
	"remove_folder_team": "Remove team from folder",
	"remove_folder_team_desc": "Are you sure that you want to remove this team from your folder? If you do, all members of the team will no longer access to any files of this folder.",
	"remove_folder_user": "Remove member from folder",
	"remove_folder_user_desc": "Are you sure that you want to remove this user from your folder? If you do, the user will no longer have access to any files of this folder.",
	"remove_from_elastic": "Remove Host from Elasticsearch",
	"remove_from_elastic_desc_modal": "This action fully removes all of the host's data from Elasticsearch. Alternatively select 'Use Default Search' to keep the indexed data in Elasticsearch while the client will use the default search.",
	"remove_from_elastic_desc_modal_note": "Remove Host from Elasticsearch",
	"remove_from_forward": "Remove this message in this forward",
	"remove_group": "Remove group",
	"remove_group_confirm": "Yes, remove group",
	"remove_group_desc": "Please confirm that you want to remove this group",
	"remove_labels": "Remove labels",
	"remove_labels_option": "Remove one or multiple labels from the conversation",
	"remove_labels_option_customers": "Remove one or multiple labels from the customer",
	"remove_list": "Remove list",
	"remove_log_entries": "remove log entries",
	"remove_mail_section": "Remove section",
	"remove_mail_section_desc": "Are you sure you want to remove this section? No emails will get deleted.",
	"remove_mail_tab": "Remove tab",
	"remove_mail_tab_desc": "Are you sure you want to remove this tab? All sections will also be removed. No emails will get deleted.",
	"remove_meeting_desc": "Are you sure that you want to remove this meeting log?",
	"remove_meeting_header": "Remove meeting log",
	"remove_message": "Remove message",
	"remove_message_desc": "Do you want to remove this message from the box?",
	"remove_messages_in_trash_or_spam_%s": "Conversations that have been in %s more than 30 days will be automatically deleted",
	"remove_note_desc": "Are you sure that you want to remove this note?",
	"remove_note_header": "Remove Note",
	"remove_note_text": "Are you sure that you want to remove this note?",
	"remove_option": "Remove",
	"remove_pipeline": "Remove Pipeline",
	"remove_pipeline_desc": "Are you sure that you want to delete this pipeline?",
	"remove_record_desc": "Do you really want to remove this record?",
	"remove_record_header": "Delete record",
	"remove_reminder": "Remove reminder",
	"remove_reminder_desc": "Are you sure that you want to remove this reminder?",
	"remove_saved_reply": "Remove saved reply",
	"remove_saved_reply_desc": "Please confirm that you want to remove this saved reply",
	"remove_selected_subscribers": "Remove selected subscribers",
	"remove_shareable_link_public": "Remove public link",
	"remove_shareable_public_link_notification": "We have removed the public link and disabled sharing",
	"remove_stage": "Delete stage",
	"remove_stage_desc": "Are you sure that you want to delete this stage?",
	"remove_subscriber": "Remove subscriber from list",
	"remove_subscriber_all": "Remove all subscriber",
	"remove_subscriber_all_button": "Yes, remove all",
	"remove_subscriber_all_desc": "Do you really want to remove all subscribers from this distribution list?",
	"remove_subscriber_button": "Yes, remove subscriber",
	"remove_subscriber_confirmation": "Subscribers have been removed",
	"remove_subscriber_desc": "Do you really want to remove this subscriber from this distribution list?",
	"remove_subscriber_selected": "Remove selected subscribers",
	"remove_subscriber_selected_button": "Yes, remove subscribers",
	"remove_subscriber_selected_desc": "Do you really want to remove the selected subscribers from this distribution list?",
	"remove_tag_confirm": "Yep. Remove tag.",
	"remove_tag_desc": "Are you sure that you want to remove this tag?",
	"remove_tag_header": "Remove tag",
	"remove_task": "Remove task",
	"remove_task_desc": "Are you sure that you want to remove this task?",
	"remove_team": "Remove team",
	"remove_team_confirm": "Yes. Remove the team.",
	"remove_team_user": "Remove member from team",
	"remove_team_user_desc": "Are you sure that you want to remove the user from this team?",
	"remove_team_user_note": "The user will immediately be removed as a member of the team and will lose all access for workspaces or folders that are shared with this team.",
	"remove_the_selected_labels": "Select the labels that you want to remove from the conversation",
	"remove_this_message": "Remove this message",
	"remove_this_message_error": "The message could not be removed. Try again or if it happens again, please contact us.",
	"remove_this_message_success": "The message was successfully removed from this conversation",
	"remove_this_message_text": "Are you sure that you want to remove this message?",
	"remove_time_range": "remove time range",
	"remove_trigger": "Remove trigger",
	"remove_trigger_desc": "Please confirm that you want to remove this trigger",
	"remove_user_confirm": "Yes. Remove the user.",
	"remove_user_note": "This action is final and cannot be undone",
	"remove_user_prompt": "Are you sure you want to remove this user?",
	"remove_workspace_team": "Remove team from workspace",
	"remove_workspace_team_desc": "Are you sure that you want to remove the team from this workspace? If you do, the team will no longer have access to any files and folders.",
	"remove_workspace_user": "Remove member from workspace",
	"remove_workspace_user_desc": "Are you sure that you want to remove the user from this workspace? If you do, the user will no longer have access to any files and folders.",
	"rename": "Rename",
	"replace": "Replace",
	"replicate": "Replicate",
	"replied": "replied",
	"replied_%s": "%s replied",
	"replies": "Replies",
	"reply": "Reply",
	"reply_%s": "%s replies",
	"reply_above_line": "Please reply above this line",
	"reply_all": "All replies",
	"reply_one": "1 reply",
	"reply_scheduled": "Reply scheduled",
	"reply_scheduled_desc_%s_%s": "%s composed a reply that is scheduled to be sent on %s",
	"report_download_ready": "Report ready for download",
	"report_email_history_last_days_%s": "Email history of the last %s days",
	"report_num_received": "Number of emails received",
	"report_num_sent": "Number of emails sent",
	"report_total_attachments": "Number of attachments / storage",
	"report_total_attachments_numer_of": "Number of attachments",
	"report_total_attachments_storage": "Size of attachment storage",
	"report_total_average_responses": "Total average replies",
	"report_total_companies_%s": "Number of <a href='%s' class='text-underline text-inherit-color'>companies</a>",
	"report_total_customers_%s": "Number of <a href='%s' class='text-underline text-inherit-color'>contacts</a>",
	"report_total_day_least_replies": "Day with the least replies",
	"report_total_day_most_replies": "Day with the most replies",
	"report_total_emails": "Number of conversations",
	"report_total_emails_from_customers": "Emails from new contacts",
	"report_total_emails_resolved": "Total resolved emails",
	"report_total_emails_sent": "Number of emails sent",
	"report_total_emails_sent_short": "Emails sent",
	"report_total_labels_%s": "Number of <a href='%s' class='text-underline text-inherit-color'>labels</a>",
	"report_total_mailbox_users": "Number of mailbox users",
	"report_total_new": "Total new conversations",
	"report_total_resolution": "Average resolution time",
	"report_total_resolved": "Total resolved",
	"report_total_response": "Average reply time",
	"report_total_response_first": "Average first reply time",
	"report_total_responses": "Total replies",
	"report_total_responses_first": "Total first replies",
	"report_total_users_%s_%s": "Number of <a href='%s' class='text-underline text-inherit-color'>%s users</a>",
	"report_usage_link": "Usage",
	"report_user_total_responses": "User's total replies",
	"report_users_average_responses": "Average replies per day",
	"report_users_average_responses_abr": "Avg. number of replies per day",
	"reports": "Reports",
	"reports_footer_text": "Reports are based on the server location time zone",
	"reports_num_executions": "Number of workflow executions",
	"reports_subheading_1": "Get the inside scoop",
	"reprocess": "reprocess",
	"request_access": "Request access",
	"request_access_advanced_settings": "Request access advanced settings",
	"request_access_assign_permission": "Assign permission to member",
	"request_access_assign_team": "Assign team to member",
	"request_access_automatic_approval": "Approve access immediately",
	"request_access_desc": "To allow users to request access to your Razuna workspaces, turn on the \"request access\" option. Once enabled, users will see a link to the form on the login page to request access. Alternatively, you can directly send the form link to them.",
	"request_access_email_domains": "Restrict email-domains",
	"request_access_form": "Request access form",
	"request_access_form_confirmation": "Request access form confirmation",
	"request_access_form_confirmation_email": "Request access form confirmation email",
	"request_access_settings": "Request access settings",
	"requested": "Requested",
	"requested_on": "Requested on",
	"require_password": "Require a password",
	"required_fields_missing": "Please set a value for the highlighted fields above.",
	"reschedule_to": "Reschedule all to...",
	"resend_invite": "Resend invite",
	"resend_verification_email_to_%s": "Resend the verification email to %s",
	"reset": "Reset",
	"reset_cache_header": "Reset browser cache",
	"reset_counts": "Reset Counts",
	"reset_filter": "Show all",
	"reset_helpmonks_settings_%s": "Reset %s settings",
	"reset_logo": "Reset logo",
	"reset_mailbox_counts": "reset mailbox counts",
	"reset_password_on_button": "The new password will only take effect when you click the button above.",
	"reset_password_success": "We successfully reset your password",
	"reset_password_uuid_not_found": "Unfortunately, it looks like this URL is no longer valid.",
	"reset_search": "Reset search",
	"reset_view": "Reset view",
	"respond_within": "Respond within",
	"response_data": "response_data",
	"response_time": "Reply Time",
	"responses": "Replies",
	"responses_from_team_%s": "<strong>%s Replies</strong> from your team",
	"restore": "Restore",
	"restore_to_inbox": "Restore to Inbox",
	"resync_list_items": "Re-sync List Items",
	"resync_list_items_desc_modal": "Clicking Re-sync List Items will re-sync all list items from email chains. This operation can take a while depending on the number of email chains",
	"resync_list_items_desc_modal_note": "Re-sync List Items",
	"retention_policy": "Retention policy",
	"retention_policy_contacts": "The below policy will apply to all conversations in this mailbox. You can define a retention policy for specific contacts or companies on their detail pages.",
	"retention_policy_desc": "Use a retention policy to automatically delete emails you no longer need or to ensure they are removed per your guidelines.",
	"retention_policy_desc_in_company": "The below policy will apply to all conversations from contacts of this company You can also define a retention policy for specific contacts or for all conversations of a mailbox.",
	"retention_policy_desc_in_contact": "The below policy will apply to all conversations from this contact You can also define a retention policy for specific companies or for all conversations of a mailbox.",
	"retention_policy_notice": "Please note that the retention policy will delete all conversations and associated attachments. This action may result in discrepancies within your reports, as the number of conversations will differ from previous reports.",
	"retention_policy_subheading": "Define email lifecycle",
	"retest_dns": "Retest DNS records for correctness",
	"retry_every": "Retry every...",
	"return_to": "return to",
	"reverse": "reverse",
	"revoke_imap_auth": "Revoke IMAP authorization",
	"revoke_imap_auth_confirm": "Yes, revoke authorization",
	"revoke_imap_auth_desc": "Removing the IMAP authorization will remove and stop any ongoing import tasks immediately. This applies to the import tasks only. Already imported emails will stay in your mailbox.",
	"revoke_invite": "Revoke invite",
	"revoke_invite_desc": "Do you really want to revoke the invitation for this user? The user will not be able to join your team unless you send another invite.",
	"revoked": "Revoked",
	"roadmap": "Roadmap",
	"roadmap_show_kb": "Show roadmap in docs",
	"roadmap_show_kb_desc": "When enabled, your roadmap will show within your Docs site in the top navigation, and also on a dedicated page.",
	"role": "Role",
	"role_admins": "Administrators have full access to all mailboxes, users, settings, etc.",
	"role_sub_admins": "SubAdmin's have full access to the mailboxes they are members of",
	"role_users": "Users have access to the mailbox to read and reply to emails",
	"route_not_activated_for_given_host": "route not activated for given host",
	"routes": "routes",
	"running": "Running",
	"s3_access_key": "S3 Access Key",
	"s3_bucket": "S3 Bucket",
	"s3_cloudfront_name": "Cloudfront DNS name",
	"s3_cloudfront_name_desc": "If the field above is empty, the system takes the standard S3 url",
	"s3_region": "S3 Region",
	"s3_secret_access_key": "S3 Secret Access Key",
	"same_file_check_false": "Disable same file check (allow adding the same file multiple times)",
	"same_file_check_true": "Enable same file check",
	"same_file_desc": "By default, we prevent adding the same file. Turning this off will allow you to store the same file multiple times.",
	"same_file_header": "Same file check",
	"same_file_notification_false": "The same file check is now disabled",
	"same_file_notification_true": "The same file check is now enabled",
	"saturday": "Saturday",
	"save": "Save",
	"save_button_smtp_custom_desc": "Once you've tested your custom SMTP server you will be able to save the configuration.",
	"save_changes": "Save changes",
	"save_for_later": "Save for later",
	"save_reminder_date": "Save my reminder date",
	"save_smtp_settings": "Save my SMTP settings",
	"saved_password": "I've saved the password",
	"saved_replies": "Saved Replies",
	"saved_replies_add": "Add a new reply",
	"saved_replies_delete_group_note": "Any saved reply in this group will be ungrouped",
	"saved_replies_desc": "Add or edit a saved reply below. Saved replies are being used to insert a predefined message and are available in the editor when you reply to a conversation. You can also add dynamic variables which fill in the details in real-time, attach images, and documents.",
	"saved_replies_no_records": "No saved replies here. Create a new one to reply faster.",
	"saved_replies_no_records_in_category": "There are no saved replies in this group",
	"saved_replies_sorted_by_name": "Saved replies are sorted by name",
	"saved_replies_sorted_desc": "Groups and Saved replies are sorted by name. Tip: Drag & drop a saved reply from one Group to another",
	"saved_reply_logs": "saved reply logs",
	"saved_reply_placeholder_name": "Enter a name for the saved reply",
	"saved_reply_subject_desc": "Enter a subject, if you want to change the email subject when using this saved reply. Otherwise, leave empty (default)",
	"schedule_a_meeting": "Schedule a meeting",
	"schedule_cron_invalid": "invalid cron schedule",
	"schedule_send": "Schedule send",
	"schedule_send_cancel_desc": "Please note that the scheduled message has been removed. Send this message now or schedule it at new",
	"schedule_send_cancel_sending_desc": "Do you really want to cancel sending this message?",
	"schedule_send_cancel_sending_note": "Cancelling a scheduled message will put the message back into your Drafts folder",
	"schedule_send_cancel_sending_title": "Cancel scheduled message",
	"schedule_send_confirm": "Confirm sending",
	"schedule_send_delete": "Delete scheduled email",
	"schedule_send_delete_desc": "Are you sure that you want to delete this scheduled email?",
	"schedule_send_delete_selected_desc": "Are you sure that you want to delete the selected scheduled messages?",
	"schedule_send_done_desc": "Your message is now scheduled to be sent on the date and time you've selected.<br><br>Scheduled messages are stored in a dedicated section within the \"All Drafts\" folder.",
	"schedule_send_done_header": "Message scheduled",
	"schedule_send_emails": "Scheduled emails",
	"schedule_send_for": "Schedule send for",
	"schedule_send_for_button": "Schedule message to send later",
	"schedule_send_notification": "The scheduled message has been reverted to a draft",
	"schedule_send_on_date_%s": "Message is scheduled to be sent on %s",
	"schedule_send_saved": "Your email has been successfully scheduled to be sent on <span id='schedule_send_status_desc'></span>",
	"schedule_send_saved_located_%s": "You can find all scheduled emails under <a href='/mailbox/%s/sent'>\"Sent Mail\"</a>",
	"schedule_send_update": "Your email will be sent at the scheduled time",
	"schedule_sending": "Schedule sending",
	"scheduled_for": "Scheduled for",
	"scheduled_message": "Scheduled message",
	"scheduled_messages": "Scheduled messages",
	"scheduled_messages_none": "There are no messages scheduled",
	"scheduled_task_must_have_either_a_cron_schedule_or_run_once_date": "a scheduled task must have either a cron schedule or a run once date",
	"search": "Search",
	"search_background_color": "Search background color",
	"search_background_image": "Search background image",
	"search_bar": "Search bar",
	"search_body": "Search in email body",
	"search_by_contact_or_message": "Search by contact or message",
	"search_conversations_found_%s": "%s conversations found",
	"search_emails": "Search Emails",
	"search_existing_boxes": "Search for existing boxes",
	"search_filter": "Search filter",
	"search_for": "Search for...",
	"search_for_folder": "Type to select folder",
	"search_for_label": "Type to select labels",
	"search_for_mailboxes": "Type to select mailboxes",
	"search_for_status": "Type to select status",
	"search_for_users": "Type to select users",
	"search_history": "Search history",
	"search_in_collect_plus": "Search in Collect+",
	"search_in_workspace": "Search in this workspace",
	"search_include_trash_messages": "Include messages in the Spam and the Trash folder",
	"search_include_trash_messages_adv": "Also, search in Spam and Trash",
	"search_include_trash_messages_no_result": "Maybe the conversations are in the Spam or in the Trash folder?",
	"search_index": "Search index",
	"search_labels": "Search labels",
	"search_mailboxes": "Type to select mailboxes",
	"search_name_or_email": "Search name or email",
	"search_notes": "Search in notes",
	"search_placeholder": "Enter search term",
	"search_placeholder_adv": "Search everywhere",
	"search_results": "Search results for...",
	"search_results_coming": "Search results are on the way...",
	"search_results_header": "Search results",
	"search_results_phrase": "We found the following for",
	"search_saved_reply": "Search for a saved reply",
	"search_show_more": "Show more results",
	"search_subject": "Search in subjects",
	"search_text_adv_desc": "By default we AND multiple words. To search for emails containing any word use an \"OR\" between them, e.g., \"Helpmonks OR demo\". To do a phrase search just wrap your search term in \"\" (quotes), e.g., \"Helpmonks demo\".",
	"search_text_missing": "Please enter a search term",
	"second": "second",
	"seconds": "seconds",
	"seconds_plural": "seconds",
	"section": "Section",
	"section_background_color": "Section background color",
	"section_border_color": "Section border color",
	"section_delete_pages_desc": "You have existing pages in this section and therefore cannot delete this section. If you want to remove this section, please move or remove all the pages first.",
	"section_options": "Section options",
	"security_add_key": "Add my Yubikey",
	"security_add_key_input_desc": "Please insert your Yubikey now into the USB port and then press the key to insert your security token. Once done, please click the button to verify.",
	"security_desc_%s_%s": "Here you can enable Basic Authentication for your %s server. Please add the user and password combination and your users will be prompted <strong>before</strong> they can enter the %s application. Once the user has been authenticated, the value is being stored for two weeks or until the user clears his cookies.<br><br>Note: This is a basic HTTP Authentication mechanism and the user/password combinations here ARE NOT actual users but the credentials to pass the HTTP Authentication! This backend Admin is excluded from authentication!",
	"security_disable_edit_user_security_button": "Disable Two-Step authentication for this user",
	"security_disable_edit_user_security_desc": "Are you sure that you want to disable Two-Step authentication for this user?",
	"security_disable_edit_user_security_note": "Disabling Two-Step authentication cannot be undone and the user needs to re-do verification",
	"security_disable_key": "Remove security key",
	"security_disable_key_button": "I know what I'm doing. Remove this key now",
	"security_disable_key_desc": "Removing hardware security will leave your account less secure and someone just needs your username and password to access your account",
	"security_disable_key_note": "Removing hardware security is not recommended!",
	"security_disable_two_factor": "Disable Two-Step authentication",
	"security_disable_two_factor_button": "I know what I'm doing. Disable Two-Step authentication now",
	"security_disable_two_factor_confirm": "Two-Step authentication is now disabled",
	"security_disable_two_factor_desc": "Disabling Two-Step authentication will leave your account less secure and someone just needs your username and password to access your account",
	"security_disable_two_factor_note": "Disabling Two-Step authentication is not recommended!",
	"security_enable_two_factor": "Enable Two-Step authentication",
	"security_header": "Security Settings",
	"security_two_factor_backup_header": "Backup key",
	"security_two_factor_disabled_desc_%s": "Enabling Two-Step authentication adds an additional layer of security to your %s account. You will need your password and security code to access your account.",
	"security_two_factor_disabled_enable": "Yes, I want to secure my account now",
	"security_two_factor_disabled_header": "Protect your account with Two-Step authentication",
	"security_two_factor_enable_scan": "Please scan the below barcode with your Authenticator app",
	"security_two_factor_enable_verify_code": "Now, enter the code you see in your Authenticator app and click on verify",
	"security_two_factor_enabled": "Two-Step authentication",
	"security_two_factor_enabled_desc": "Please take note of the backup key below which allows you to sign into your account in case you don't have your phone handy. You should probably store this key in secure place, like a password manager or alike",
	"security_two_factor_enabled_header": "Great! You've enabled Two-Step authentication for your account.",
	"security_two_factor_front_end_app_%s": "No hardware key available?<br><a href='%s'>Use your Authenticator app</a>",
	"security_two_factor_front_end_desc": "Open your authentication app to enter the code to log in",
	"security_two_factor_front_end_desc_%s": "Open your authentication app and enter the code for %s",
	"security_two_factor_front_end_desc_backup": "Enter your backup code",
	"security_two_factor_front_end_forgot_%s": "Can't access your authentication app?<br><a href='%s'>Enter your backup code to log in</a>",
	"security_two_factor_front_end_header": "Two-factor authentication",
	"security_two_factor_front_end_header_backup": "Two-factor backup",
	"security_two_factor_front_end_key_%s": "Want to use your hardware key instead?<br><a href='%s'>Use your hardware key</a>",
	"security_two_factor_front_end_key_desc": "Insert your Yubikey into the USB port and press it to enter the security token in the field below",
	"security_two_factor_front_end_placeholder": "Enter 6 digit code",
	"security_two_factor_front_end_placeholder_backup": "Enter 36 character backup code",
	"see_example": "See example",
	"see_host_details": "host details",
	"see_how_helpmonks_works_%s_%s_%s": "Send an email to <a href='mailto:%s' target='_blank' class='dont-break-out'>%s</a> to see your message arrive in your shared mailbox.",
	"see_log": "See log",
	"select": "Select",
	"select from known companies, users or customers": "select from known companies, users or customers",
	"select_a_company_option": "Start typing to select a company",
	"select_a_condition": "Select a condition",
	"select_a_sla": "None",
	"select_a_variable_to_insert": "select a variable to insert",
	"select_action": "Select an action to add to your workflow",
	"select_action_filter": "Select action to filter",
	"select_all": "Select all",
	"select_all_conversations": "Select all conversations in this view",
	"select_all_conversations_%s": "Select all %s conversations",
	"select_all_conversations_selected": "All conversations in this view have now been selected",
	"select_all_mailboxes": "Select all mailboxes",
	"select_an_action": "Select an action",
	"select_another_user": "Select another user",
	"select_assignee_to_not_react_to": "The email conversation is NOT currently assigned to one of the following team members",
	"select_assignee_to_react_to": "The email is currently assigned to one of the following team members",
	"select_cc_value_to_match": "Enter the exact email address to match in the CC of the email",
	"select_color": "Select a color",
	"select_company": "Select company",
	"select_condition": "Select a condition to add to your workflow",
	"select_contact": "Select contact",
	"select_contacts": "Select contact(s)",
	"select_date": "Select a date",
	"select_date_and_time": "Select a date and time",
	"select_default_folder": "Or select a default folders",
	"select_deselect_all": "Select/Deselect all",
	"select_doc_site": "Select doc site",
	"select_doc_site_desc": "Please select which doc site you want to use for your Knowledge-base, Feedback, and Announcements. Only one doc site can be used per widget. However, you can create another group for another doc site.",
	"select_field_teams": "Select team(s)",
	"select_field_users": "Select user(s)",
	"select_label_color": "Select a color for this label",
	"select_labels": "Select labels",
	"select_language": "Select language",
	"select_list": "Select list",
	"select_mailbox": "Select a Mailbox",
	"select_multiple_emails": "Tip: To select multiple emails hold down the shift key and click the checkboxes",
	"select_saved_reply": "Select a Saved Reply",
	"select_segment": "Trigger",
	"select_sla": "Select an SLA for this company",
	"select_sla_to_react_to": "The incoming message is from a user of a company associated with one of the following service level agreements",
	"select_stage": "Select stage",
	"select_status": "Select status",
	"select_status_to_not_react_to": "The email conversation does NOT have one of the following statuses",
	"select_status_to_react_to": "The email has one of the following statuses",
	"select_tag": "Select a tag",
	"select_template": "Select template",
	"select_time_ago_customer": "The previous customer's reply to the email was",
	"select_time_ago_internal": "The last internal reply to the email was",
	"select_time_range": "Select a time range",
	"select_to_add_mailbox": "Tip: Select several members to add to one or many mailboxes",
	"select_to_add_mailbox_confirm": "The selected user(s) have been added to the mailbox(es)",
	"select_type_filter": "Select type to filter",
	"select_user": "Select user",
	"select_users": "Select users",
	"select_users_to_add": "Select users to add",
	"select_workspace": "Select workspace",
	"selected_have_been": "The selected conversations have been",
	"send": "Send",
	"send_a_message": "Send a message",
	"send_campaign_now": "Send campaign now",
	"send_check_to": "Send result of checks to...",
	"send_find_in_sent_mail": "You can find the emails in \"Sent Mail\"",
	"send_first_message": "1. Send your first message",
	"send_for": "Send checks only on...",
	"send_for_error": "Alert only on errors",
	"send_for_everything": "Everything (very verbose)",
	"send_from": "Send from",
	"send_from_mailbox": "Send emails with the external mailbox name in the FROM (replies will go to the mailbox email address)",
	"send_from_user": "Send emails with the username in the FROM (replies will go to the mailbox email address)",
	"send_from_user_direct": "Send from the primary email address of the user (replies will go to the users email address)",
	"send_later": "Send later",
	"send_message_to_companies": "Send a message to one or multiple companies",
	"send_message_to_team": "Send a message to your team",
	"send_saved_reply": "send saved reply",
	"send_saved_reply_option": "Send a saved reply / auto reply to the sender of the email",
	"send_test_email": "Send test email",
	"send_the_selected_saved_reply": "Send an automatic reply to the sender by selecting one of your \"Saved Replies\"",
	"send_to_company": "Message company",
	"send_to_recipient": "Enter or select recipient",
	"send_to_users": "Message customer",
	"send_to_users_%s": "Message team",
	"send_via": "Send via...",
	"send_welcome_email": "Send a \"Welcome Email\"",
	"send_welcome_email_desc": "If enabled, will send the \"Welcome Email\" to the user containing the information you have entered in the email template (usually the login URL and other useful details).",
	"send_with_custom_server": "... my SMTP server",
	"send_with_custom_server_desc": "Send all emails with your SMTP Server by adding your settings below.<br><br>Before you enable your SMTP server, please make sure that the test email went out successfully. Once enabled, all outgoing emails will be sent with your SMTP server.<br><br>Upon sending, we check if your SMTP server is online and able to send messages, if not we will reset the SMTP server to ours and inform you. This is to make sure that your conversations still get delivered.<br><br>Please note, that we do not take any responsibility for unsent messages or delivery issues as the responsibility lies within your SMTP server",
	"send_with_default_server_%s": "... %s SMTP servers",
	"send_with_default_server_sendgrid_%s": "... %s SMTP servers powered by Sendgrid",
	"send_with_sendgrid_desc": "We are pleased to offer SMTP servers powered by Sendgrid for your account. Our Sendgrid SMTP servers offer you the best delivery rate.",
	"send_with_sendgrid_dns_desc": "However, before you can use these SMTP servers you are required to create the below SPF and DKIM records in your DNS server. Only verified records, will be able to use these servers. Please note that you are required to keep these DNS records as long as you use your account.",
	"send_with_sendgrid_enabled": "Everything is properly configured and you are all set for sending your emails with our SMTP servers powered by Sendgrid.",
	"send_with_sendgrid_header_%s": "Send emails with the %s SMTP servers powered by Sendgrid",
	"sendgrid_button_domain_add": "I've created the records in my DNS",
	"sendgrid_button_domain_add_error": "Oops. Something is wrong. Please try again. If it still doesn't work, contact us please.",
	"sendgrid_button_domain_add_feedback": "Great. Now, check your DNS records.",
	"sendgrid_button_domain_add_retry": "There was an issue with the activation. However, we could fix it in the meantime. Please click that button again.",
	"sendgrid_button_domain_check": "Check your DNS entries",
	"sending_identities": "Email addresses for sending",
	"sending_on": "Sending on",
	"sending_to": "Sending to",
	"sendmonks_dashboard_desc": "Welcome to your email campaign dashboard",
	"sent": "sent",
	"sent_by": "Sent by",
	"sent_by_me": "Sent by me",
	"sent_campaigns": "Sent campaigns",
	"sent_on": "Sent on",
	"sent_to": "Sent to",
	"sent_to_list": "Sent to list",
	"seo_description": "Page description",
	"seo_description_desc": "Describe the content of the page. This is what will show underneath a search results. Hence use this to increase your SEO ranking and provide visitors a valuable description. This text shouldn't be longer than 157 chars.",
	"seo_settings": "SEO settings",
	"seo_title": "Page title",
	"seo_title_desc": "The title is what will show in the browser tab. Best practice is to use the same value as your page name. However, you might want to change this to target certain keywords of this page. This text shouldn't be longer than 70 chars.",
	"sequences": "Sequences",
	"sequences_delete": "Remove Sequences",
	"sequences_delete_desc": "Please confirm that you want to remove this sequence",
	"server": "Server",
	"server_name": "Name for server",
	"service_level_agreement": "Service Level Agreement",
	"ses_email_verify_desc": "We've sent a verification email to your email address. Please follow the steps in that email. You will not be able to send any campaigns with this email address until you verify your email address.",
	"ses_mailbox_new_verify": "Important: Verify your email",
	"ses_mailbox_new_verify_desc": "We've sent a verification email to your email address. Please follow the steps in that email. You will not be able to send emails from this mailbox until you verify your email address.",
	"ses_verification_failure_desc": "Unfortunately, we are not able to verify your email address properly. Please try again or contact us.",
	"ses_verification_failure_header": "Something is wrong",
	"ses_verification_success_desc": "You can now use this email address to send emails from your mailbox to others.",
	"ses_verification_success_header": "We successfully verified your email address",
	"session_clear_all": "Clear All Sessions",
	"session_clear_filter": "Clear Search Filter",
	"session_expiration_date": "Expires on",
	"session_host_name": "Host Name",
	"session_remove_body": "Are you sure you want to remove this session?",
	"session_remove_body_all": "Are you sure you want to remove all sessions?",
	"session_remove_header": "Remove Session",
	"session_remove_note": "Note: This will remove ALL sessions in the session store. This might take a long time and your browser window might appear to have frozen. Do NOT refresh the page!",
	"session_remove_submit": "Remove",
	"session_search": "Search",
	"session_settings_desc": "Define the timeout for user sessions and cookies. Once the timeout is reached, your users will automatically be signed out of the system and have to sign in again.",
	"session_settings_header": "User session settings",
	"session_user_email": "Email",
	"session_user_name": "User Name",
	"sessions_desc": "All users with a logged in sessions are listed below. By removing a session, the user is immediately signed out.",
	"sessions_header": "Sessions",
	"sessions_remove_all": "Remove all user sessions",
	"set": "Set",
	"set_action_after_assignee": "After assigning the conversation, I want to...",
	"set_action_after_assignee_desc": "Sets the default navigation after you assign a conversation.",
	"set_action_after_complete": "After completing the conversation, I want to...",
	"set_action_after_complete_desc": "Sets the default navigation after you complete a conversation.",
	"set_action_after_reminder": "After adding a reminder to the conversation, I want to ...",
	"set_action_after_reminder_desc": "Sets the default navigation after you set a reminder on a conversation.",
	"set_action_after_sending": "After sending a reply or saving a note, I want to...",
	"set_action_after_sending_desc": "Sets the default navigation after you send a reply or save a note on a conversation.",
	"set_action_after_sending_list": "Go back to the list page",
	"set_action_after_sending_next": "Go to the next conversation",
	"set_action_after_sending_note": "For notes",
	"set_action_after_sending_reply": "For replies",
	"set_action_after_sending_setting": "Change default setting",
	"set_action_after_sending_stay": "Stay on the page",
	"set_action_after_status": "After changing the status of the conversation, i want to...",
	"set_action_after_status_desc": "Sets the default navigation after you change the status on a conversation.",
	"set_action_after_trash": "After removing the conversation, I want to...",
	"set_action_after_trash_desc": "Sets the default navigation after you delete a conversation.",
	"set_date": "Set date",
	"set_labels": "Add labels",
	"set_password_confirmation": "Your new password has been successfully set.",
	"set_quote_level": "How many replies and notes to include in outgoing email",
	"set_quote_level_desc_%s": "Defines how many replies (including notes and actions) you want to see in the email notification. This is only for internal emails that you receive from %s.",
	"set_quote_level_mailbox": "Quoted replies",
	"set_quote_level_mailbox_desc": "Select the number of quoted replies to be included in replies to customers. This is independent of the chosen template above.",
	"set_reminder": "Select a reminder",
	"set_reminder_option": "Set a reminder for all users of the mailbox for the conversation",
	"set_reply_to": "Use as reply-to address",
	"set_the_to_address_to_%s": "set the reply to address of this email to %s",
	"set_time": "Set time",
	"set_timezone": "Please set the time zone you are in",
	"set_timezone_of_user": "Please set the time zone the user is in",
	"set_to_hide": "Set to hide",
	"set_to_show": "Set to show",
	"setting_applies_to_all_workspace_members": "This setting applies to all workspace members",
	"settings": "Settings",
	"settings_comm_content_desc": "Use this page to manage words to be recognized as spam on unverified accounts.",
	"settings_comm_news_desc": "Any news article here will show up in the news section on the front-end. Upon publishing a new entry the user will get a notification, i.e., will see a small notification in the main navigation.",
	"settings_lower": "settings",
	"settings_user_desc": "Create and edit users for this host",
	"setup_new_mailbox_header_desc": "Your mailbox needs a name and email address. Then you're all set!</br>Don't worry, you can always change the name or email address of your mailbox later.",
	"setup_not_recommended": "Setup not recommended",
	"setup_not_recommended_desc": "Configuring a section with no mailbox, label, status, or assignee will fetch all messages and can take a long time and is generally not recommended.",
	"share_collection": "Share collection",
	"share_collection_desc": "Please select the member(s) or team(s) you want to collaborate with in this collection.",
	"share_email_link_create_desc": "A public link allows you to share this conversation publicly with others. Only messages will be visible.",
	"share_email_link_create_header": "Create a shareable public link",
	"share_email_link_header": "Share with a public link",
	"share_email_link_subline": "Share the link above with others for message-only access. They won't be able to modify your emails.",
	"share_file": "Share file",
	"share_folder": "Share folder",
	"share_folder_desc": "Please select the member(s) or team(s) you would like to grant access to this folder.",
	"share_folder_with": "Share this folder with",
	"share_url": "Share a link to this conversation",
	"share_url_desc": "This is a private link and anyone accessing this link will need to have access to your Helpmonks account",
	"share_workspace": "Share workspace",
	"share_workspace_desc": "Select the user or team you want to share this workspace with.",
	"share_workspace_with": "Share this workspace with",
	"shareable_collection_links_desc": "Create one or several links to this collection that you can share with others.",
	"shareable_file_links_desc": "Create one or many links to this file that you can share with others.",
	"shareable_file_links_header": "Shareable file links",
	"shareable_folder_links_desc": "Create one or many links to this folder that you can share with others.",
	"shareable_folder_links_header": "Shareable folder links",
	"shareable_public_link": "Shareable public link",
	"shared_by": "Shared by",
	"shared_folder_with_team": "Yay! Congrats for sharing. We informed your team members that they have access.",
	"shared_folder_with_user": "Yay! Congrats for sharing. We informed the user that they have access.",
	"shared_with_me": "Shared with me",
	"shared_with_you": "Shared with you",
	"short_link": "Short link",
	"show": "show",
	"show_action": "Show statuses",
	"show_activities": "Show activities",
	"show_all": "Show all",
	"show_all_contacts": "Show all contacts",
	"show_all_of_user": "Show all conversations of user",
	"show_all_pending_emails": "Show all pending conversations",
	"show_all_users": "Show all users",
	"show_announcements": "Show announcements",
	"show_archived": "Show archived",
	"show_as_folder": "Show as folder",
	"show_company_in_list_main_desc": "If enabled (default) it will show the company the user belongs to",
	"show_company_in_list_main_header": "Show company in email list",
	"show_dns_records": "Show DNS info",
	"show_doc_search_panel": "Show knowledge base search panel",
	"show_emails_assigned_to_%s": "Show emails assigned to %s",
	"show_full_report": "Show full report",
	"show_headers": "Show headers",
	"show_hidden_users": "Show hidden contacts",
	"show_in_label_explorer": "Show in Label-Explorer",
	"show_label_in_mailboxes": "Show label in these mailboxes...",
	"show_me": "Show me",
	"show_message": "Show messages",
	"show_mine_and_group": "Show mine and this group together",
	"show_more": "Show more",
	"show_next_status_%s": "Show next message in %s",
	"show_note": "Show notes",
	"show_notification_badge": "Show notification counts",
	"show_notification_badge_desc": "if \"Show notification counts\" is enabled, the user will see a count of unread notifications in the main navigation.",
	"show_notification_badge_desc_user": "If \"Show notification counts\" is enabled, you will see a count of unread notifications in the main navigation.",
	"show_only_new": "Show only new",
	"show_previous_status_%s": "Show previous message in %s",
	"show_read": "Show answered messages",
	"show_request_access": "Show request access",
	"show_search_filter": "Show search filter",
	"show_shortid_desc": "Each email conversation has a unique short ID. Some might refer to it as a \"Ticket Number\". You can search by this ID and also directly link to it with a convenient short URL. If enabled, the short ID will show in the email list. The short ID is always visible on the message detail page.",
	"show_shortid_header": "Show the short ID (Ticket Number) in email list",
	"show_simple_filter": "Show simple filter",
	"show_suspicious_users": "Show contacts marked as spam",
	"show_today": "Show messages from today",
	"show_unread": "Show unanswered messages",
	"show_up_to": "Show up to",
	"show_uppercase": "Show",
	"show_user": "Show user",
	"show_users": "Show users",
	"show_verified_users": "Show verified contacts",
	"show_with_attachments": "Show messages with attachments",
	"show_with_notes": "Show messages with notes",
	"show_with_replies": "Show messages with replies",
	"show_with_replies_customers": "Show messages with customer replies",
	"show_with_replies_users": "Show messages with user replies",
	"show_yesterday": "Show messages from yesterday",
	"show_your_mailboxes": "Show your mailboxes",
	"sign_in": "Log in",
	"sign_in_method": "Sign-in Method",
	"sign_in_with_imap": "Sign in with my IMAP provider",
	"signature": "Signature",
	"signature_desc": "This is the default signature for your mailbox. This signature is only being used if the user(s) did not configure a signature themselves. You can design your signature below, including adding images. Please note that remote images should be loaded over HTTPS. Tip: You can switch to the \"code view\" to copy & paste or edit HTML directly.",
	"signin_header": "Sign in",
	"since": "Since",
	"site_background_color": "Site background color",
	"site_links_color": "Site links color",
	"size": "size",
	"sla": "SLA",
	"sla_customer": "SLA Customer",
	"sla_logs": "SLA logs",
	"sla_overdue_header": "SLA not met, response overdue!",
	"smtp": "SMTP",
	"smtp_default_desc": "We run a network of SMTP servers and by default your outgoing emails will use these servers.",
	"smtp_default_desc_sendgrid": "In addition, you also have the option to switch to our SMTP servers that are powered by Sendgrid.",
	"smtp_desc_1": "Your mailbox email address is verified with our SMTP servers and you are using one of the best delivery email services in the business.",
	"smtp_desc_2": "If you want, or need to, you can change to another SMTP service below. Messages to your customers will then use your custom SMTP server (notifications are still being sent over our SMTP server).",
	"smtp_desc_3": "Please note, that this is for sending emails only. If you want to synchronize outgoing emails back to your mail provider you have to set up \"IMAP sync\" (click on the second tab above).",
	"smtp_for_customers": "Select SMTP server for sending emails",
	"smtp_for_customers_desc": "Please select the SMTP server you want to use for sending messages to your customers. Note: This should only be used if you know what you are doing.",
	"smtp_switch_to_default": "Switch to default SMTP",
	"smtp_test_desc": "Please enter the TO and FROM address to test your SMTP server",
	"smtp_test_header": "Test your SMTP server",
	"sort_by": "Sort by",
	"sort_by_%s": "Sort by %s",
	"sort_by_create_date": "Sort by created date",
	"sort_by_due_date": "Sort by due date",
	"sort_by_no_due_date": "Sort by no due date",
	"sort_manually": "Manual sort order",
	"spam_%s": "%s marked this message as spam",
	"spam_arrival_message": "Email has been identified as spam, and moved to the spam folder",
	"spam_filter_desc": "To block all emails from a domain you can either enter *@spamdomain.com or *.spamdomain.com. In addition, you can also filter emails from subdomains, e.g., if you want to filter the email address spammer@spam.subdomain.com you would create a rule with @*.subdomain.com (this also works for multiple subdomains!)",
	"spam_folder_empty": "Your email inbox is free from those pesky spammers who bombard you with useless messages about winning lotteries and whatnot. Looks like you're one of the lucky ones!",
	"spam_score_value": "Spam score value",
	"specific_day": "A specific day",
	"spellchecker_autocorrect": "Auto-Correct",
	"spellchecker_autocorrect_desc": "Enable auto-correction as you type",
	"spellchecker_behaviour": "Spellchecker options",
	"spellchecker_behaviour_desc": "Select the options you like to enable in the spellchecker",
	"spellchecker_enablegrammar": "Grammar checking",
	"spellchecker_enablegrammar_desc": "Enable grammar check as you type",
	"spellchecker_feedback_updated": "Spellchecker settings have been successfully updated",
	"spellchecker_ignoreallcapswords": "All-Caps words",
	"spellchecker_ignoreallcapswords_desc": "Ignore all words written capitalized, e.g., \"AWESOME\"",
	"spellchecker_ignoredomainnames": "Domain names",
	"spellchecker_ignoredomainnames_desc": "Ignore domain name, e.g., yourdomain.com will not be checked",
	"spellchecker_ignorewordswithmixedcases": "Mixed cases words",
	"spellchecker_ignorewordswithmixedcases_desc": "Ignore words with mixed cases, e.g., \"AweSome\"",
	"spellchecker_ignorewordswithnumbers": "Words with numbers",
	"spellchecker_ignorewordswithnumbers_desc": "Ignore words with numbers, e.g., \"awes0m3\"",
	"spellchecker_language": "Select your language",
	"spellchecker_language_desc": "Select the language the spellchecker will default to",
	"spellchecker_nav": "Spellchecker",
	"spellchecker_proofreadasyoutype": "Enable Spellchecker",
	"spellchecker_proofreadasyoutype_desc": "Enable the spellchecker as you type",
	"spf_configure_header": "Please add the following string to your existing SPF record",
	"spf_configured": "SPF record is configured",
	"spf_current_record": "Your current SPF record",
	"spf_desc": "Take the value below and create a TXT record in your DNS. If you already have a SPF record, add the value to the existing one. A domain should only ever have ONE SPF record (a SPF record can have multiple values)!",
	"spf_dkim_desc": "We highly recommend that you configure a SPF DNS records to help with email delivery. Below is the status of your DNS records.",
	"spf_never_checked": "We've never checked your SPF record. Please use the button here to run a test now.",
	"spf_not_configured": "SPF record is not configured",
	"spf_record": "We recommend that you configure a SPF DNS record to help with email delivery. Please see our <a href='https://helpmonks.com/kb/support/p/adding-an-spf-record-to-help-with-email-delivery' target='_blank'>documentation on the SPF record</a>.",
	"spf_record_desc_%s": "Adding a SPF DNS record to your DNS will help with email delivery. Nowadays, many organizations check the SPF record to make sure that the email originates from a valid mail server. If you do not configure a SPF record, %s is still able to send your email. However, it can be that your email never arrives at the recipients mail server or is automatically flagged as spam. <br>Please see our <a href='https://helpmonks.com/kb/support/p/adding-an-spf-record-to-help-with-email-delivery' target='_blank'>how-to guide on configuring your SPF record</a>.",
	"split_email_chains": "Send individual messages",
	"split_email_chains_desc": "If you select this, it will send to each recipient an individual message. Please note that replies will come in as individual messages as well.",
	"split_horizontal": "Horizontal split",
	"split_no": "No split",
	"split_vertical": "Vertical split",
	"ssl_ready": "SSL published",
	"sso_token_desc": "Generate a SSO key to use in your redirects to your authentication page.",
	"sso_token_header": "SSO Key",
	"sso_url_desc": "Enter the entire URL to your authentication page. We will send any login request to this URL with the signed token.",
	"sso_url_header": "SSO authentication URL",
	"stage": "Stage",
	"star_mailbox": "By starring this mailbox it will appear at the top of this page and the mailbox list",
	"starred_mailboxes": "Starred mailboxes",
	"start_date": "Start date",
	"start_date_must_come_before_end_date": "The SLA start date must come before the end date",
	"start_time": "start time",
	"start_typing": "Tip: Start typing to add email address(es). For a new address simply enter it and HIT ENTER!",
	"started_processing_at": "Started processing at",
	"started_thread": "started this conversation",
	"started_thread_%s": "%s started this conversation",
	"starting_download": "Download is starting...",
	"statistics": "Statistics",
	"status": "Status",
	"status_broadcast": "Status: Broadcasted (emails that were processed and send out)",
	"status_changed_to_%s": "The status of this conversation has been changed to \"%s\".",
	"status_changed_to_completed": "The status of this conversation has been changed to \"Completed\" (closed and marked as answered).",
	"status_changed_to_many_%s": "The status of the selected conversations have been changed to \"%s\".",
	"status_collision": "Collision detected",
	"status_collision_desc": "Below you will find messages that were detected as collisions, i.e. someone from your team wrote already a message",
	"status_collision_emails": "Email collisions",
	"status_collision_emails_desc": "These are collisions that we detected when you sent an email with your email client",
	"status_collision_internal": "Reply collisions",
	"status_collision_internal_desc_%s": "These are collisions that we detected when you composed a reply in %s",
	"status_collision_reply": "is composing a reply right now",
	"status_collision_reply_%s": "%s is composing a reply right now",
	"status_collision_view": "is viewing this message right now",
	"status_collision_view_%s": "%s is viewing this message right now",
	"status_complete": "Status: Completed",
	"status_copy_to_mailbox_category_error": "There was an error with copying the Group. Please try again. If it happens again, please let us know.",
	"status_copy_to_mailbox_category_success": "The Group has been copied to the selected mailbox",
	"status_copy_to_mailbox_error": "There was an error with copying the selected records. Please try again. If it happens again, please let us know.",
	"status_copy_to_mailbox_success": "The selected records have been copied to the selected mailbox",
	"status_deferred": "Status: Deferred",
	"status_error": "Status: Error (emails that could not be processed)",
	"status_incoming_summary_lead": "Below, find a summary of recent activities below. Please note that the summary only shows the most recent seven emails.",
	"status_loop_email": "Status: Loop (emails that were caught in a loop filter, e.g. auto-replies, endless loops, etc.)",
	"status_no_mailbox": "Status: No Mailbox (emails that could not be assigned to any mailbox)",
	"status_saved_not_broadcast": "Status: Saved (emails that were processed but not send out)",
	"status_see_all_link": "Show all (reset group or filter)",
	"status_success": "Status: Success",
	"status_unprocessed": "Status: Unprocessed (emails waiting to be processed)",
	"statuses": "Statuses",
	"stay_up_to_date": "Stay up-to-date",
	"stopped": "Stopped",
	"storage": "Storage",
	"storage_local": "Store on local file system",
	"storage_s3": "Store on S3 compatible service",
	"storage_used": "Storage used",
	"sub_admin": "Sub-Admin",
	"sub_admins": "Sub-Admins",
	"sub_directory": "Subdirectory",
	"sub_domain": "Your subdomain (without https://)",
	"sub_domain_header": "Subdomain",
	"subfolder": "Subfolder",
	"subheading": "Subheading",
	"subheading_desc": "An optional subheading that shows underneath the heading. HTML is allowed.",
	"subject": "Subject",
	"subject_lower": "subject",
	"subject_only": "subject only",
	"subject_or_body": "subject or body",
	"submit": "Submit",
	"submit_button": "Submit button",
	"submit_button_text": "Submit button text",
	"submit_confirm_header_desc": "Submit confirmation description",
	"submit_confirm_header_text": "Submit confirmation header",
	"submit_forgot_password": "Reset Password",
	"subscriber": "Subscriber",
	"subscriber_activities": "Subscriber activities",
	"subscribers": "Subscribers",
	"subscribers_only_%s": "Trigger for %s users",
	"subscribers_only_desc": "Trigger workflow when a message arrives from a team member or composing or forwarding an email.",
	"success_follow_box": "You will receive a notification when there is an update for this box",
	"success_save": "Your changes have been saved successfully",
	"success_unfollow_box": "You will no longer receive a notification from this box",
	"successfully_indexed": "successfully indexed",
	"successfully_saved": "Successfully saved",
	"successfully_sent": "successfully sent",
	"successfully_sent_option": "Sent successfully",
	"summaries": "Summaries",
	"summary": "Summary",
	"summary_of_assigned_emails": "Summary of assigned conversations",
	"summary_of_pending_emails": "Summary of pending conversations",
	"sunday": "Sunday",
	"support": "Support",
	"support_desc": "If you have an idea or a feedback for us, please do let us know. We love to hear from you!",
	"suppress_notifications": "Block notifications for this message, for the selected users (overriding any notification settings)",
	"suppress_notifications_regardless_of_notifications": "Select users for which to block notifications for this message",
	"suspicious_contact_desc": "This email address appears invalid. It failed email verification, was marked as spam, or is hidden.",
	"suspicious_contact_header": "Contact identified as spam",
	"suspicious_contact_safe": "Looks safe",
	"suspicious_domains": "Suspicious domains",
	"switch_group": "Switch Group",
	"switch_smtp": "Switch to this SMTP server",
	"switch_to": "Switch to...",
	"switch_to_automation": "Go to Email Sequences",
	"switch_to_email_campaigns": "Go to Campaigns",
	"switch_to_smtp_%s": "Use %s SMTP for sending",
	"sync_enabled": "Enable the syncing of outgoing emails",
	"sync_established": "Sync established",
	"sync_established_desc": "We could successfully establish a connection to your mailbox. From here on, we will sync all outgoing messages to your mail server.",
	"sync_mail_server": "Mail server credentials",
	"sync_mail_server_desc": "Please enter your mail server credentials below",
	"sync_mail_server_folder": "Folder for outgoing messages",
	"sync_mail_server_folder_desc": "Please select the folder on your mail server to store outgoing messages",
	"sync_mail_server_folder_help": "In order to change the folder please click on \"Test mail server connection\" which will fetch the latest folders from your mail server",
	"sync_mail_server_test_button": "Test mail server connection",
	"sync_mail_server_type": "Type of mail server",
	"sync_mail_server_type_desc": "Please select from the known type of mail servers below. If your mail server is not listed, simply select \"Generic\". <strong>Note: This only works with a compatible IMAP server!</strong>",
	"sys_admin_email": "System Admin's email address",
	"system_information": "System Information",
	"system_settings_host_settings": "Host settings",
	"system_settings_hosted_desc": "Define the default values for new hosts, e.g. user and mailbox limitation",
	"system_settings_hosted_header": "Set the account limitations for hosts",
	"system_settings_hosted_link_type": "How to display",
	"system_settings_hosted_link_type_desc": "Select how to display the account page.",
	"system_settings_hosted_max_daily_emails": "How many emails in 24 hours",
	"system_settings_hosted_max_mailboxes": "How many mailboxes",
	"system_settings_hosted_max_users": "How many users",
	"system_settings_hosted_unimited": "Setting \"0\" (zero) will allow an unlimited amount",
	"system_settings_hosted_url_upgrade_page": "URL to call for account",
	"system_settings_hosted_url_upgrade_page_desc": "Enter the URL to redirect the user for his account. The request will contain the host_id parameter!",
	"system_status": "Status",
	"system_status_desc_%s": "Live status updates on %s",
	"tab_backup_restore": "Backup & Restore",
	"tab_customization": "Customization",
	"tab_email_templates": "Email templates",
	"tab_general": "General",
	"tab_groups": "Workgroups",
	"tab_groups_desc": "To create Workgroups, first create one or more user groups, and add your chosen users. Then create a Workgroup, and choose which mailbox(es) the Workgroup has access to. Last, associate your one or more user groups to the Workgroup which gives users of the associated user groups access to the Workgroup's mailbox(es).",
	"tab_ldap": "LDAP/AD",
	"tab_logs": "Logs",
	"tab_maintenance": "Maintenance",
	"tab_mb_is_syncing_email_eng_desc_%s_%s": "New messages and replies composed in %s are always stored in %s. Whether or not you use your own SMTP server your new emails and replies are not stored in your \"Sent\" folder. You've authorized us with your provider to sync emails created and sent in %s.",
	"tab_mb_set_connection": "SMTP & Sync",
	"tab_mb_set_connection_desc": "SMTP settings",
	"tab_mb_set_permissions": "Permissions",
	"tab_mb_set_sync": "Sync outgoing emails",
	"tab_mb_set_sync_desc_%s_%s_%s_%s": "New messages and replies composed in %s are stored in %s. Even when you send all your email with your SMTP-server, those emails are not stored in your \"Sent\" folder. However, on this page, you can configure %s to store a copy of all outgoing messages on your mail server.<br><br>P.S.: If you rather want to send each outgoing message to a BCC address, then please <a href='/mailbox/settings/workflows/%s'>head over to your workflows</a>.<br><br><span>This only works with a compatible IMAP server. Please read <a href='https://helpmonks.com/kb/support/p/sync-outgoing-messages-automatically-to-your-imap-server' target='_blank'>our IMAP sync guide</a>.</span>",
	"tab_mb_set_sync_email_eng_desc_%s": "New messages and replies composed in %s are always stored in %s. Whether or not you use your own SMTP server your new emails and replies are not stored in your \"Sent\" folder. However, you can configure syncing of your outgoing emails by authenticating with %s using the link below.",
	"tab_name": "What should the tab be called?",
	"tab_options": "Tab options",
	"tab_scheduled_tasks": "Scheduled Tasks",
	"tab_services": "Services",
	"tab_slas": "Service Level Agreements",
	"tab_spam": "Spam Settings",
	"tab_templates_import": "Import & Export",
	"tab_templates_rendition": "Renditions",
	"tab_templates_watermark": "Watermarks",
	"tab_users": "Users",
	"tag_directions": "tag_directions",
	"tag_no_files_found": "It looks like there are no files with this tag",
	"tags": "Tags",
	"target_display_desc": "Show your message only to visitors on the following URLs. If you don't enter a URL it will show on any page.",
	"target_display_end_desc": "Set when your message should be marked as seen, i.e., not display to your visitor anymore.",
	"target_display_if_desc": "Show your message on to visitors if they match one or more specific conditions. By default, it will no apply any condition.",
	"target_display_when_desc": "Set how often you want to show your message to your visitors. Optionally set a condition, e.g., time spent on the page or when the user exists the page.",
	"targeting": "Targeting",
	"task": "Task",
	"task_add_comment_button": "Add comment",
	"task_add_email_button": "Add new To-Do",
	"task_add_email_tooltip": "To-Dos of this conversation",
	"task_all_conversation": "To-Dos of this conversation",
	"task_comment_header": "Comment",
	"task_header": "To-Dos",
	"task_new": "New To-Do",
	"task_new_conversation": "Add a new To-Do to conversation",
	"task_remove_button": "Yes, remove the To-Do",
	"task_remove_comment_button": "Yes, remove the comment",
	"task_remove_comment_header": "Remove Comment",
	"task_remove_comment_text": "Are you sure that you want to remove the comment from the To-Do?",
	"task_remove_header": "Remove To-Do",
	"task_remove_text": "Are you sure that you want to remove this To-Do?",
	"task_update_comment_button": "Update comment",
	"tasks": "Tasks",
	"team_add_remove_user": "Add or remove member from team(s)",
	"team_added_to_collection": "Yay! The team has been added to the collection",
	"team_sent_messages_%s": "You and your team sent %s messages today",
	"team_sent_no_messages": "No messages have been sent yet",
	"team_sent_one_message": "You and your team sent one message today",
	"teams": "Teams",
	"teams_links": "Manage Workgroups",
	"teams_with_access": "Teams with access",
	"template": "Template",
	"template_preview": "Template preview",
	"template_remove_desc": "Please confirm that you want to remove this template",
	"template_remove_header": "Remove template",
	"test_connection": "Test connection",
	"test_desktop_notification": "Test desktop notification",
	"test_email_desc": "Enter the email address(es) to send a test email to",
	"test_mail_servers": "Monitor the following Mail-Server(s)",
	"test_smtp_servers": "Monitor the following SMTP Server(s)",
	"test_spf_dkim_settings_%s": "Test %s settings",
	"text": "Text",
	"text_to_match": "text to match",
	"the_last_seven_days_so_far": "Your team activities over the last seven days",
	"theme_dark": "Dark Theme",
	"theme_light": "Light Theme",
	"these_folder_members": "These folder members",
	"this_could_take_a_while": "Depending on the size and number of emails present this could take a while to complete",
	"this_group": "This group",
	"this_is_mailbox_setting_too": "Please note: If you enable this setting, it will apply to all mailboxes, unless the setting is configured differently within the \"Mailbox Settings\" itself.",
	"this_language": "English",
	"this_language_code": "en",
	"this_week_so_far": "Here's how this week looks so far",
	"this_will_remove_all_emails_from_elasticsearch": "This is a full remove of the host from elasticsearch, and permanently removes all indexed emails",
	"thumbnail": "Thumbnail",
	"thursday": "Thursday",
	"time_format": "Time Format",
	"time_settings": "Time Settings",
	"time_since_reply_to_customer": "Trigger based on time",
	"time_since_reply_to_customer_desc": "Trigger workflow based on how long the customer has been waiting for a reply from your team",
	"timezone": "Time zone",
	"timezone_admin_desc": "Set the time zone, your server is in. This time zone is being used as default if there is no time zone set within the hosts and the mailboxes.",
	"timezone_compose": "Show time zone upon reply and new messages",
	"timezone_compose_desc": "If enabled, this will show a time zone drop-downmenu when composing emails, allowing the user to change the time zone for external emails for that conversation",
	"timezone_email_list_desc_%s": "Time zone of customer emails is set to %s",
	"timezone_ext_emails": "Set time zone for sending",
	"timezone_ext_emails_desc": "By default, all emails back to the sender are formatted in the sender's time zone. However, you can choose to send emails back to the sender formatted in the mailbox's or user's time zone instead.",
	"timezone_hostsettings_desc": "Set the global default time zone. All users and mailboxes, unless individually changed, will use the time zone set here.",
	"timezone_listview": "Show time zone of external emails in email list",
	"timezone_listview_desc": "If enabled, this will show the time zone used when sending external emails",
	"timezone_mailbox_desc_%s_%s": "Choose the default time zone for external emails sent from this mailbox. ** Please note, dates and times displayed within %s are always shown in your chosen time zone setting of %s.",
	"timezone_modal_desc": "Change time zone to",
	"timezone_modal_header": "Time zone change detected",
	"timezone_settings_desc": "Find below all relevant time zone settings that apply to this mailbox only",
	"tip_company_creation": "Tip: Companies are automatically created for you if there are more than one user with the same email domain",
	"title": "Title",
	"to": "To",
	"to_customers": "To customers",
	"to_date_%s": "to date %s",
	"to_lowercase": "to",
	"to_reply": "to reply",
	"to_team_members": "To team members",
	"today": "Today",
	"todo_active": "Active To-Dos",
	"todo_active_link": "Show active To-Dos",
	"todo_active_not_found": "There are no active To-dos for this contact",
	"todo_add": "Add this To-Do",
	"todo_archived_header": "Showing archived To-Dos only",
	"todo_archived_link": "Archived To-Dos",
	"todo_archived_link_expanded": "Show archived To-Dos",
	"todo_archived_tooltip": "Toggle between showing archived and active To-Dos",
	"todo_assigned_by_%s": "%s assigned this To-Do to you",
	"todo_assignee": "Assign the To-Do or leave empty...",
	"todo_associate_external_users_placeholder": "Associate customer(s) with this To-Do...",
	"todo_buttons_refresh": "Refresh To-Do list",
	"todo_category": "Add the To-Do to a list or leave empty...",
	"todo_comment_by_%s": "%s left a comment",
	"todo_comments_all": "Show all To-Do comments",
	"todo_comments_header": "To-Do Comments",
	"todo_comments_new": "Add a To-Do first to add comments",
	"todo_comments_none": "No comments here. Add a comment or edit the To-Do",
	"todo_completed": "Completed To-Dos",
	"todo_conversation_header": "To-dos for this conversation",
	"todo_conversation_not_found": "There are no To-dos for this conversation",
	"todo_created_by_%s": "%s created this To-Do",
	"todo_delete": "Delete this To-Do",
	"todo_done": "The To-Do has been marked as completed",
	"todo_done_by_%s": "%s completed this To-Do",
	"todo_edit": "Edit To-Do",
	"todo_expand": "Open To-Do",
	"todo_group_delete": "Delete this group",
	"todo_group_delete_note_todo": "If you delete this group, any To-Dos in the group will not be removed, but default back to no group",
	"todo_group_delete_text": "Are you sure that you want to delete this group?",
	"todo_history_not_found": "There is no To-do history for this contact",
	"todo_lists_header": "Your To-Do lists",
	"todo_mark_completed": "Mark completed",
	"todo_mark_completed_detail": "The To-Do has been successfully completed",
	"todo_mark_completed_tooltip": "Will mark this To-Do as completed and move it to the archived section",
	"todo_marked_desc": "The To-Do has been marked as completed.",
	"todo_new_tooltip": "Add a new To-Do",
	"todo_nothing": "Looks like there are no To-Dos for this conversation",
	"todo_nothing_archived": "There are no archived To-Dos for this conversation",
	"todo_nothing_found": "Looks like you have no To-Dos left in here",
	"todo_nothing_found_for_email": "There are no To-Dos for this email conversation<br><br><a href='#' onclick='createToDo();' class='btn btn-primary'>Create one now</a><br><span class='font-size-15 text-muted'>(the email will be associated with your new To-Do automatically)</span>",
	"todo_notification_%s": "To-Do assigned to you by %s",
	"todo_notification_new": "The To-Do was successfully created. If assigned or added to a list you will now find it there...",
	"todo_notification_updated": "This To-Do was successfully updated",
	"todo_notify_external_users_placeholder": "Notify customer(s) when To-Do is done...",
	"todo_notify_users": "Notify users",
	"todo_notify_users_external": "Notify customer(s)",
	"todo_notify_users_placeholder": "Notify users when To-Do is done or there are comments...",
	"todo_number_found_%s": "%s To-Dos",
	"todo_open": "Open the To-Do",
	"todo_open_modal": "Open To-Dos",
	"todo_private": "Private To-Do",
	"todo_private_desc": "Only you can see this To-Do",
	"todo_reopen": "Reopen To-Do",
	"todo_reopen_by_%s": "%s re-opened this To-Do",
	"todo_reopen_text": "The To-Do has been reopened",
	"todo_search_placeholder": "Search To-Dos...",
	"todo_title_placeholder": "Describe your To-Do...",
	"todo_upcoming_schedule_desc": "To-Dos that are due or overdue are shown here. Tip: Drag & drop to sort or to reschedule",
	"todo_update_header": "Update the To-Do",
	"todo_updated_by_%s": "%s updated this To-Do",
	"todo_user_found": "Active To-Dos associated with this contact",
	"todo_user_not_found": "There are no To-dos associated with this contact",
	"todo_who_gets_notified": "The user who created this To-Do, any assignee, and selected users will get notified when this To-Do is done or when a comment is added according to the users notification settings",
	"todos_emails": "Email To-Dos",
	"todos_emails_desc": "Below are all the emails with active To-Dos. Click on the subject to see all associated To-Dos",
	"todos_emails_nothing": "Looks like there are no To-Dos that are associated with any conversation (yet)",
	"todos_emails_nothing_desc": "To-Dos for teams is a powerful feature that you can use to manage your workflow. You can create a To-Do for yourself or assign a task to a team member. Furthermore, To-Dos are linked to an email conversation and a contact automatically.",
	"todos_emails_nothing_desc_2": "Create a To-Do right within an email or click on the To-Do icon in the top navigation for more To-Do actions.",
	"todos_emails_nothing_header": "No conversation To-Dos",
	"todos_private": "Private To-Dos",
	"toggle": "Toggle",
	"tomorrow": "Tomorrow",
	"tomorrow_afternoon": "Tomorrow afternoon",
	"tomorrow_morning": "Tomorrow morning",
	"tooltip_assignee": "Assign the conversation(s)",
	"tooltip_bookmark": "Bookmark conversation(s)",
	"tooltip_disable_workflow": "Click to disable workflow",
	"tooltip_enable_workflow": "Click to enable workflow",
	"tooltip_filter": "Group or filter messages",
	"tooltip_forward": "Forward this conversation",
	"tooltip_label": "Add one or many labels to the conversation(s)",
	"tooltip_label_folders": "Move the conversation(s) into another folder",
	"tooltip_move": "Move conversation(s) to another mailbox",
	"tooltip_next_week": "Next week (Monday at 8 am)",
	"tooltip_note": "Create a note",
	"tooltip_reminder": "Set a reminder",
	"tooltip_reply": "Compose a reply",
	"tooltip_restore": "Restore the selected conversations to the Inbox",
	"tooltip_search": "Enter a search term and hit enter",
	"tooltip_select": "Select or de-select conversations",
	"tooltip_select_customers": "Select or de-select customers",
	"tooltip_spf_dkim_not_valid": "Looks like your SPF or DKIM record is not fully configured. Click here to learn more.",
	"tooltip_status": "Change the status",
	"tooltip_todo": "Create a new To-Do for the conversation(s)",
	"tooltip_tomorrow_afternoon": "Tomorrow afternoon at 3 pm",
	"tooltip_tomorrow_morning": "Tomorrow morning at 8 am",
	"tooltip_trash": "Delete conversation(s)",
	"tooltip_trash_in_trash": "Delete all conversation in trash forever",
	"tooltip_trash_in_trash_select": "Delete all the selected conversations in trash forever",
	"tooltip_user_link": "Open the user record in a new window",
	"total": "Total",
	"total_activities": "Total activities",
	"total_approved": "Total approved",
	"total_assigned_emails": "Total assigned emails",
	"total_conversations": "Total conversations",
	"total_customer_replies": "Total customer replies",
	"total_messages": "Total messages",
	"total_rejected": "Total rejected",
	"total_resolved_emails": "Total resolved emails",
	"total_user_replies": "Total user replies",
	"totals": "Summary",
	"Totals": "Totals",
	"tracked_seen": "Seen",
	"tracking_desc": "Open tracking works with embedding an image in your HTML emails. Please note that this method is being actively blocked by most email providers in the recent years. Though, enabling tracking shouldn't hurt your delivery score.",
	"tracking_log": "Tracking",
	"tracking_main_desc": "When you enable open tracking it will allow you to see if your recipients have opened your sent messages.",
	"tracking_main_desc_more": "Once your recipients have viewed your message you will see this in the list view and in the detail view. In addition, you will also get a desktop notification at the time the user views your message.",
	"tracking_main_header": "Enable open tracking",
	"tracking_main_note_%s": "Open tracking only works with HTML emails. As %s only sends HTML emails you should have quite a high positive rate with the tracking, though.",
	"trash_%s": "%s moved this message to the trash",
	"trash_email_header": "Trash selected emails",
	"trash_email_note": "Note: This will move the conversation to the trash.",
	"trash_email_text": "Are you sure that you want to trash this conversation?",
	"trash_empty_button": "Yes, empty trash now",
	"trash_empty_header": "Empty trash now",
	"trash_empty_text": "Are you sure that you want to empty the trash?",
	"trash_file": "Move to trash",
	"trash_selected_emails_button": "Yes, remove",
	"trash_selected_emails_forever_button": "Yes, remove forever",
	"trash_selected_emails_forever_header": "Delete forever",
	"trash_selected_emails_forever_note": "Note: This action is irreversible!",
	"trash_selected_emails_forever_text": "Are you sure that you want to delete the selected email conversations forever?",
	"trash_selected_emails_header": "Trash selected emails",
	"trash_selected_emails_note": "Note: This will move the conversation(s) to the trash.",
	"trash_selected_emails_text": "Are you sure that you want to trash the selected conversation(s)?",
	"trial_exp_date": "Trial expiration date",
	"Trial_Exp_Date": "Trial exp date",
	"trial_expired_button": "See my plan options",
	"trial_expired_contact_admin_%s": "",
	"trial_expired_contact_admin_%s_%s_%s": "If you believe you've reached this page in error<br>please contact your account administrator<br>%s at<br><a href=\"mailto:%s\" __target='blank'>%s</a><br>to check the status of your account.",
	"trial_expired_desc_%s": "But no worries, you can get your account up and running again by selecting a plan to begin your %s subscription.",
	"trial_expired_desc_%s_%s": "Your trial has already expired. But no worries, you can get %s up and running again by simply selecting a plan and entering your billing information to continue your monthly subscription. If you need more time, just contact us as %s.",
	"trial_expired_desc_ps": "PS: All your data will remain intact and available for 1 month after your trial expiration date.",
	"trial_expired_header": "Your trial has expired!",
	"trial_expired_offer_%s_%s": "If you still need more time to decide, just contact us at <a href=\"mailto:%s\" __target='blank'>%s</a>.",
	"trial_expired_subject_%s": "%s Trial has Expired",
	"triggers_for": "Triggers for",
	"trusted_table_header": "IP/DNS name",
	"tt_collection_private": "Set this collection to be private. This is only possible with a paid account.",
	"tuesday": "Tuesday",
	"turn_off": "Turn off",
	"turn_off_collision_detection": "Do not use collision detection",
	"two_factor_enabled": "Your account is safe and secure with Two-Factor authentication.",
	"two_factor_recommendation": "We recommend to enable Two-Factor authentication for your account to make it more secure.",
	"type_for_folder": "Type for folder",
	"type_to_filter": "Type to filter...",
	"type_to_search": "Start typing to search",
	"type_to_select": "Start typing to select",
	"type_to_select_folder": "Start typing to select a folder",
	"unable_to_find_email_chain_with_id": "Unable to find an email chain with id",
	"unanswered": "unanswered",
	"unarchive": "Unarchive",
	"unassign": "Unassign",
	"unassigned": "Unassigned",
	"unassigned_lowercase": "unassigned",
	"unassociable_domain_error_%s": "The following domains cannot be associated with a company : %s",
	"understood": "Understood",
	"ungrouped": "Ungrouped",
	"units": "Units",
	"unknown": "Unknown",
	"unknown_user": "unknown user",
	"unprocessed": "Not Processed",
	"unread": "Unread",
	"unsubscribe_already": "You are already unsubscribed from this list",
	"unsubscribe_button": "Unsubscribe",
	"unsubscribe_confirmation": "You are now unsubscribed from this list",
	"unsubscribe_list_not_found": "Unfortunately, we cannot find the subscription list",
	"unsubscribe_user_not_found": "Unfortunately, we cannot find you in our database",
	"unsubscribed": "Unsubscribed",
	"unsubscribed_users": "Unsubscribed users",
	"upcoming": "Upcoming",
	"update": "Save changes",
	"update_activation_state": "Update activation state",
	"update_keywords": "Update keywords",
	"update_option": "Update",
	"update_report_button": "Update report",
	"update_results": "update results",
	"update_search_results": "Update Search Results",
	"update_setting": "Update settings",
	"update_to_%s": "Update to %s",
	"updated": "Updated",
	"upgrade": "Upgrade",
	"upgrade_files_more_collections": "Need more collections?",
	"upgrade_files_more_collections_desc": "Upgrade to a paid plan to get unlimited collections. Alternatively, if you are a member of a paid workspace, switch to that workspace to enjoy unlimited collections.",
	"upgrade_files_more_links": "Need more shareable links?",
	"upgrade_files_more_links_desc": "Upgrade to a paid plan to get unlimited shareable links and more advanced features.",
	"upgrade_header": "Looks like it's time to upgrade",
	"upgrade_header_desc_%s": "Your current %s account limit is reached. Click on the link below to explore what to do next.",
	"upgrade_link": "Let me see my options",
	"upload": "Upload",
	"upload_demo_files": "Add demo files",
	"upload_region": "Upload location",
	"upload_to": "Upload into",
	"url": "url",
	"url_open_in": "Open URL in new window/tab",
	"usage_history": "Your usage history",
	"usage_history_over_days_%s": "Your usage history over the last %s days",
	"usage_terms": "Usage terms",
	"use_default_search": "Use Default Search",
	"use_elasticsearch": "Use ElasticSearch",
	"use_email_as_name_matches": "Incoming customer email parse settings",
	"use_email_as_name_matches_desc_%s_%s": "%s extracts the name from a given sender info. For generic names such as 'Ebay Member' or 'A System User' it may make more sense to use the email address to identify the sender. In the textbox below, add any identifiers to tell %s to use the email address instead of the name when adding customers. Values must be a minimum of 3 characters.",
	"use_ssl": "Use SSL",
	"use_template": "Use template",
	"use_this_template": "Use this template",
	"used": "used",
	"used_as_archive_desc": "Setting the mailbox to act as an archive will prevent all emails from being sent when processing incoming emails. This guarantees that there are no recipients of emails or notifications of processed emails. The mailbox will act as an archive, for collecting and organizing emails it receives.",
	"used_as_archive_header": "Use Mailbox as Archive",
	"used_in_collection": "Used in collection(s)",
	"user": "User",
	"user_activities": "User Activities",
	"user_activity": "User Activity",
	"user_activity_over_days_%s": "User activity over the last %s days",
	"user_activity_over_months_%s": "User activity over the last %s months",
	"user_add_view": "Add User",
	"user_added_file_to_comment": "updated the comments with a file",
	"user_added_new_comment": "added a new comment just now.",
	"user_added_to_collection": "Yay! The user has been added to the collection",
	"user_and_host": "user and host info",
	"user_assigned_to_another_user_%s": "You assigned this conversation to %s.",
	"user_assigned_to_another_user_selected_%s": "You assigned the selected conversations to %s.",
	"user_assigned_to_self_%s": "%s assigned this conversation to themselves",
	"user_assigned_to_user_%s_%s": "%s assigned this conversation to %s",
	"user_details": "Client user details",
	"user_edit_desc": "Sub-description",
	"user_edit_group_tip": "Tip: Users who are Administrators can automatically access all mailboxes, however they will only receive notifications from the checked mailboxes.",
	"user_edit_view": "Edit User",
	"user_group_button_new": "Add a user group",
	"user_group_button_save": "Save this user group",
	"user_group_description": "Description for this user group",
	"user_group_name": "Name of this user group",
	"user_groups": "User groups",
	"user_logs": "user logs",
	"user_lower": "user",
	"user_mailbox_access_false": "The access to the mailbox has been revoked",
	"user_mailbox_access_true": "This user has now access to the mailbox",
	"user_mailbox_permission": "This user has access to and will receive communications from the checked mailboxes",
	"user_name": "User name",
	"user_name_contains": "user name contains",
	"user_not_in_mailbox": "User inactive or not in this mailbox",
	"user_notification_warning": "Changing the notifications for the mailbox <strong>does not have an effect</strong> on the individual user notification settings. If you have access you can <a href='/settings/users'>change the notification settings for your users</a>.",
	"user_panel_header": "Info",
	"user_permissions": "User Permissions",
	"user_permissions_desc": "Define what permissions \"Users\" members will have. This applies to all mailboxes.",
	"user_report": "User report",
	"user_reset_sessions_warning": "Please note that users will NOT have access to modified settings and mailboxes until they sign out of the system and back in. Alternatively, you can also <a href='/settings/general/maintenance'>force a refresh of the user sessions</a>.",
	"user_settings_permissions": "User Settings Permissions",
	"user_sign_out_desc": "Please note that your changes will only apply once your users sign out of the system. Alternatively, you can also remove the user sessions.",
	"users": "Users",
	"users_and_groups": "Users & Groups",
	"users_and_teams": "Users & Teams",
	"users_list": "Users",
	"users_no_accept_invite": "Users that have yet to accept your invitation",
	"users_to_add_to_%s": "Users to be added to %s",
	"users_to_add_to_mailbox": "Users to be added to your mailbox",
	"users_with_access": "Users with access",
	"uuid": "uuid",
	"valid": "valid",
	"valid_for_24_hours": "Please note any verification email sent will be valid for 24 hours",
	"validation_data_type_failed_%s_%s": "%s must be of type %s",
	"validation_is_required_%s": "%s is required",
	"validation_range_failed_both_%s_%s_%s": "%s must be greater than %s and less than %s",
	"validation_range_failed_greater_than_%s_%s": "%s must be greater than %s",
	"validation_range_failed_less_than_%s_%s": "%s must be less than %s",
	"validation_regex_failed_%s": "%s does not match the specified format",
	"validation_selectable_values_failed_%s_%s": "%s must come from the following values: [ %s ]",
	"verification_desc_%s_%s": "It looks like you haven't verified %s for sending yet. To do so, click on the verification link that we have sent to %s.",
	"verification_desc_2": "Please note that emails will NOT get delivered until you verify your email address. If you're an Administrator head over to the Mailbox SMTP settings now to fix it.",
	"verification_email_was_sent_to_%s_%s": "A verification email with subject <strong>'%s'</strong>, was sent to %s.",
	"verification_required": "Verification required",
	"verification_resend": "Resend verification email",
	"verification_resend_button": "Thanks. Will do.",
	"verification_resend_desc": "We've resent the confirmation email to your mailbox email address. Please check and click on the link within that email.",
	"verification_step_%s": "Please open this email and verify your mailbox to activate it in %s.",
	"verify": "Verify",
	"verify_dns": "Verify DNS",
	"verify_email_address": "Just a moment while we verify your email address",
	"verify_password": "Verify Password",
	"verify_password_help": "Enter password again",
	"verify_process_confirm": "We started with the verification process and will notify you when done",
	"verify_process_done": "We verified all recipients of your campaign now. Those found to be invalid have been marked as such.",
	"verify_recipients": "Verify recipients",
	"verify_recipients_desc": "We will verify all recipients of your email sequence now. If we find invalid recipients we will mark the sequence as completed for that user.",
	"verify_recipients_desc_2": "Please note that we will do a thorough verification of each email address. This can take some time. We will notify you when the process is done.",
	"verify_your_mailbox": "Verify your mailbox",
	"verify_your_mailbox_steps_%s_%s": "We've sent a verification email to %s. Please look for an email with subject '%s'. Click on the green verification button in this email to activate your mailbox.",
	"version": "Version",
	"version_update_desc_%s": "A new version of %s is available.",
	"version_update_link": "Click here to update",
	"videos": "Videos",
	"view": "View",
	"view_all": "View all",
	"view_all_reports": "View all reports",
	"view_all_updates": "View all updates",
	"view_and_download": "View and download",
	"view_and_download_and_upload": "View, download, and upload",
	"view_and_download_and_upload_desc": "View, download, and upload files",
	"view_change_log": "Changelog",
	"view_collection": "View collection",
	"view_enhanced": "Statistic mailbox overview",
	"view_folder_files_only": "View folder and files only.",
	"view_is_filtered": "However, this being a filtered view, we still show you the emails here, independent of the location.",
	"view_mappings": "View Mappings",
	"view_message": "View message",
	"view_normal": "Normal mailbox overview",
	"view_only": "View only",
	"view_options": "View options",
	"view_setting": "View my settings",
	"view_simple": "Small mailbox overview",
	"view_template": "View template",
	"views": "Views",
	"visitors_analytics": "Visitors Analytics",
	"visitors_in_real_time": "Visitors in Real-time",
	"wait": "Wait",
	"want_to_test_first": "Send your first message",
	"warning_option": "Warning",
	"website": "Website",
	"wednesday": "Wednesday",
	"week_before_last": "The week before last",
	"week_starting": "week starting",
	"welcome": "Welcome",
	"welcome_back": "Welcome back",
	"welcome_desc_%s_%s": "It's great having you here. Let's get you set up so you can enjoy the benefits of your %s account.",
	"welcome_desc_no_mailboxes_%s": "%s works with shared mailboxes. Your administrator will first need to setup some mailboxes and add you as a subscriber before you can use the system.",
	"welcome_desc_sub": "Your first step is to setup a mailbox in Helpmonks. Let's do that right now.",
	"welcome_email_template_name": "Welcome Email",
	"welcome_mailbox_address": "Enter the email address of the mailbox",
	"welcome_mailbox_address_button": "Create your mailbox",
	"welcome_mailbox_name": "Give your mailbox a meaningful name",
	"welcome_to_helpmonks_%s": "Welcome to %s",
	"wf_enter_cc_bcc": "Enter a CC or BCC address to add them to all outgoing emails",
	"wf_enter_forward_to_addresses": "Enter one or more email addresses to receive a forwarded copy of the message",
	"wf_mark_read": "Mark the conversation as answered or unanswered",
	"wf_send_to_bcc": "Add BCC email addresses",
	"wf_send_to_cc": "Add CC email addresses",
	"what": "What",
	"what_to_do_message": "What do you want to do with this message?",
	"whats_new": "Here's what's new",
	"whats_next": "What's next?",
	"when": "When",
	"when_an_email_has_forward_headers": "When an incoming email has forward headers",
	"when_message_is_from": "The incoming message is from one of the following companies or users",
	"when_message_is_not_from": "The incoming message is NOT from any of the the following companies or users",
	"where_to_go_%s": "Hi %s. Where do you want to go?",
	"wheretogonext_desc": "Set \"Where to go next\" after an action. The \"Where to go next\" settings will only apply within a conversation",
	"wheretogonext_header": "Where to go next",
	"white_label_desc": "Customize how you and your team access Helpmonks by using a custom URL, logo, login screen, and more.",
	"white_label_status_verified": "Your DNS record is verified and working. We are requesting a custom SSL certificate for your domain. This should only take a couple of hours. You will receive an email once the certificate is ready.",
	"white_label_status_waiting": "Your DNS record could not be verified. Please make sure that you have entered the correct DNS record. It can take up to 48 hours for the DNS record to be propagated. If you are sure that you have entered the correct DNS record, please contact support.",
	"whitelist": "Allowed email-addresses or domains",
	"who_has_access": "Who has access",
	"who_is_using_sla": "Which companies are using this SLA",
	"will_receive_campaign": "will receive this campaign",
	"with": "With",
	"within_seven_seconds": "within seven seconds",
	"wl_logo_precedence": "This logo takes precedence over the logo defined under customization. ",
	"wording": "Wording",
	"work_faster_with": "Work faster with",
	"work_group_button_new": "Add a Workgroup",
	"work_group_button_save": "Save this Workgroup",
	"work_group_description": "Description for this Workgroup",
	"work_group_name": "Name of Workgroup",
	"work_group_notifications": "Workgroup notifications",
	"work_group_user_group_selection": "Select the user groups that should belong in this Workgroup",
	"workflow": "Workflow",
	"workflow_activity": "Workflow Activity",
	"workflow_admin_test_note": "Running the workflows will not make any changes to the email_chain, nor communicate to users, it is informational only",
	"workflow_executes_on": "Select when and for which user segment your workflow should  trigger",
	"workflow_is_applied_when": "Select one or multiple conditions which must be met to apply this workflow",
	"workflow_logs": "Workflow logs",
	"workflow_mark_as_read_unread": "Mark the conversation as answered or unanswered",
	"workflow_name": "Workflow Name",
	"workflow_no_conditions_desc": "<strong>Not selecting any condition</strong>, will apply the workflow actions to all conversations, when the workflow is triggered",
	"workflow_select_placeholder_text": "start typing to filter values",
	"workflows": "workflows",
	"workflows_cap": "Workflows / Rules",
	"workflows_desc": "<p>Workflows are a powerful tool to automate your email workload as they allow you to execute actions depending on conditions. For example, you can create a workflow that will execute when no one replies to a message or when the message arrives within a certain time frame.</p><p>Please note, that Workflows only execute when a message comes into your mailbox.</p><p>Tip: Use the buttons below to copy workflows between your mailboxes</p>",
	"workgroup": "Workgroup",
	"workgroup_lower": "workgroup",
	"workspace": "Workspace",
	"workspace_admin": "Workspace admin",
	"workspace_admin_desc": "Manage workspace content, members, settings, and invite new members.",
	"workspace_contributor": "Workspace contributor",
	"workspace_contributor_desc": "Add and edit files only.",
	"workspace_editor": "Workspace editor",
	"workspace_editor_desc": "Add, edit, move, and delete files and folders.",
	"workspace_members": "Members of this workspace",
	"workspace_members_no_accept_invite": "Workspace members that have yet to accept your invitation",
	"workspace_shared_with_you_%s": "The %s workspace is now shared with you.",
	"workspace_unshared_with_you_%s": "The %s workspace is no longer shared with you.",
	"workspace_users_subline": "Workspace users & teams with access",
	"workspace_viewer": "Workspace viewer",
	"workspaces": "Workspaces",
	"workspaces_and_users": "Workspaces & members",
	"written_by": "Written by",
	"years": "Years",
	"yesterday": "Yesterday",
	"you": "You",
	"you_are_missing_out": "You are missing out",
	"you_are_not_authorized": "You are not authorized to view this page",
	"you_are_not_authorized_group": "You are not authorized to access this group",
	"you_are_not_authorized_mailbox": "You are not authorized to access this mailbox",
	"you_can_undo": "You can undo this",
	"you_got_mentioned": "You got mentioned",
	"you_noted": "You noted",
	"you_set_reminder_tooltip": "You set a reminder for this message",
	"your_api_log": "Your API Logs",
	"your_campaigns": "Your campaigns",
	"your_co_administators": "Your Co-Administrators",
	"your_collections": "My collections",
	"your_drafts": "Your drafts",
	"your_favorites": "My favorites",
	"your_forwarding_address": "Your Helpmonks Forwarding Address",
	"your_name": "Your name",
	"your_new_token": "Your access token",
	"your_new_token_desc": "Here is your access token. Please make sure to copy it to a secure location as this is the only time the token will be displayed.",
	"your_personal_workspace": "Your personal workspace",
	"your_reminder": "Your reminder",
	"your_spf_dkim_desc_1": "It's important to correctly configure DNS records to ensure proper domain identification when we send emails on your behalf.",
	"your_spf_dkim_desc_2": "You're all set to go if the status below indicates that your DNS records are correctly configured. Otherwise, please create the SPF and DKIM records following the instructions below.",
	"your_spf_dkim_header": "DNS records health",
	"your_teams": "Your teams",
	"your_templates": "Your templates",
	"your_uppercase": "Your",
	"your_url": "Your URL",
	"your_url_dot_com": "http(s)://your_url.com",
	"yourself_lowercase": "yourself",
	"zip_upload_progress": "Your ZIP file has been uploaded and is being processed"
}
;

		_locale = _data;
		I18n.localeCache[locale] = _data;


		// $.ajax({
		// 	url: this.directory + "/" + locale + this.extension,
		// 	dataType: 'json',
		// 	success: function(data) {
		// 		// Store new translation
		// 		// _data[locale] = data;
		// 		_locale = data;
		// 		// Store it
		// 		// lfSetItem('hm_translations', data)
		// 		// Set localCache
		// 		I18n.localeCache[locale] = data;
		// 		// If callback defined
		// 		// return locale;
		// 	}
		// });

		// var _locale = await lfGetItem('hm_translations').then(t => {
		// 	I18n.localeCache = t && t.this_language_code && t.this_language_code === locale ? t : {}
		// 	// If we find the locale return
		// 	if (I18n.localeCache.this_language_code) {
		// 		// Set localCache
		// 		I18n.localeCache[locale] = t;
		// 		// Return
		// 		return locale;
		// 	}
		// 	// var _data = {};
		// 	$.ajax({
		// 		url: this.directory + "/" + locale + this.extension,
		// 		dataType: 'json',
		// 		success: function(data) {
		// 			// Store new translation
		// 			// _data[locale] = data;
		// 			// Store it
		// 			lfSetItem('hm_translations', data)
		// 			// Set localCache
		// 			I18n.localeCache[locale] = data;
		// 			// If callback defined
		// 			return locale;
		// 		}
		// 	});
		// })
		callback && callback(_locale)
	},

	__: function(){
		if ( ! I18n.localeCache[this.locale] ) {
			var _this = this;
			setTimeout(function() {
				_this.deferredLoading();
			}, 1000);
		}
		else {
			var msg = I18n.localeCache[this.locale][arguments[0]];
			if (arguments.length > 1) msg = vsprintf(msg, Array.prototype.slice.call(arguments, 1));
			return msg;
		}
	},

	__n: function(singular, count){
		var msg = I18n.localeCache[this.locale][singular];
		count = parseInt(count, 10);
		if(count === 0)
			msg = msg.zero;
		else
			msg = count > 1 ? msg.other : msg.one;
		msg = vsprintf(msg, [count]);
		if (arguments.length > 2)
			msg = vsprintf(msg, Array.prototype.slice.call(arguments, 2));
		return msg;
	},

	deferredLoading: function() {
		if ( ! I18n.localeCache[this.locale] ) {
			// console.log(' __ deferred again')
			var _this = this;
			setTimeout(function() {
				_this.__();
			}, 1000);
		}
		else {
			this.__();
		}
	}

};

//- Translation
i18n = new I18n({
	// directory: _du + '/locales',
	locale: Cookies.get('language') && Cookies.get('language') !== 'undefined' ? Cookies.get('language') : 'en',
	// extension: '_' + _sv + ".min.json",
	fallbacks : { '*' : 'en' },
	retryInDefaultLocale: true,
});
